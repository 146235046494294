import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { home } from "../services/home";
import {
  GET_CREATED_USERS,
  VIEW_USER_FULL_PROFILE,
  GET_RECENT_USERS,
  SUSPEND_USER,
  UNSUSPEND_USER,
  GET_REPORTED_PROFILE_LIST,
  GET_REPORTED_CONVERSATION_LIST,
  VIEW_REPORTED_CONVERSATION,
  ADD_ADMIN_CHECK,
  ADMIN_CHECK_LIST,
  GET_USERS_WITHOUT_L2,
  GET_RECENT_CHECK_LIST,
  READ_USER_VOICE_MESSAGE,
  ADD_USER_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION_LIST
} from "../ActionTypes";
import {
  getCreatedUsersSuccess,
  viewUserFullProfileSuccess,
  getRecentUsersSuccess,
  suspendUserSuccess,
  unsuspendUserSuccess,
  getReportedProfileListSuccess,
  getReportedConversationListSuccess,
  viewReportedConversationSuccess,
  showAuthMessage,
  addAdminCheckSuccess,
  adminCheckListSuccess,
  getUsersWithoutL2Success,
  getRecentCheckListSuccess,
  readUserVoiceMessageSuccess,
  addUserSubscriptionSuccess,
  getUserSubscriptionListSuccess
} from "../actions/Home";

const createdUsersRequest = async (
  limit,
  offset,
  userCountry,
  userCity,
  gender,
  phoneNumber,
  reportedProfile,
  reportedProfileReasonId,
  reportedConversation,
  reportedConversationReasonId,
  suspended,
  count,
  dateFrom,
  dateTo,
  recentFlag,
  userId,
  maritalStatus,
  createdDateFrom,
  createdDateTo,
  check,
  checkRange,
  checkTimestampOrder,
  createdTimeOrder,
  ageRange,
  orand,
  subscriptionFlag,
  subscriptionFlagOrder
) =>
  await home
    .getCreatedUsers(
      limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneNumber,
      reportedProfile,
      reportedProfileReasonId,
      reportedConversation,
      reportedConversationReasonId,
      suspended,
      count,
      dateFrom,
      dateTo,
      recentFlag,
      userId,
      maritalStatus,
      createdDateFrom,
      createdDateTo,
      check,
      checkRange,
      checkTimestampOrder,
      createdTimeOrder,
      ageRange,
      orand,
      subscriptionFlag,
      subscriptionFlagOrder
    )
    .then(users => users)
    .catch(error => error);


const usersWithoutL2Request = async (
      limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneVerified,
      phoneNumber,
      createdDateFrom,
      createdDateTo,
      jnt,
      count
    ) =>
      await home
        .getUsersWithoutL2(
          limit,
          offset,
          userCountry,
          userCity,
          gender,
          phoneVerified,
          phoneNumber,
          createdDateFrom,
          createdDateTo,
          jnt,
          count
        )
        .then(users => users)
        .catch(error => error);    


const recentCheckListRequest = async (
          limit,
          offset,
          userCountry,
          userCity,
          gender,
          phoneVerified,
          phoneNumber,
          createdDateFrom,
          createdDateTo,
          jnt,
          count,
          subscriptionCreatedDateFrom,
          subscriptionCreatedDateTo,
          mpFlag
        ) =>
          await home
            .getRecentCheckList(
              limit,
              offset,
              userCountry,
              userCity,
              gender,
              phoneVerified,
              phoneNumber,
              createdDateFrom,
              createdDateTo,
              jnt,
              count,
              subscriptionCreatedDateFrom,
              subscriptionCreatedDateTo,
              mpFlag
            )
            .then(users => users)
            .catch(error => error);    
    
const viewUserFullProfileRequest = async (
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) =>
  await home
    .viewUserFullProfile(userId, gender, userCountry, userCity, userVa, jnt)
    .then(userData => userData)
    .catch(error => error);

const getRecentUsersRequest = async (limit, offset, userId, gender, count) =>
  await home
    .getRecentUsers(limit, offset, userId, gender, count)
    .then(users => users)
    .catch(error => error);

const suspendUserRequest = async (
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) =>
  await home
    .suspendUser(userId, gender, userCountry, userCity, userVa, jnt)
    .then(suspend => suspend)
    .catch(error => error);

const unsuspendUserRequest = async (
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) =>
  await home
    .unsuspendUser(userId, gender, userCountry, userCity, userVa, jnt)
    .then(unsuspend => unsuspend)
    .catch(error => error);

const getReportedProfileListRequest = async (
  limit,
  offset,
  reporterId,
  reportedId,
  reasonId,
  count
) =>
  await home
    .getReportedProfileList(
      limit,
      offset,
      reporterId,
      reportedId,
      reasonId,
      count
    )
    .then(profileList => profileList)
    .catch(error => error);

const getReportedConversationListRequest = async (
  limit,
  offset,
  reporterId,
  reportedId,
  reasonId,
  count
) =>
  await home
    .getReportedConversationList(
      limit,
      offset,
      reporterId,
      reportedId,
      reasonId,
      count
    )
    .then(conversationList => conversationList)
    .catch(error => error);

const viewReportedConversationRequest = async (
  scoreL,
  offset,
  limitCount,
  reporterId,
  reportedId,
  conversationId
) =>
  await home
    .viewReportedConversation(
      scoreL,
      offset,
      limitCount,
      reporterId,
      reportedId,
      conversationId
    )
    .then(conversation => conversation)
    .catch(error => error);

const adminAddCheckRequest = async (userId, comment) =>
  await home
    .addAdminCheck(userId, comment)
    .then(data => data)
    .catch(error => error);

const adminCheckListRequest = async (limit, offset, userId, count) =>
  await home
    .getAdminCheckList(limit, offset, userId, count)
    .then(users => users)
    .catch(error => error);

const userVoiceMessageReadRequest = async (conversationId,
  genderOfReporter,
  reporterUserCountry,
  reporterUserCity,
  reporterUserVa,
  reporterId,
  reporterjnt,
  genderOfReported,
  reportedUserCountry,
  reportedUserCity,
  reportedUserVa,
  reportedId,
  reportedjnt,
  reportedMessage,
  reportPosition) =>
await home
  .readUserVoiceMessage(conversationId,
    genderOfReporter,
    reporterUserCountry,
    reporterUserCity,
    reporterUserVa,
    reporterId,
    reporterjnt,
    genderOfReported,
    reportedUserCountry,
    reportedUserCity,
    reportedUserVa,
    reportedId,
    reportedjnt,
    reportedMessage,
    reportPosition)
  .then(users => users)
  .catch(error => error);

  const userSubscriptionRequest= async (userId, amount, paymentPeriod,paymentType,paymentCurrency) =>
  await home
    .addUserSubscription(userId, amount, paymentPeriod,paymentType,paymentCurrency)
    .then(users => users)
    .catch(error => error);

const userSubscriptionListRequest= async (userId,limit,offset,count) =>
    await home
      .getUserSubscriptionList(userId,limit,offset,count)
      .then(list => list)
      .catch(error => error);
///////////////

function* requestCreatedUsers({ payload }) {
  const {
    limit,
    offset,
    userCountry,
    userCity,
    gender,
    phoneNumber,
    reportedProfile,
    reportedProfileReasonId,
    reportedConversation,
    reportedConversationReasonId,
    suspended,
    count,
    dateFrom,
    dateTo,
    recentFlag,
    userId,
    maritalStatus,
    createdDateFrom,
    createdDateTo,
    check,
    checkRange,
    checkTimestampOrder,
    createdTimeOrder,
    ageRange,
    orand,
    subscriptionFlag,
    subscriptionFlagOrder
  } = payload;

  try {
    const returnedData = yield call(
      createdUsersRequest,
      limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneNumber,
      reportedProfile,
      reportedProfileReasonId,
      reportedConversation,
      reportedConversationReasonId,
      suspended,
      count,
      dateFrom,
      dateTo,
      recentFlag,
      userId,
      maritalStatus,
      createdDateFrom,
      createdDateTo,
      check,
      checkRange,
      checkTimestampOrder,
      createdTimeOrder,
      ageRange,
      orand,
      subscriptionFlag,
      subscriptionFlagOrder
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(getCreatedUsersSuccess(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* requestGetUsersWithoutL2({ payload }) {
  const {
    limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneVerified,
      phoneNumber,
      createdDateFrom,
      createdDateTo,
      jnt,
      count
  } = payload;

  try {
    const returnedData = yield call(
      usersWithoutL2Request,
      limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneVerified,
      phoneNumber,
      createdDateFrom,
      createdDateTo,
      jnt,
      count
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(getUsersWithoutL2Success(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* requestRecentCheckList({ payload }) {

  const {
    limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneVerified,
      phoneNumber,
      createdDateFrom,
      createdDateTo,
      jnt,
      count,
      subscriptionCreatedDateFrom,
      subscriptionCreatedDateTo,
      mpFlag
  } = payload;

  try {
    const returnedData = yield call(
      recentCheckListRequest,
      limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneVerified,
      phoneNumber,
      createdDateFrom,
      createdDateTo,
      jnt,
      count,
      subscriptionCreatedDateFrom,
      subscriptionCreatedDateTo,
      mpFlag
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(getRecentCheckListSuccess(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* requestViewUserFullProfile({ payload }) {
  const { userId, gender, userCountry, userCity, userVa, jnt } = payload;

  try {
    const returnedData = yield call(
      viewUserFullProfileRequest,
      userId,
      gender,
      userCountry,
      userCity,
      userVa,
      jnt
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(viewUserFullProfileSuccess(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestRecentUsers({ payload }) {
  const { limit, offset, userId, gender, count } = payload;

  try {
    const returnedData = yield call(
      getRecentUsersRequest,
      limit,
      offset,
      userId,
      gender,
      count
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(getRecentUsersSuccess(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestSuspendUser({ payload }) {
  const { userId, gender, userCountry, userCity, userVa, jnt } = payload;

  try {
    const returnedData = yield call(
      suspendUserRequest,
      userId,
      gender,
      userCountry,
      userCity,
      userVa,
      jnt
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(suspendUserSuccess(returnedData.suspended));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestUnSuspendUser({ payload }) {
  const { userId, gender, userCountry, userCity, userVa, jnt } = payload;

  try {
    const returnedData = yield call(
      unsuspendUserRequest,
      userId,
      gender,
      userCountry,
      userCity,
      userVa,
      jnt
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(unsuspendUserSuccess(returnedData.unsuspended));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestGetReportedProfileList({ payload }) {
  const { limit, offset, reporterId, reportedId, reasonId, count } = payload;

  try {
    const returnedData = yield call(
      getReportedProfileListRequest,
      limit,
      offset,
      reporterId,
      reportedId,
      reasonId,
      count
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(getReportedProfileListSuccess(returnedData.profileList));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestGetReportedConversationList({ payload }) {
  const { limit, offset, reporterId, reportedId, reasonId, count } = payload;

  try {
    const returnedData = yield call(
      getReportedConversationListRequest,
      limit,
      offset,
      reporterId,
      reportedId,
      reasonId,
      count
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(getReportedConversationListSuccess(returnedData.List));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestViewReportedConversation({ payload }) {
  const {
    scoreL,
    offset,
    limitCount,
    reporterId,
    reportedId,
    conversationId
  } = payload;

  try {
    const returnedData = yield call(
      viewReportedConversationRequest,
      scoreL,
      offset,
      limitCount,
      reporterId,
      reportedId,
      conversationId
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(viewReportedConversationSuccess(returnedData.List));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestAddAdminCheck({ payload }) {
  const { userId, comment } = payload;

  try {
    const returnedData = yield call(adminAddCheckRequest, userId, comment);
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(addAdminCheckSuccess(true, returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestAdminCheckList({ payload }) {
  const { limit, offset, userId, count } = payload;

  try {
    const returnedData = yield call(
      adminCheckListRequest,
      limit,
      offset,
      userId,
      count
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(adminCheckListSuccess(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestReadVoiceMessage({payload}){
  const {conversationId,genderOfReporter,reporterUserCountry,reporterUserCity,reporterUserVa,reporterId,reporterjnt,genderOfReported,reportedUserCountry,reportedUserCity,reportedUserVa,reportedId,reportedjnt,reportedMessage,reportPosition } = payload;
  try {
    const returnedData = yield call(
      userVoiceMessageReadRequest,
      conversationId,
      genderOfReporter,
      reporterUserCountry,
      reporterUserCity,
      reporterUserVa,
      reporterId,
      reporterjnt,
      genderOfReported,
      reportedUserCountry,
      reportedUserCity,
      reportedUserVa,
      reportedId,
      reportedjnt,
      reportedMessage,
      reportPosition
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(readUserVoiceMessageSuccess(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestAddUserSubscription({payload}){
  const {userId, amount, paymentPeriod,paymentType,paymentCurrency} = payload;
  try {
    const returnedData = yield call(
      userSubscriptionRequest,
      userId, amount, paymentPeriod,paymentType,paymentCurrency
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(addUserSubscriptionSuccess(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestGetUserSubscriptionList({payload}){
  const {userId,limit,offset,count} = payload;
  try {
    const returnedData = yield call(
      userSubscriptionListRequest,
      userId,limit,offset,count
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(getUserSubscriptionListSuccess(returnedData));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

///////

export function* getCreatedUsersRequest() {
  yield takeEvery(GET_CREATED_USERS, requestCreatedUsers);
}

export function* getUsersWithoutL2Request(){
  yield takeEvery(GET_USERS_WITHOUT_L2, requestGetUsersWithoutL2);
}

export function* getRecentCheckListRequest(){
  yield takeEvery(GET_RECENT_CHECK_LIST, requestRecentCheckList);
}

export function* userFullProfileRequest() {
  yield takeEvery(VIEW_USER_FULL_PROFILE, requestViewUserFullProfile);
}

export function* RecentUsersRequest() {
  yield takeEvery(GET_RECENT_USERS, requestRecentUsers);
}

export function* userSuspendRequest() {
  yield takeEvery(SUSPEND_USER, requestSuspendUser);
}

export function* userUnSuspendRequest() {
  yield takeEvery(UNSUSPEND_USER, requestUnSuspendUser);
}

export function* reportedProfileListRequest() {
  yield takeEvery(GET_REPORTED_PROFILE_LIST, requestGetReportedProfileList);
}

export function* reportedConversationListRequest() {
  yield takeEvery(
    GET_REPORTED_CONVERSATION_LIST,
    requestGetReportedConversationList
  );
}

export function* reportedConversationRequest() {
  yield takeEvery(VIEW_REPORTED_CONVERSATION, requestViewReportedConversation);
}

export function* addAdminCheckRequest() {
  yield takeEvery(ADD_ADMIN_CHECK, requestAddAdminCheck);
}

export function* listAminCheckRequest() {
  yield takeEvery(ADMIN_CHECK_LIST, requestAdminCheckList);
}

export function* readUserVoiceMessageRequest() {
  yield takeEvery(READ_USER_VOICE_MESSAGE, requestReadVoiceMessage);
}

export function* addUserSubscriptionRequest(){
  yield takeEvery(ADD_USER_SUBSCRIPTION, requestAddUserSubscription);
}

export function* requestUserSubscriptionList(){
  yield takeEvery(GET_USER_SUBSCRIPTION_LIST, requestGetUserSubscriptionList);
}
///////

export default function* rootSaga() {
  yield all([
    fork(getCreatedUsersRequest),
    fork(userFullProfileRequest),
    fork(RecentUsersRequest),
    fork(userSuspendRequest),
    fork(userUnSuspendRequest),
    fork(reportedProfileListRequest),
    fork(reportedConversationListRequest),
    fork(reportedConversationRequest),
    fork(addAdminCheckRequest),
    fork(listAminCheckRequest),
    fork(getUsersWithoutL2Request),
    fork(getRecentCheckListRequest),
    fork(readUserVoiceMessageRequest),
    fork(addUserSubscriptionRequest),
    fork(requestUserSubscriptionList)
  ]);
}
