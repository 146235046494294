// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const OPEN_MODAL = "open_modal";

//AUTH

export const ADMIN_LOGIN = "admin_login";
export const ADMIN_LOGIN_SUCCESS = "admin_login_success";

export const ADMIN_LOGOUT = "admin_logout";

export const CHANGE_PASSWORD = "change_password";
export const CHANGE_PASSWORD_SUCCESS = "change_password_success";

export const FORGOT_PASSWORD = "forgot_password";
export const FORGOT_PASSWORD_SUCCESS = "forgot_password_success";

// HOME

export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";

export const GET_CREATED_USERS = "get_created_users";
export const GET_CREATED_USERS_SUCCESS = "get_created_users_success";

export const RESET_CREATED_USERS = "reset_created_users";

export const VIEW_USER_FULL_PROFILE = "view_user_full_profile";
export const VIEW_USER_FULL_PROFILE_SUCCESS = "view_user_full_profile_success";

export const GET_RECENT_USERS = "get_recent_users";
export const GET_RECENT_USERS_SUCCESS = "get_recent_users_success";

export const SUSPEND_USER = "suspend_user";
export const SUSPEND_USER_SUCCESS = "suspend_user_success";

export const UNSUSPEND_USER = "unsuspend_user";
export const UNSUSPEND_USER_SUCCESS = "unsuspend_user_success";

export const GET_REPORTED_PROFILE_LIST = "get_reported_profile_list";
export const GET_REPORTED_PROFILE_LIST_SUCCESS =
  "get_reported_profile_list_success";

export const GET_REPORTED_CONVERSATION_LIST = "get_reported_conversation_list";
export const GET_REPORTED_CONVERSATION_LIST_SUCCESS =
  "get_reported_conversation_list_success";

export const VIEW_REPORTED_CONVERSATION = "view_reported_conversation";
export const VIEW_REPORTED_CONVERSATION_SUCCESS =
  "view_reported_conversation_success";

export const CHANGE_LIMIT_DATA_RETURNED = "change_limit_data_returned";

export const RESET_USERS_DATA = "reset_users_data";

export const SET_PAGE_INDEX = "set_page_index";

export const SET_CLIKED_ROW = "set_clicked_row";

export const UPDATE_DATA_LIST = "update_data_list";

export const RESET_REPORTED_PROFILE_LIST = "reset_report_profile_list";
export const RESET_CONVERSATION_PROFILE_LIST = "reset_report_conversation_list";

export const ADD_ADMIN_CHECK = "add_admin_check";
export const ADD_ADMIN_CHECK_SUCCESS = "add_admin_check_success";

export const ADMIN_CHECK_LIST = "admin-check-list";
export const ADMIN_CHECK_LIST_SUCCESS = "admin-check-list_success";

export const RESET_OFFSET_ADMIN_CHECK_LIST = "reset_offset_admin_check_list";

///// Filter
export const SET_COUNTRY_SELECTED = "set_country_selected";
export const SET_CITY_SELECTED = "set_city_selected";
export const SET_GENDER_SELECTED = "set_gender_selected";
export const SET_PHONE_SELECTED = "set_phone_selected";
export const SET_CREATED_DATE_FROM_SELECTED = "set_created_date_from_selected";
export const SET_CREATED_DATE_TO_SELECTED = "set_created_date_to_selected";
export const SET_MARITAL_STATUS_SELECTED = "set_marital_status_selected";
export const SET_REPORTED_PROFILE_SELECTED = "set_reported_profile_selected";
export const SET_REPORTED_PROFILE_REASON_SELECTED =
  "set_reported_profile_reason_selected";
export const SET_REPORTED_CONVERSATION_SELECTED = "set_cconversation_selected";
export const SET_REPORTED_CONVERSATION_REASON_SELECTED =
  "set_conversation_reason_selected";
export const SET_SUSPENDED_SELECTED = "set_suspended_selected";
export const SET_DATE_FROM_SELECTED = "set_date_from_selected";
export const SET_DATE_TO_SELECTED = "set_date_to_selected";
export const SET_AGE_FROM_SELECTED = "set_age_from_selected";
export const SET_AGE_TO_SELECTED = "set_age_to_selected";
export const SET_CHECKED_OPTION_SELECTED = "set_checked_option_selected";
export const SET_CHECKED_DATE_FROM_SELECTED = "set_checked_date_from_selected";
export const SET_CHECKED_DATE_TO_SELECTED = "set_checked_date_to_selected";
export const SET_CHECKED_RECENT_USERS_SELECTED =
  "set_checked_recent_users_selected";
export const SET_USER_ID_SELECTED = "set_user_id_selected";
export const SET_CHECK_ORDER_BY_SELECTED = "set_check_order_by_selected";
export const SET_SUBSCRIBE_ORDER_BY_SELECTED="set_subscribe_order_by_selected";
export const SET_CREATE_ORDER_BY_SELECTED = "set_create_order_by_selected";
export const SET_AND_OR_OPTION_SELECTED = "set_and_or_option_selected";

///
export const SET_APP_LOCAL = "set_app_local";

export const OPEN_POPUP_CHAT = "open_popup_chat";

export const RESET_CONVERSATION_VIEW_DATA = "reset_conversation_view_data";

///// UNCOMPLETED LIST
export const OPEN_POPUP_ACTION = "open_popup_action";

export const GET_USERS_WITHOUT_L2="get_users_without_l2";
export const GET_USERS_WITHOUT_L2_SUCCESS="get_users_without_l2_success";

export const RESET_USERS_WITHOUT_L2="reset_users_without_l2";


export const GET_RECENT_CHECK_LIST="get_recent_check_list";
export const GET_RECENT_CHECK_LIST_SUCCESS="get_recent_check_list_success";

export const RESET_RECENT_CHECK_LIST="reset_recent_check_list";


export const SET_CURRENT_LIST_TYPE="set_current_list_type";
/// FILTER UNCOMPLETED LIST
export const SET_COUNTRY_SELECTED_UNCOMPLETED = "set_country_selected_uncompleted";
export const SET_CITY_SELECTED_UNCOMPLETED = "set_city_selected_uncompleted";
export const SET_GENDER_SELECTED_UNCOMPLETED = "set_gender_selected_uncompleted";
export const SET_PHONE_VERIFIED_UNCOMPLETED = "set_phone_verified_uncompleted";
export const SET_PHONE_SELECTED_UNCOMPLETED = "set_phone_selected_uncompleted";
export const SET_CREATED_DATE_FROM_SELECTED_UNCOMPLETED = "set_created_date_from_selected_uncompleted";
export const SET_CREATED_DATE_TO_SELECTED_UNCOMPLETED = "set_created_date_to_selected_uncompleted";

//READ VOICE MESSAGE
export const READ_USER_VOICE_MESSAGE="read_user_voice_message";
export const READ_USER_VOICE_MESSAGE_SUCCESS="read_user_voice_message_success";

export const UPDATE_AUDIO_BLOB_LIST="update_audio_blob_list";

export const OPEN_ADD_SUBSCRIPTION_POPUP="open_add_subsctiption_popup";

export const SET_SUBSCRIBE_OPTION_SELECTED="set_subscribe_option_selected";

export const ADD_USER_SUBSCRIPTION="add_user_subscription";

export const ADD_USER_SUBSCRIPTION_SUCCESS="add_user_subscription_success";

export const SET_SUBSCRIPTION_DATE_FROM_SELECTED="set_subscription_date_from_selected";

export const SET_SUBSCRIPTION_DATE_TO_SELECTED="set_subscription_date_from_selected";

export const SET_MAIN_PHOTO="set_main_photo";

export const GET_USER_SUBSCRIPTION_LIST="get_user_subscription_list";

export const GET_USER_SUBSCRIPTION_LIST_SUCCESS="get_user_subscription_list_success";


