import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getCookie } from "../util/session";
import base64url from "base64url";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Button from "@material-ui/core/Button";
import {
    openPopupAction,
    getUsersWithoutL2,
    getRecentCheckList,
    viewUserFullProfile,
    setCurrentListType,
    openPopupAddSubscription
} from "../redux/actions/Home";

import ModalActionList from "./modals/actionList"
import ModalAddSubscription from "./modals/addSubscription"

import AG_GRID_LOCALE_AR from "./locale/local.ar";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

///////
import { GridOverlay, DataGrid } from "@material-ui/data-grid";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { nlNL } from "@mui/x-data-grid";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PageviewIcon from "@material-ui/icons/Pageview";

import ModalChat from "./modals/chat";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Flag from "react-world-flags";

import Carousel from "react-elastic-carousel";

import { mapUserPhotoUrl } from "../helpers/mapUserPhotoUrl";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import {
  COUNTRY_MAP,
  COUNTRY_CITY_MAP
} from "../util/data";

//////
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

/// table 
import { alpha } from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
//   import DeleteIcon from '@mui/icons-material/Delete';
//   import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
///

///// tabs

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  //// table

  function createData(name, calories, fat, carbs, protein, protein1, protein2,Actions) {
    return {
      name,
      calories,
      fat,
      carbs,
      protein,
      protein1,protein2,
      Actions
    };
  }
  
  const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3,"ddddddddddddd","ttttttt"),
    createData('Donut', 452, 25.0, 51, 4.9,"ddddddddddddd","ttttttt"),
    createData('Eclair', 262, 16.0, 24, 6.0,"ddddddddddddd","ttttttt"),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0,"ddddddddddddd","ttttttt"),
    createData('Gingerbread', 356, 16.0, 49, 3.9,"ddddddddddddd","ttttttt"),
    createData('Honeycomb', 408, 3.2, 87, 6.5,"ddddddddddddd","ttttttt"),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3,"ddddddddddddd","ttttttt"),
  ];
  
  
  const headCells = [
    {
      id: 'userId',
      numeric: false,
      disablePadding: true,
      label: 'User Id',
    },
    {
      id: 'fullName',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'userCountry',
      numeric: false,
      disablePadding: false,
      label: 'Country',
    },
    {
      id: 'userCity',
      numeric: false,
      disablePadding: false,
      label: 'City',
    },
    {
      id: 'userVa',
      numeric: false,
      disablePadding: false,
      label: 'Virtual area',
    },
    {
      id: 'birthDay',
      numeric: false,
      disablePadding: false,
      label: 'BirthDay',
    },
    {
      id: 'jnt',
      numeric: false,
      disablePadding: false,
      label: 'join Time',
    },
    ,
    {
      id: 'phoneNumber',
      numeric: false,
      disablePadding: false,
      label: 'Phone Number',
    },
    {
      id: 'phoneVerified',
      numeric: false,
      disablePadding: false,
      label: 'Phone Verified',
    }
    ,
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: 'created Date',
    },
    {
        id: 'ip',
        numeric: false,
        disablePadding: false,
        label: 'IP',
      },
    {
        id: 'hardwareName',
        numeric: false,
        disablePadding: false,
        label: 'Hardware Name',
      },
      {
        id: 'hardwareVendor',
        numeric: false,
        disablePadding: false,
        label: 'Hardware Vendor',
      },
      {
          id: 'viewFullProfile',
          numeric: false,
          disablePadding: false,
          label: 'View Full Profile',
        },
      {
          id: 'Actions',
          numeric: false,
          disablePadding: false,
          label: 'Actions',
        },
        {
          id: 'usersubscription',
          numeric: false,
          disablePadding: false,
          label: 'User Subscription',
        }

  ];

  const headCells2 = [
    {
      id: 'userId',
      numeric: false,
      disablePadding: true,
      label: 'User Id',
    },
    {
      id: 'fullName',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'userCountry',
      numeric: false,
      disablePadding: false,
      label: 'Country',
    },
    {
      id: 'userCity',
      numeric: false,
      disablePadding: false,
      label: 'City',
    },
    {
      id: 'userVa',
      numeric: false,
      disablePadding: false,
      label: 'Virtual area',
    },
    {
      id: 'birthDay',
      numeric: false,
      disablePadding: false,
      label: 'BirthDay',
    },
    {
      id: 'jnt',
      numeric: false,
      disablePadding: false,
      label: 'join Time',
    },
    ,
    {
      id: 'phoneNumber',
      numeric: false,
      disablePadding: false,
      label: 'Phone Number',
    },
    {
      id: 'phoneVerified',
      numeric: false,
      disablePadding: false,
      label: 'Phone Verified',
    }
    ,
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: 'created Date',
    },
    {
        id: 'ip',
        numeric: false,
        disablePadding: false,
        label: 'IP',
      },
    {
        id: 'hardwareName',
        numeric: false,
        disablePadding: false,
        label: 'Hardware Name',
      },
      {
        id: 'hardwareVendor',
        numeric: false,
        disablePadding: false,
        label: 'Hardware Vendor',
      },
      {
          id: 'Actions',
          numeric: false,
          disablePadding: false,
          label: 'Actions',
      }
    
  ];


  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,listType } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {listType=="list2"?headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              > */}
                {headCell.label}
                {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel> */}
            </TableCell>
          )):headCells2.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              > */}
                {headCell.label}
                {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel> */}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    listType:PropTypes.string.isRequired
  };
  
  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
    const dispatch = useDispatch();
    const {selectedUsers}=props
    const {selectedAction}=props
    const {listType}=props
    const { t } = useTranslation();
    // const [selectedAction, setSelectedAction] = React.useState(null);
    const handleActionClicked = () => {
      //  console.log("selectedUsers1 ",selectedUsers)
       dispatch(openPopupAction(true))
      };
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} {t("users.selected")}
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
           
          </Typography>
        )}
  
        {numSelected > 0 ? (
        //   <Tooltip title="action1">
        //     <IconButton>
        //       <DeleteIcon />
        //     </IconButton>
        //   </Tooltip>
        <div className="btn-container">
            <Button variant="contained" color="primary" onClick={()=>{handleActionClicked();selectedAction("action1")}}>{t("user.action1")}</Button>
            <Button variant="contained" color="primary">{t("user.action2")}</Button>
            {listType=="list1"&&<Button variant="contained" color="primary">{t("user.action3")}</Button>}
            </div>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              {/* <FilterListIcon /> */}
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    selectedUsers:PropTypes.array.isRequired,
    selectedAction:PropTypes.func.isRequired,
    listType:PropTypes.string.isRequired
  };

//////end table 
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "3rem 1.5rem 1rem",
    textAlign: "center",
    width: "40%",
    "@media screen and (max-width: 575px) ": {
      width: "75% !important"
    }
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  avatar: {
    margin: "auto"
  },
  icons: {
    width: "35%",
    margin: "auto"
  },
  smallIcons: {
    width: "100%",
    margin: "auto"
  }
}));

const UncompletedList = () => {
    
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const authUser = useSelector(state => state.auth.authUser);
  const local = useSelector(state => state.home.local);




  ///tabs


  ///table list 1
  const usersWithoutL2Data=useSelector(state => state.home.usersWithoutL2Data);
  const totalCountUsersWithoutL2Data=useSelector(state => state.home.totalCountUsersWithoutL2Data);
  const usersWithoutL2Limit=useSelector(state => state.home.usersWithoutL2Limit);
  const usersWithoutL2Offset=useSelector(state => state.home.usersWithoutL2Offset);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersWithoutL2Data
    //   rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, user) => {
    const selectedIndex = selected.indexOf(user);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(getUsersWithoutL2(usersWithoutL2Limit,usersWithoutL2Offset,"","","","","","","","",""))
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => selected.indexOf(row) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersWithoutL2Data.length) : 0;
/////////end table

  ///table list2 
  const [selected2, setSelected2] = useState([]);
  const [selectedAction2, setSelectedAction2] = useState(null);
  const [pageList2, setPageList2] =useState(0);
  const [rowsPerPageList2, setRowsPerPageList2] =useState(25);
  const [finalUserPhotoUrl, setFinalUserPhotoUrl] = useState(null);
  const [userNotSubscribe, setUserNotSubscribe] = useState(false);
  const [currentClickedUser, setCurrentClickedUser] = useState(null);

  const [open, setOpen] = useState(false);

  const userFullProfileData = useSelector(
    state => state.home.userFullProfileData
  );
  const totalCountRecentCheckListData=useSelector(
    state => state.home.totalCountRecentCheckListData
  );
  const recentCheckListData=useSelector(state => state.home.recentCheckListData);
  const recentCheckListLimit=useSelector(state => state.home.recentCheckListLimit);
  const recentCheckListOffset=useSelector(state => state.home.recentCheckListOffset);

  const userSubscriptionAdded=useSelector(state => state.home.userSubscriptionAdded);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentClickedUser(null);
    setUserNotSubscribe(false)
  };

  useEffect(() => {
    if (currentClickedUser) {
      dispatch(
        viewUserFullProfile(
          currentClickedUser.userId,
          currentClickedUser.gender,
          currentClickedUser.userCountry,
          currentClickedUser.userCity,
          currentClickedUser.userVa,
          currentClickedUser.jnt
        )
      );
    }
  }, [currentClickedUser]);

  useEffect(() => {
    if (userFullProfileData && userFullProfileData.mainpicture&& currentClickedUser) {
      setFinalUserPhotoUrl(
        mapUserPhotoUrl(
          currentClickedUser.id,
          userFullProfileData.mainpicture,
          userFullProfileData.mainpictureSR
        )
      );
    }
    setUserNotSubscribe(true)
    handleOpen()
  }, [userFullProfileData]);


 


  const handleSelectAllClick2 = (event) => {
    if (event.target.checked) {
      const newSelecteds = recentCheckListData
    //   rows.map((n) => n.name);
      setSelected2(newSelecteds);
      return;
    }
    setSelected2([]);
  };

  const handleClick2 = (event, user) => {
    const selectedIndex = selected2.indexOf(user);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected2, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected2.slice(1));
    } else if (selectedIndex === selected2.length - 1) {
      newSelected = newSelected.concat(selected2.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected2.slice(0, selectedIndex),
        selected2.slice(selectedIndex + 1),
      );
    }

    setSelected2(newSelected);
  };

  const handleChangePage2 = (event, newPage) => {
    dispatch(getRecentCheckList(recentCheckListLimit,recentCheckListOffset,"","","","","","","","","","","",""))
    setPageList2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPageList2(parseInt(event.target.value, 10));
    setPageList2(0);
  };

  const isSelected2 = (row) => selected2.indexOf(row) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows2 =
  pageList2 > 0 ? Math.max(0, (1 + pageList2) * rowsPerPageList2 - recentCheckListData.length) : 0;
/////////end table list2

const [value, setValue] = useState(0);



const handleChange = (event, newValue) => {
  if(newValue==0){
    if(usersWithoutL2Data.length==0){
      dispatch(getUsersWithoutL2("",0,"","","","","","","","",1))
      dispatch(getUsersWithoutL2(usersWithoutL2Limit,0,"","","","","","","","",""))
    }
    dispatch(setCurrentListType("L1"))
  }else if(newValue==1){
    if(recentCheckListData.length==0){
      dispatch(getRecentCheckList("",0,"","","","","","","","",1,"","",""))
      dispatch(getRecentCheckList(recentCheckListLimit,0,"","","","","","","","","","","",""))
    }
    dispatch(setCurrentListType("L2"))
  }


  setValue(newValue);
};

const [subscribedUser,setSubscribedUser]= useState(null);
const [tokenUserData, setTokenUserData] = useState(null);
useEffect(() => {
  const tokenValue = getCookie("access_token", false);
  if (tokenValue != undefined) {
    setTokenUserData(
      JSON.parse(base64url.decode(`${tokenValue}`.split(".")[1]))
    );
  }
  dispatch(getUsersWithoutL2("",0,"","","","","","","","",1))
  dispatch(getUsersWithoutL2(usersWithoutL2Limit,0,"","","","","","","","",""))
  dispatch(setCurrentListType("L1"))
}, []);

// useEffect(() => {
//   if(usersWithoutL2Data.length!=0){
//     console.log("usersWithoutL2Data ",usersWithoutL2Data)
//   }
// }, [usersWithoutL2Data]);

//// popup subscriptions
const openSubscriptionPopup = useSelector(state => state.home.openSubscriptionPopup);

//// popup actions
  const openActionList = useSelector(state => state.home.openActionList);

  const handleActionClicked = (user) => {
    // console.log("selectedUsers 2",user)
    dispatch(openPopupAction(true))
   };

  ///// 
  return (
    <>

    <div className="tabs-container">
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={t("users.addL1nol2")} {...a11yProps(0)} />
          <Tab label={t("users.addDateNoPhotoSub")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
       {/* table list 1 */}
       <Box sx={{ width: '100%' }}>
         <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} selectedUsers={selected} selectedAction={setSelectedAction} listType="list1"/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={usersWithoutL2Data.length}
              listType="list1"
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                   {/* {console.log("usersWithoutL2Data ",usersWithoutL2Data)} */}
              {
                // stableSort(rows, getComparator(order, orderBy))
              
                usersWithoutL2Data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.userId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.userId}
                      </TableCell>
                      <TableCell align="right">{row.fullName}</TableCell>
                      <TableCell align="right">{t(COUNTRY_MAP[row.userCountry])}</TableCell>
                      <TableCell align="right">
                                  {t(
                          COUNTRY_CITY_MAP[row.userCountry][row.userCity - 1]
                        )}
                      </TableCell>
                      <TableCell align="right">{row.userVa}</TableCell>
                      <TableCell align="right">
                                  {`${row.birthDay
                          .toString()
                          .slice(0, 4)}- ${row.birthDay
                          .toString()
                          .slice(4, 6)}- ${row.birthDay.toString().slice(6, 8)}`}
                      </TableCell>
                      <TableCell align="right">
                      {moment(new Date(row.jnt * 100000)).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell align="right">{row.phoneNumber}</TableCell>
                      <TableCell align="right">{row.phoneVerified?"true":"false"}</TableCell>
                      <TableCell align="right">
                      {moment(row.createdDate).format("YYYY-MM-DD HH:mm A")}
                      </TableCell>
                      <TableCell align="right">{row.ip}</TableCell>
                      <TableCell align="right">{row.hardwareName}</TableCell>
                      <TableCell align="right">{row.hardwareVendor}</TableCell>
                     {(tokenUserData.id == 1||tokenUserData.id == 11)&&
                     <TableCell align="right">{row.Actions}
                       <div className="btn-container">
                          <Button variant="contained" color="primary" onClick={()=>{handleActionClicked(row); setSelectedAction("action1")}}>{t("user.action1")}</Button>
                          <Button variant="contained" color="primary">{t("user.action2")}</Button>
                          <Button variant="contained" color="primary">{t("user.action3")}</Button>
                         </div>
                     </TableCell>
                     }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}   
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={totalCountUsersWithoutL2Data}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
         onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* table list 2 */}
        <Box sx={{ width: '100%' }}>
         <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected2.length} selectedUsers={selected2} selectedAction={setSelectedAction2} listType="list2"/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected2.length}
              // order={order}
              // orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick2}
              // onRequestSort={handleRequestSort}
              rowCount={recentCheckListData.length}
              listType="list2"
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {
                // stableSort(rows, getComparator(order, orderBy))
                recentCheckListData
                .slice(pageList2 * rowsPerPageList2, pageList2 * rowsPerPageList2 + rowsPerPageList2)
                .map((row, index) => {
                  const isItemSelected = isSelected2(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick2(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.userId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.userId}
                      </TableCell>
                      <TableCell align="right">{row.fullName}</TableCell>
                      <TableCell align="right">{t(COUNTRY_MAP[row.userCountry])}</TableCell>
                      <TableCell align="right">
                                  {t(
                          COUNTRY_CITY_MAP[row.userCountry][row.userCity - 1]
                        )}
                      </TableCell>
                      <TableCell align="right">{row.userVa}</TableCell>
                      <TableCell align="right">
                                  {`${row.birthDay
                          .toString()
                          .slice(0, 4)}- ${row.birthDay
                          .toString()
                          .slice(4, 6)}- ${row.birthDay.toString().slice(6, 8)}`}
                      </TableCell>
                      <TableCell align="right">
                      {moment(new Date(row.jnt * 100000)).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell align="right">{row.phoneNumber}</TableCell>
                      <TableCell align="right">{row.phoneVerified?"true":"false"}</TableCell>
                      <TableCell align="right">
                      {moment(row.createdDate).format("YYYY-MM-DD HH:mm A")}
                      </TableCell>
                      <TableCell align="right">{row.ip}</TableCell>
                      <TableCell align="right">{row.hardwareName}</TableCell>
                      <TableCell align="right">{row.hardwareVendor}</TableCell>
                      <TableCell align="right">
                      <PageviewIcon
                        color="secondary"
                        fontSize="large"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                           setFinalUserPhotoUrl(null);
                           setCurrentClickedUser(row);
                        }}
                      />

                      
                      </TableCell>

                     {(tokenUserData.id == 1||tokenUserData.id == 11)&& 
                     <TableCell align="right">{row.Actions}
                       <div className="btn-container">
                    <Button variant="contained" color="primary" onClick={()=>{handleActionClicked(row); setSelectedAction2("action1")}}>{t("user.action1")}</Button>
                    <Button variant="contained" color="primary">{t("user.action2")}</Button>
                    </div>
                    </TableCell>
                     }
                     {
                     <TableCell align="right">{row.usersubscription}
                       {(row.mpFlag==1&&row.subscriptionFlag==0)&&
                       <div className="btn-container">
                          <Button variant="contained" color="primary" onClick={()=>{ 
                            
                            dispatch(openPopupAddSubscription(true));
                            setSubscribedUser(row)
                            
                            }}>{t("user.addSub")}</Button>
                       </div>}
                       {(row.mpFlag==1&&row.subscriptionFlag==1)&&
                        
                         moment(row.modifiedDate).format("YYYY-MM-DD HH:mm A")
                       }
                    </TableCell>
                     }
                    </TableRow>
                  );
                })}
              {emptyRows2 > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows2,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={totalCountRecentCheckListData}
          rowsPerPage={rowsPerPageList2}
          page={pageList2}
          onPageChange={handleChangePage2}
          onRowsPerPageChange={handleChangeRowsPerPage2}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
      </TabPanel>
    </Box>
    </div>
      {openSubscriptionPopup && <ModalAddSubscription subscribedUser={subscribedUser}/> }
      {openActionList && <ModalActionList actionSelected={selectedAction} selectedUsers={selected}/> }
      {currentClickedUser && userFullProfileData && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Grid container>
                <Grid item xs={6} className="full-mb">
                  <Avatar
                    alt={currentClickedUser ? currentClickedUser.fullName : ""}
                    src={finalUserPhotoUrl ? finalUserPhotoUrl : ""}
                    className={`${classes.large} ${classes.avatar}`}
                  />
                  <h2 id="transition-modal-title">
                    {currentClickedUser.fullName}
                  </h2>
                  
                  {userNotSubscribe &&
                  <h3 id="transition-modal-title">
                    {t("user.noSub")}
                  </h3>
                  }
                </Grid>

                <Grid item xs={6} className="full-mb">
                  {/* className="grid-mb" */}
                  <Grid container>
                    <Grid item xs={1} className="g3-1">
                      <div className={`${classes.smallIcons} icon`}>
                        <Flag code={userFullProfileData.nationality} />
                      </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className="g3-2">
                      <Typography variant="body1" gutterBottom>
                        {t(`nationality.${userFullProfileData.nationality}`)}
                      </Typography>
                    </Grid>

                    <Grid item xs={1} className="g3-1">
                      <div className={`${classes.smallIcons} icon`}>
                        <img src="static/images/icons/new-profile/education.svg" />
                      </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className="g3-2">
                      <Typography variant="body1" gutterBottom>
                        {t(`education.${userFullProfileData.education}`)}
                      </Typography>
                    </Grid>

                    {userFullProfileData.tpercent && (
                      <>
                        <Grid item xs={1} className="g3-1">
                          <div className={`${classes.smallIcons} icon`}>
                            <img src="static/images/icons/new-profile/tprecent.svg" />
                          </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className="g3-2">
                          <Typography variant="body1" gutterBottom>
                            {t(`tprecent.${userFullProfileData.tpercent}`)}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className="grid-mb">
                <Grid item xs={2} className="g3-1">
                  <div className={`${classes.icons} icon`}>
                    <img src="static/images/icons/new-profile/title.svg" />
                  </div>
                </Grid>
                <Grid item xs={10} className="g3-2">
                  <Typography variant="body1" gutterBottom>
                    {userFullProfileData.title}
                  </Typography>
                </Grid>

                <Grid item xs={2} className="g3-1">
                  <div className={`${classes.icons} icon`}>
                    <img src="static/images/icons/new-profile/workd.svg" />
                  </div>
                </Grid>
                <Grid item xs={10} className="g3-2">
                  <Typography variant="body1" gutterBottom>
                    {t(`workd.${userFullProfileData.workd - 1}`)}
                  </Typography>
                </Grid>
                <Grid item xs={2} className="g3-1">
                  <div className={`${classes.icons} icon`}>
                    <img src="static/images/icons/new-profile/bio.svg" />
                  </div>
                </Grid>
                <Grid item xs={10} className="g3-2">
                  <Typography variant="body1" gutterBottom>
                    {userFullProfileData.bio}
                  </Typography>
                </Grid>
              </Grid>

              {/* <Typography variant="h6" gutterBottom>
                {t("profile.privateImg")}
              </Typography> */}
              {/* {userFullProfileData.privatepictures === null ? (
                <p id="transition-modal-description">{t("user.noPP")}</p>
              ) : finalUserPhotoPPUrl ? (
                <>
                  <div
                    style={{
                      width: finalUserPhotoPPUrl.length == 1 ? "50%" : "70%",
                      margin: "auto"
                    }}
                    className="carousel-container"
                  >
                    <Carousel breakPoints={breakPoints}>
                      {finalUserPhotoPPUrl.map((e, i) => (
                        <img key={i} src={e} />
                      ))}
                    </Carousel>
                  </div>
                </>
              ) : (
                ""
              )} */}
            </div>
          </Fade>
        </Modal>
      )}
      <NotificationContainer />
    </>
  );
};

export default UncompletedList;
