import AG_GRID_LOCALE_EN from "./local.en";
var AG_GRID_LOCALE_AR = {};

// Create a dummy locale based on english but prefix everything with zzz
// Object.keys(AG_GRID_LOCALE_EN).forEach(function(key) {
//   AG_GRID_LOCALE_ZZZ[key] = "zzz-" + AG_GRID_LOCALE_EN[key];
// });

export default AG_GRID_LOCALE_AR = {
  // Root
  noRowsLabel: "بلا صفوف",
  noResultsOverlayLabel: "لم يتم العثور على نتائج.",
  errorOverlayDefaultLabel: "حدث خطأ.",

  // نص زر شريط أدوات محدد الكثافة
  toolbarDensity: "الكثافة",
  toolbarDensityLabel: "الكثافة",
  toolbarDensityCompact: "مضغوط",
  toolbarDensityStandard: "قياسي",
  toolbarDensityComfortable: "مريح",

  // نص زر شريط أدوات محدد الأعمدة
  toolbarColumns: "Columns",
  toolbarColumnsLabel: "تحديد الأعمدة",

  // فلاتر نص زر شريط الأدوات
  toolbarFilters: "عوامل التصفية",
  toolbarFiltersLabel: "إظهار عوامل التصفية",
  toolbarFiltersTooltipHide: "إخفاء عوامل التصفية",
  toolbarFiltersTooltipShow: "إظهار عوامل التصفية",
  toolbarFiltersTooltipActive: count =>
    count !== 1 ? `${count} مرشحات نشطة ` : `${count} مرشح نشط `,

  // Export selector toolbar button text
  toolbarExport: "تصدير",
  toolbarExportLabel: "تصدير",
  toolbarExportCSV: "تنزيل كملف CSV",
  toolbarExportPrint: "طباعة",

  // نص لوحة الأعمدة
  columnsPanelTextFieldLabel: "بحث عن عمود",
  columnsPanelTextFieldPlaceholder: "عنوان العمود",
  columnsPanelDragIconLabel: "إعادة ترتيب العمود",
  columnsPanelShowAllButton: "إظهار الكل",
  columnsPanelHideAllButton: "إخفاء الكل",

  // نص لوحة التصفية
  filterPanelAddFilter: "إضافة عامل تصفية",
  filterPanelDeleteIconLabel: "حذف",
  filterPanelOperators: "المشغلون",
  filterPanelOperatorAnd: "و",
  filterPanelOperatorOr: "أو",
  filterPanelColumns: "الأعمدة",
  filterPanelInputLabel: "القيمة",
  filterPanelInputPlaceholder: "تصفية القيمة",

  // عامل التصفية النص
  filterOperatorContains: "يحتوي على",
  filterOperatorEquals: "يساوي",
  filterOperatorStartsWith: "يبدأ بـ",
  filterOperatorEndsWith: "ينتهي بـ",
  filterOperatorIs: "هو",
  filterOperatorNot: "ليس",
  filterOperatorAfter: "بعد",
  filterOperatorOnOrAfter: "قيد التشغيل أو بعد",
  filterOperatorBefore: "قبل",
  filterOperatorOnOrBefore: "قيد التشغيل أو قبله",
  filterOperatorIsEmpty: "فارغ",
  filterOperatorIsNotEmpty: "ليس فارغًا",

  // تصفية نص القيم
  filterValueAny: "أي",
  filterValueTrue: "صحيح",
  filterValueFalse: "خطأ",

  // نص قائمة العمود
  columnMenuLabel: "القائمة",
  columnMenuShowColumns: "إظهار الأعمدة",
  columnMenuFilter: "عامل التصفية",
  columnMenuHideColumn: "إخفاء",
  columnMenuUnsort: "Unsort",
  columnMenuSortAsc: "فرز حسب ASC",
  columnMenuSortDesc: "فرز حسب DESC",

  // Column header text
  columnHeaderFiltersTooltipActive: count =>
    count !== 1 ? `${count} مرشحات نشطة` : `${count} مرشح نشط `,
  columnHeaderFiltersLabel: "اظهر الفلاتر",
  columnHeaderSortIconLabel: "نوع ",

  // Rows selected footer text
  footerRowSelected: count =>
    count !== 1
      ? `${count.toLocaleString()} الصفوف المختارة `
      : `${count.toLocaleString()} الصف المحدد `,

  // Total rows footer text
  footerTotalRows: "إجمالي الصفوف: ",

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "اختيار خانة الاختيار ",

  // Boolean cell text
  booleanCellTrueLabel: "صحيح",
  booleanCellFalseLabel: "غلط",

  // Actions cell more text
  actionsCellMore: "المزيد",

  // Used core components translation keys
  MuiTablePagination: {}
};
