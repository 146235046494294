import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Filtration from "../components/filtration";
import UsersList from "../components/userList";
import LangDropdown from "../components/langDropdown";

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="div-container">
        <Filtration />
        <UsersList />
      </div>
    </>
  );
};

export default Home;
