import { callAxios } from "./callAxios";
import { setCookie, removeCookie, getCookie } from "../../util/session";
import base64url from "base64url";
//import IntlMessages from "../../util/IntlMessages";
const auth = {};
const tokenManagerOperations = {};

auth.login = function(username, password) {
  return new Promise(async (resolve, reject) => {
    if (username && password) {
      try {
        let options = {
          url: "/auth/login",
          method: "POST",
          headers: {
            Accept: "application-json",
            "Content-Type": "application/json;charset=UTF-8"
          },
          data: {
            username,
            password
          }
        };

        let resX = await callAxios(options);

        let res = resX.data;

        if (res) {
          if (res.data && res.data.token && res.data.admin) {
            let tokenUserData = JSON.parse(
              base64url.decode(`${res.data.token}`.split(".")[1])
            );

            tokenManagerOperations.setTokenAndValidate(
              "access_token",
              res.data.token
            );
            resolve({ authUser: res.data.admin });
          } else {
            resolve({ message: res.message });
          }
        }
      } catch (err) {
        resolve({ message: err.message });
      }
    } else {
      if (!username && password) {
        //  resolve({ message: <IntlMessages id="error.emptyPhone" /> });
      } else if (username && !password) {
        // resolve({ message: <IntlMessages id="error.emptyPass" /> });
      } else {
        //// resolve({ message: <IntlMessages id="error.emptyPhonePass" /> });
      }
    }
  }).catch(err => {
    console.log(err);
  });
};
auth.changePassword = function(oldPassword, newPassword) {
  //"oldPassword": "SYSTEM@_123",
  //  "newPassword": "ha@_123"
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      // const tokenValue =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjI3Nzc0ODU0fQ.se8wumuRe1Nz0UCPwKU5WHaZHsGkztpMmNi-be-uSR0";
      const options = {
        url: `auth/change-password`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        },
        data: {
          oldPassword: `${oldPassword}`,
          newPassword: `${newPassword}`
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve({ changed: true });
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

auth.forgotPassword = function(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const options = {
        url: `auth/forgot-password`,
        method: "POST",
        headers: {
          Accept: "application-json",
          "Content-Type": "application/json;charset=UTF-8"
        },
        data: {
          email
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve({ sent: true });
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

tokenManagerOperations.clearAllTokens = function(key) {
  removeCookie(key);
  return;
};

tokenManagerOperations.setTokenAndValidate = function(key, value) {
  return new Promise((resolve, reject) => {
    if (!key) {
      resolve(false);
      return;
    }

    setCookie(key, value);
    resolve(true);
  }).catch(err => {
    console.log(err);
  });
};

export { auth, tokenManagerOperations };
