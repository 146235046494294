import React, { useState } from "react";

import Filtration2 from "../components/filteration-2";
import UsersList2 from "../components/userList-2";

const ReportedProfileList = () => {
  return (
    <>
      <div className="div-container">
        <Filtration2 />
        <UsersList2 />
      </div>
    </>
  );
};

export default ReportedProfileList;
