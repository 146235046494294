import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { auth } from "../services/auth";
import { ADMIN_LOGIN, CHANGE_PASSWORD, FORGOT_PASSWORD } from "../ActionTypes";
import { showAuthMessage } from "../actions/Home";
import {
  loginSuccess,
  changePasswordSuccess,
  forgotPasswordSuccess
} from "../actions/Auth";

const loginRequest = async (username, password) =>
  await auth
    .login(username, password)
    .then(user => user)
    .catch(error => error);

const changePasswordRequest = async (oldPassword, newPassword) =>
  await auth
    .changePassword(oldPassword, newPassword)
    .then(change => change)
    .catch(error => error);

const forgotPasswordRequest = async email =>
  await auth
    .forgotPassword(email)
    .then(sent => sent)
    .catch(error => error);
///////////////

function* requestLogin({ payload }) {
  const { username, password } = payload;

  try {
    const returnedData = yield call(loginRequest, username, password);
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(loginSuccess(returnedData, password));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestChangePassword({ payload }) {
  const { oldPassword, newPassword } = payload;

  try {
    const returnedData = yield call(
      changePasswordRequest,
      oldPassword,
      newPassword
    );
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(changePasswordSuccess(returnedData.changed));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* requestForgotPassword({ payload }) {
  const { email } = payload;

  try {
    const returnedData = yield call(forgotPasswordRequest, email);
    if (returnedData.message) {
      yield put(showAuthMessage(returnedData.message));
    } else {
      yield put(forgotPasswordSuccess(returnedData.sent));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

///////

export function* loginAdminRequest() {
  yield takeEvery(ADMIN_LOGIN, requestLogin);
}

export function* adminChangePasswordRequest() {
  yield takeEvery(CHANGE_PASSWORD, requestChangePassword);
}

export function* adminForgotPasswordRequest() {
  yield takeEvery(FORGOT_PASSWORD, requestForgotPassword);
}

///////

export default function* rootSaga() {
  yield all([
    fork(loginAdminRequest),
    fork(adminChangePasswordRequest),
    fork(adminForgotPasswordRequest)
  ]);
}
