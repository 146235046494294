import {
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  GET_CREATED_USERS,
  GET_CREATED_USERS_SUCCESS,
  VIEW_USER_FULL_PROFILE,
  VIEW_USER_FULL_PROFILE_SUCCESS,
  GET_RECENT_USERS,
  GET_RECENT_USERS_SUCCESS,
  SUSPEND_USER,
  SUSPEND_USER_SUCCESS,
  UNSUSPEND_USER,
  UNSUSPEND_USER_SUCCESS,
  GET_REPORTED_PROFILE_LIST,
  GET_REPORTED_PROFILE_LIST_SUCCESS,
  GET_REPORTED_CONVERSATION_LIST,
  GET_REPORTED_CONVERSATION_LIST_SUCCESS,
  VIEW_REPORTED_CONVERSATION,
  VIEW_REPORTED_CONVERSATION_SUCCESS,
  RESET_CREATED_USERS,
  CHANGE_LIMIT_DATA_RETURNED,
  SET_PAGE_INDEX,
  SET_CLIKED_ROW,
  UPDATE_DATA_LIST,
  RESET_REPORTED_PROFILE_LIST,
  RESET_CONVERSATION_PROFILE_LIST,
  ADD_ADMIN_CHECK,
  ADD_ADMIN_CHECK_SUCCESS,
  ADMIN_CHECK_LIST,
  ADMIN_CHECK_LIST_SUCCESS,
  RESET_OFFSET_ADMIN_CHECK_LIST,
  SET_COUNTRY_SELECTED,
  SET_AGE_FROM_SELECTED,
  SET_AND_OR_OPTION_SELECTED,
  SET_AGE_TO_SELECTED,
  SET_CHECKED_DATE_FROM_SELECTED,
  SET_CHECKED_OPTION_SELECTED,
  SET_CHECKED_RECENT_USERS_SELECTED,
  SET_CHECK_ORDER_BY_SELECTED,
  SET_SUBSCRIBE_ORDER_BY_SELECTED,
  SET_CITY_SELECTED,
  SET_CREATED_DATE_FROM_SELECTED,
  SET_CREATED_DATE_TO_SELECTED,
  SET_CREATE_ORDER_BY_SELECTED,
  SET_DATE_FROM_SELECTED,
  SET_DATE_TO_SELECTED,
  SET_GENDER_SELECTED,
  SET_MARITAL_STATUS_SELECTED,
  SET_PHONE_SELECTED,
  SET_REPORTED_CONVERSATION_REASON_SELECTED,
  SET_REPORTED_CONVERSATION_SELECTED,
  SET_REPORTED_PROFILE_REASON_SELECTED,
  SET_REPORTED_PROFILE_SELECTED,
  SET_SUSPENDED_SELECTED,
  SET_USER_ID_SELECTED,
  SET_CHECKED_DATE_TO_SELECTED,
  SET_APP_LOCAL,
  OPEN_POPUP_CHAT,
  OPEN_POPUP_ACTION,
  RESET_CONVERSATION_VIEW_DATA,
  GET_USERS_WITHOUT_L2,
  GET_USERS_WITHOUT_L2_SUCCESS,
  GET_RECENT_CHECK_LIST,
  GET_RECENT_CHECK_LIST_SUCCESS,
  SET_COUNTRY_SELECTED_UNCOMPLETED,
  SET_CITY_SELECTED_UNCOMPLETED,
  SET_GENDER_SELECTED_UNCOMPLETED,
  SET_PHONE_VERIFIED_UNCOMPLETED,
  SET_PHONE_SELECTED_UNCOMPLETED,
  SET_CREATED_DATE_FROM_SELECTED_UNCOMPLETED,
  SET_CREATED_DATE_TO_SELECTED_UNCOMPLETED,
  SET_CURRENT_LIST_TYPE,
  RESET_USERS_WITHOUT_L2,
  RESET_RECENT_CHECK_LIST,
  READ_USER_VOICE_MESSAGE,
  READ_USER_VOICE_MESSAGE_SUCCESS,
  UPDATE_AUDIO_BLOB_LIST,
  OPEN_ADD_SUBSCRIPTION_POPUP,
  SET_SUBSCRIBE_OPTION_SELECTED,
  ADD_USER_SUBSCRIPTION,
  ADD_USER_SUBSCRIPTION_SUCCESS,
  SET_SUBSCRIPTION_DATE_FROM_SELECTED,
  SET_SUBSCRIPTION_DATE_TO_SELECTED,
  SET_MAIN_PHOTO,
  GET_USER_SUBSCRIPTION_LIST,
  GET_USER_SUBSCRIPTION_LIST_SUCCESS
} from "../ActionTypes";

// FILTER

export const openPopupChat = data => {
  return {
    type: OPEN_POPUP_CHAT,
    payload: data
  };
};

export const resetConversationViewData = () => {
  return {
    type: RESET_CONVERSATION_VIEW_DATA
  };
};

export const setCountrySelected = data => {
  return {
    type: SET_COUNTRY_SELECTED,
    payload: data
  };
};

export const setCitySelected = data => {
  return {
    type: SET_CITY_SELECTED,
    payload: data
  };
};

export const setGenderSelected = data => {
  return {
    type: SET_GENDER_SELECTED,
    payload: data
  };
};

export const setUserIdSelected = data => {
  return {
    type: SET_USER_ID_SELECTED,
    payload: data
  };
};
export const setSuspendedSelected = data => {
  return {
    type: SET_SUSPENDED_SELECTED,
    payload: data
  };
};
export const setReportedProfileSelected = data => {
  return {
    type: SET_REPORTED_PROFILE_SELECTED,
    payload: data
  };
};
export const setReportedProfileReasonSelected = data => {
  return {
    type: SET_REPORTED_PROFILE_REASON_SELECTED,
    payload: data
  };
};
export const setReportedConversationSelected = data => {
  return {
    type: SET_REPORTED_CONVERSATION_SELECTED,
    payload: data
  };
};
export const setReportedConversationReasonSelected = data => {
  return {
    type: SET_REPORTED_CONVERSATION_REASON_SELECTED,
    payload: data
  };
};
export const setPhoneSelected = data => {
  return {
    type: SET_PHONE_SELECTED,
    payload: data
  };
};
export const setMarialStatusSelected = data => {
  return {
    type: SET_MARITAL_STATUS_SELECTED,
    payload: data
  };
};
export const setDateToSelected = data => {
  return {
    type: SET_DATE_TO_SELECTED,
    payload: data
  };
};
export const setDateFromSelected = data => {
  return {
    type: SET_DATE_FROM_SELECTED,
    payload: data
  };
};
export const setCreateOrderBySelected = data => {
  return {
    type: SET_CREATE_ORDER_BY_SELECTED,
    payload: data
  };
};
export const setCreatedDateToSelected = data => {
  return {
    type: SET_CREATED_DATE_TO_SELECTED,
    payload: data
  };
};
export const setCreatedDateFromSelected = data => {
  return {
    type: SET_CREATED_DATE_FROM_SELECTED,
    payload: data
  };
};
export const setCheckOrderBySelected = data => {
  return {
    type: SET_CHECK_ORDER_BY_SELECTED,
    payload: data
  };
};
export const setSubscribeOrderBySelected=data=>{
  return {
    type: SET_SUBSCRIBE_ORDER_BY_SELECTED,
    payload: data
  };
}
export const setCheckedRecentUsersSelected = data => {
  return {
    type: SET_CHECKED_RECENT_USERS_SELECTED,
    payload: data
  };
};
export const setCheckedOptionSelected = data => {
  return {
    type: SET_CHECKED_OPTION_SELECTED,
    payload: data
  };
};
export const setCheckedDateFromSelected = data => {
  return {
    type: SET_CHECKED_DATE_FROM_SELECTED,
    payload: data
  };
};

export const setCheckedDateToSelected = data => {
  return {
    type: SET_CHECKED_DATE_TO_SELECTED,
    payload: data
  };
};

export const setAgeToSelected = data => {
  return {
    type: SET_AGE_TO_SELECTED,
    payload: data
  };
};
export const setAndOrOptionSelected = data => {
  return {
    type: SET_AND_OR_OPTION_SELECTED,
    payload: data
  };
};
export const setAgeFromSelected = data => {
  return {
    type: SET_AGE_FROM_SELECTED,
    payload: data
  };
};

export const getCreatedUsers = (
  limit,
  offset,
  userCountry,
  userCity,
  gender,
  phoneNumber,
  reportedProfile,
  reportedProfileReasonId,
  reportedConversation,
  reportedConversationReasonId,
  suspended,
  count,
  dateFrom,
  dateTo,
  recentFlag,
  userId,
  maritalStatus,
  createdDateFrom,
  createdDateTo,
  check,
  checkRange,
  checkTimestampOrder,
  createdTimeOrder,
  ageRange,
  orand,
  subscriptionFlag,
  subscriptionFlagOrder
) => {
  return {
    type: GET_CREATED_USERS,
    payload: {
      limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneNumber,
      reportedProfile,
      reportedProfileReasonId,
      reportedConversation,
      reportedConversationReasonId,
      suspended,
      count,
      dateFrom,
      dateTo,
      recentFlag,
      userId,
      maritalStatus,
      createdDateFrom,
      createdDateTo,
      check,
      checkRange,
      checkTimestampOrder,
      createdTimeOrder,
      ageRange,
      orand,
      subscriptionFlag,
      subscriptionFlagOrder
    }
  };
};

export const changeLimitReturnedData = limit => {
  return {
    type: CHANGE_LIMIT_DATA_RETURNED,
    payload: limit
  };
};

export const getCreatedUsersSuccess = data => {
  return {
    type: GET_CREATED_USERS_SUCCESS,
    payload: data
  };
};

export const viewUserFullProfile = (
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) => {
  return {
    type: VIEW_USER_FULL_PROFILE,
    payload: {
      userId,
      gender,
      userCountry,
      userCity,
      userVa,
      jnt
    }
  };
};

export const viewUserFullProfileSuccess = data => {
  return {
    type: VIEW_USER_FULL_PROFILE_SUCCESS,
    payload: data
  };
};

export const getRecentUsers = (limit, offset, userId, gender, count) => {
  return {
    type: GET_RECENT_USERS,
    payload: {
      limit,
      offset,
      userId,
      gender,
      count
    }
  };
};

export const getRecentUsersSuccess = data => {
  return {
    type: GET_RECENT_USERS_SUCCESS,
    payload: data
  };
};

export const suspendUser = (
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) => {
  return {
    type: SUSPEND_USER,
    payload: {
      userId,
      gender,
      userCountry,
      userCity,
      userVa,
      jnt
    }
  };
};

export const suspendUserSuccess = data => {
  return {
    type: SUSPEND_USER_SUCCESS,
    payload: data
  };
};

export const unsuspendUser = (
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) => {
  return {
    type: UNSUSPEND_USER,
    payload: {
      userId,
      gender,
      userCountry,
      userCity,
      userVa,
      jnt
    }
  };
};

export const unsuspendUserSuccess = data => {
  return {
    type: UNSUSPEND_USER_SUCCESS,
    payload: data
  };
};

export const getReportedProfileList = (
  limit,
  offset,
  reporterId,
  reportedId,
  reasonId,
  count
) => {
  return {
    type: GET_REPORTED_PROFILE_LIST,
    payload: {
      limit,
      offset,
      reporterId,
      reportedId,
      reasonId,
      count
    }
  };
};

export const getReportedProfileListSuccess = data => {
  return {
    type: GET_REPORTED_PROFILE_LIST_SUCCESS,
    payload: data
  };
};

export const getReportedConversationList = (
  limit,
  offset,
  reporterId,
  reportedId,
  reasonId,
  count
) => {
  return {
    type: GET_REPORTED_CONVERSATION_LIST,
    payload: {
      limit,
      offset,
      reporterId,
      reportedId,
      reasonId,
      count
    }
  };
};

export const getReportedConversationListSuccess = data => {
  return {
    type: GET_REPORTED_CONVERSATION_LIST_SUCCESS,
    payload: data
  };
};

export const viewReportedConversation = (
  scoreL,
  offset,
  limitCount,
  reporterId,
  reportedId,
  conversationId
) => {
  return {
    type: VIEW_REPORTED_CONVERSATION,
    payload: {
      scoreL,
      offset,
      limitCount,
      reporterId,
      reportedId,
      conversationId
    }
  };
};

export const viewReportedConversationSuccess = data => {
  return {
    type: VIEW_REPORTED_CONVERSATION_SUCCESS,
    payload: data
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

export const resetCreatedUsers = () => {
  return {
    type: RESET_CREATED_USERS
  };
};

export const resetUsersWithoutL2 = () => {
  return {
    type: RESET_USERS_WITHOUT_L2
  };
};
export const resetRecentCheckList = () => {
  return {
    type: RESET_RECENT_CHECK_LIST
  };
};

export const setPageDate = (flag, page) => {
  return {
    type: SET_PAGE_INDEX,
    payload: { flag, page }
  };
};

export const setClickedRow = row => {
  return {
    type: SET_CLIKED_ROW,
    payload: row
  };
};

export const updateCreatedDataList = option => {
  return {
    type: UPDATE_DATA_LIST,
    payload: option
  };
};

export const resetReportedProfileList = () => {
  return {
    type: RESET_REPORTED_PROFILE_LIST
  };
};

export const resetReportedConversationList = () => {
  return {
    type: RESET_CONVERSATION_PROFILE_LIST
  };
};

export const addAdminCheck = (userId, comment) => {
  return {
    type: ADD_ADMIN_CHECK,
    payload: { userId, comment }
  };
};

export const addAdminCheckSuccess = (flag, data) => {
  return {
    type: ADD_ADMIN_CHECK_SUCCESS,
    payload: { flag, data }
  };
};

export const adminCheckList = (limit, offset, userId, count) => {
  return {
    type: ADMIN_CHECK_LIST,
    payload: { limit, offset, userId, count }
  };
};

export const adminCheckListSuccess = data => {
  return {
    type: ADMIN_CHECK_LIST_SUCCESS,
    payload: data
  };
};

export const resetOffsetAdminCheckList = () => {
  return {
    type: RESET_OFFSET_ADMIN_CHECK_LIST
  };
};

export const setAppLocal = data => {
  return {
    type: SET_APP_LOCAL,
    payload: data
  };
};

export const openPopupAction=(data)=>{
  return {
    type: OPEN_POPUP_ACTION,
    payload: data
  };
}

export const getUsersWithoutL2 =(limit,
  offset,
  userCountry,
  userCity,
  gender,
  phoneVerified,
  phoneNumber,
  createdDateFrom,
  createdDateTo,
  jnt,
  count)=>{
  return {
    type:GET_USERS_WITHOUT_L2,
    payload:{limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneVerified,
      phoneNumber,
      createdDateFrom,
      createdDateTo,
      jnt,
      count}
  }
}

export const getUsersWithoutL2Success=(data)=>{
  return {
    type:GET_USERS_WITHOUT_L2_SUCCESS,
    payload:data
  }
}


export const getRecentCheckList =(limit,
  offset,
  userCountry,
  userCity,
  gender,
  phoneVerified,
  phoneNumber,
  createdDateFrom,
  createdDateTo,
  jnt,
  count,
  subscriptionCreatedDateFrom,
  subscriptionCreatedDateTo,
  mpFlag
)=>{
  return {
    type:GET_RECENT_CHECK_LIST,
    payload:{limit,
      offset,
      userCountry,
      userCity,
      gender,
      phoneVerified,
      phoneNumber,
      createdDateFrom,
      createdDateTo,
      jnt,
      count,
      subscriptionCreatedDateFrom,
      subscriptionCreatedDateTo,
      mpFlag
    }
  }
}

export const getRecentCheckListSuccess=(data)=>{
  return {
    type:GET_RECENT_CHECK_LIST_SUCCESS,
    payload:data
  }
}


export const setCountrySelectedUncompleted = (value) => {
  return {
    type: SET_COUNTRY_SELECTED_UNCOMPLETED,
    payload: value
  };
};

export const setCitySelectedUncompleted = (value) => {
  return {
    type: SET_CITY_SELECTED_UNCOMPLETED,
    payload: value
  };
};

export const setGenderSelectedUncompleted = (value) => {
  return {
    type: SET_GENDER_SELECTED_UNCOMPLETED,
    payload: value
  };
};

export const setPhoneVerifiedSelectedUncompleted = (value) => {
  return {
    type: SET_PHONE_VERIFIED_UNCOMPLETED,
    payload: value
  };
};

export const setMPCheck= (value) => {
  return {
    type: SET_MAIN_PHOTO,
    payload: value
  };
};

export const setPhoneSelectedUncompleted = (value) => {
  return {
    type: SET_PHONE_SELECTED_UNCOMPLETED,
    payload: value
  };
};

export const setCreatedDateFromSelectedUncompleted = (value) => {
  return {
    type: SET_CREATED_DATE_FROM_SELECTED_UNCOMPLETED,
    payload: value
  };
};

export const setCreatedDateToSelectedUncompleted = (value) => {
  return {
    type: SET_CREATED_DATE_TO_SELECTED_UNCOMPLETED,
    payload: value
  };
};

export const setCurrentListType=(type)=>{
  return{
    type:SET_CURRENT_LIST_TYPE,
    payload: type
  }
}

export const readUserVoiceMessage= (conversationId,genderOfReporter,reporterUserCountry,reporterUserCity,reporterUserVa,reporterId,reporterjnt,genderOfReported,reportedUserCountry,reportedUserCity,reportedUserVa,reportedId,reportedjnt,reportedMessage,reportPosition) =>{
  return{
    type:READ_USER_VOICE_MESSAGE,
    payload:{conversationId,
      genderOfReporter,
      reporterUserCountry,
      reporterUserCity,
      reporterUserVa,
      reporterId,
      reporterjnt,
      genderOfReported,
      reportedUserCountry,
      reportedUserCity,
      reportedUserVa,
      reportedId,
      reportedjnt,
      reportedMessage,
      reportPosition}
  }
}

export const readUserVoiceMessageSuccess= (data)=>
    {
  return{
    type:READ_USER_VOICE_MESSAGE_SUCCESS,
    payload:data
  }
}

export const updateAudioBlobList=(newObject)=>{
  return{
    type:UPDATE_AUDIO_BLOB_LIST,
    payload:newObject
  }
}

export const openPopupAddSubscription=(data)=>{
  return{
    type:OPEN_ADD_SUBSCRIPTION_POPUP,
    payload:data
  }
}

export const setSubscribeOptionSelected=(data)=>{
  return{
    type:SET_SUBSCRIBE_OPTION_SELECTED,
    payload:data
  }
}

export const addUserSubscription = (userId, amount, paymentPeriod,paymentType,paymentCurrency) => {
  return {
    type: ADD_USER_SUBSCRIPTION,
    payload: {
      userId, amount, paymentPeriod,paymentType,paymentCurrency
    }
  };
};

export const addUserSubscriptionSuccess = data => {
  return {
    type: ADD_USER_SUBSCRIPTION_SUCCESS,
    payload: data
  };
};


export const  setSubscriptionDateFromSelected = data =>{
  return {
    type: SET_SUBSCRIPTION_DATE_FROM_SELECTED,
    payload: data
  };
}

export const  setSubscriptionDateToSelected = data =>{
  return {
    type: SET_SUBSCRIPTION_DATE_TO_SELECTED,
    payload: data
  };
}

export const getUserSubscriptionList = (userId,limit,offset,count) => {
  return {
    type: GET_USER_SUBSCRIPTION_LIST,
    payload: {
      userId,limit,offset,count
    }
  };
};

export const getUserSubscriptionListSuccess = data => {
  return {
    type: GET_USER_SUBSCRIPTION_LIST_SUCCESS,
    payload: data
  };
};
