import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Button from "@material-ui/core/Button";
import {
  hideMessage,
  getCreatedUsers,
  viewUserFullProfile,
  suspendUser,
  suspendUserSuccess,
  unsuspendUser,
  unsuspendUserSuccess,
  getReportedProfileList,
  getReportedConversationList,
  viewReportedConversation,
  resetReportedProfileList,
  resetReportedConversationList,
  openPopupChat,
  resetConversationViewData
} from "../redux/actions/Home";

import { makeStyles } from "@material-ui/core/styles";
import {
  COUNTRY_MAP,
  COUNTRY_CITY_MAP,
  ARRAYS_OF_REASONS,
  ARRAYS_OF_REASONS_CONV
} from "../util/data";

import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReceivedMessageCell from "./ReceivedMessageCell";
import SentMessageCell from "./SentMessageCell";

///////
import { DataGrid } from "@material-ui/data-grid";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PageviewIcon from "@material-ui/icons/Pageview";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Flag from "react-world-flags";

import Carousel from "react-material-ui-carousel";

import { mapUserPhotoUrl } from "../helpers/mapUserPhotoUrl";

import { useLocation } from "react-router-dom";

import ModalChat from "./modals/chat";

//////

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

//////
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    width: "50%"
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  avatar: {
    margin: "auto"
  },
  icons: {
    width: "35%",
    margin: "auto"
  }
}));

const UsersList2 = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const [finalCreatedUsersData, setFinalCreatedUsersData] = useState([]);
  const [currentClickedUser, setCurrentClickedUser] = useState(null);
  const [finalUserPhotoUrl, setFinalUserPhotoUrl] = useState(null);
  const [finalUserPhotoPPUrl, setFinalUserPhotoPPUrl] = useState(null);

  ///////
  const reportedProfileData = useSelector(
    state => state.home.reportedProfileData
  );
  const [reporterDetails, setReporterDetails] = useState(null);
  const [reportedDetails, setReportedDetails] = useState(null);
  const [clickedConversationId, setClickedConversationId] = useState(null);
  const [clickedReportedId, setClickedReportedId] = useState(null);
  const [clickedReporterId, setClickedReporterId] = useState(null);

  const reportedConversationData = useSelector(
    state => state.home.reportedConversationData
  );

  const limitReportedProfileData = useSelector(
    state => state.home.limitReportedProfileData
  );

  const limitReportedConversationData = useSelector(
    state => state.home.limitReportedConversationData
  );
  const offsetReportedProfileData = useSelector(
    state => state.home.offsetReportedProfileData
  );
  const offsetReportedConversationData = useSelector(
    state => state.home.offsetReportedConversationData
  );

  const totalCountReportedConversationData = useSelector(
    state => state.home.totalCountReportedConversationData
  );

  const totalCountReportedProfileData = useSelector(
    state => state.home.totalCountReportedProfileData
  );

  ///

  const reportedConversationView = useSelector(
    state => state.home.reportedConversationView
  );

  const OffsetConversationMessages = useSelector(
    state => state.home.OffsetConversationMessages
  );
  const scoreLConversationMessages = useSelector(
    state => state.home.scoreLConversationMessages
  );

  const endOfConversationMessages = useSelector(
    state => state.home.endOfConversationMessages
  );


  const showMessage = useSelector(state => state.home.showMessage);
  const alertMessage = useSelector(state => state.home.alertMessage);

  const openChat = useSelector(state => state.home.openChat);
  ////

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /////

  const columns2 = [
    {
      field: "id",
      headerName: "User Id",
      width: 180
      // disableClickEventBubbling: true,
      // renderCell: params => {
      //   params.row.id = params.row.userId;
      //   delete params.row.userId;
      //   return <></>;
      // }
    },
    { field: "fullName", headerName: "Name", width: 180 },
    {
      field: "userCountry",
      headerName: "Country",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return <>{t(COUNTRY_MAP[params.row.userCountry])}</>;
      }
    },
    {
      field: "userCity",
      headerName: "City",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>
            {t(
              COUNTRY_CITY_MAP[params.row.userCountry][params.row.userCity - 1]
            )}
          </>
        );
      }
    },
    { field: "userVa", headerName: "virtual area", width: 180 },
    { field: "birthDay", headerName: "BirthDay", width: 180 },
    {
      field: "jnt",
      headerName: "Join Time",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>{moment(new Date(params.row.jnt * 100000)).format("YYYY-MM-DD")}</>
        );
      }
    },
    { field: "phoneNumber", headerName: "phone Number", width: 180 },
    { field: "reportedProfile", headerName: "Reported Profile", width: 180 },
    {
      field: "reportedConversation",
      headerName: "Reported Conversation",
      width: 180
    },
    {
      field: "suspendedUser",
      headerName: "Suspended",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (params.row.suspendedUser === 1) {
                dispatch(
                  unsuspendUser(
                    params.row.id,
                    params.row.gender,
                    params.row.userCountry,
                    params.row.userCity,
                    params.row.userVa,
                    params.row.jnt
                  )
                );
              } else {
                dispatch(
                  suspendUser(
                    params.row.id,
                    params.row.gender,
                    params.row.userCountry,
                    params.row.userCity,
                    params.row.userVa,
                    params.row.jnt
                  )
                );
              }
            }}
          >
            {params.row.suspendedUser === 1 ? "Unsuspend User" : "Suspend User"}
          </Button>
        );
      }
    },
    {
      field: "createdDate",
      headerName: "created Date",
      width: 180
    },
    {
      field: "location",
      headerName: "Location",
      width: 180
    },
    {
      field: "ip",
      headerName: "IP",
      width: 180
    },
    {
      field: "macAddress",
      headerName: "MacAddress",
      width: 180
    },
    {
      field: "hardwareName",
      headerName: "HardwareName",
      width: 180
    },
    {
      field: "hardwareVendor",
      headerName: "HardwareVendor",
      width: 180
    },
    {
      field: "viewFullProfile",
      headerName: "View Full Profile",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          // <Button
          //   variant="contained"
          //   color="secondary"
          // >
          <PageviewIcon
            color="secondary"
            fontSize="large"
            onClick={() => {
              setFinalUserPhotoUrl(null);
              setCurrentClickedUser(params.row);
              // dispatch(
              //   viewUserFullProfile(
              //     params.row.id,
              //     params.row.gender,
              //     params.row.userCountry,
              //     params.row.userCity,
              //     params.row.userVa,
              //     params.row.jnt
              //   )
              // );
            }}
          />
          // </Button>
        );
      }
    }
  ];

  const columns = [
    { id: "reporterId", label: t("data.reporterId"), minWidth: 170 },
    { id: "reportedId", label: t("data.reportedId"), minWidth: 170 },
    { id: "reasonId", label: t("data.reasonId"), minWidth: 100 },
    { id: "comment", label: t("data.comment"), minWidth: 100 }
  ];

  const columns1 = [
    { id: "reporterId", label: t("data.reporterId"), minWidth: 170 },
    { id: "reportedId", label: t("data.reportedId"), minWidth: 170 },
    { id: "reasonId", label: t("data.reasonId"), minWidth: 100 },
    { id: "comment", label: t("data.comment"), minWidth: 100 },
    { id: "conversationId", label: t("data.conversationId"), minWidth: 70 }
  ];

  const columnsDetails = [
    { id: "userCountry", label: t("filter.country"), minWidth: 100 },
    { id: "userCity", label: t("filter.city"), minWidth: 100 },
    { id: "userVa", label: t("data.virtualArea"), minWidth: 100 },
    { id: "jnt", label: t("data.joinTime"), minWidth: 100 }
  ];

  /////

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /////

  useEffect(() => {
    if (location.pathname === "/ReportedProfileList") {
      dispatch(resetReportedProfileList());
      dispatch(
        getReportedProfileList(
          limitReportedProfileData,
          offsetReportedProfileData,
          "",
          "",
          "",
          1
        )
      );
      dispatch(
        getReportedProfileList(
          limitReportedProfileData,
          offsetReportedProfileData,
          "",
          "",
          "",
          0
        )
      );
    } else if (location.pathname === "/ReportedConvList") {
      dispatch(resetReportedConversationList());
      dispatch(
        getReportedConversationList(
          limitReportedConversationData,
          offsetReportedConversationData,
          "",
          "",
          "",
          1
        )
      );
      dispatch(
        getReportedConversationList(
          limitReportedConversationData,
          offsetReportedConversationData,
          "",
          "",
          "",
          0
        )
      );
    }
  }, []);

  //   useEffect(() => {
  //     // if (createdUsersData.length !== 0) {
  //     // setFinalCreatedUsersData([]);
  //     // createdUsersData.map(user => {
  //     //   user.id = user.userId;
  //     //   delete user.userId;
  //     //   // user.userCity = t(
  //     //   //   COUNTRY_CITY_MAP[user.userCountry][user.userCity - 1]
  //     //   // );
  //     //   // user.userCountry = t(COUNTRY_MAP[user.userCountry]);
  //     //   //user.jnt = moment(new Date(user.jnt * 100000)).format("YYYY-MM-DD");
  //     // });
  //     // console.log("createdUsersData from component ", createdUsersData);
  //     // setFinalCreatedUsersData(createdUsersData);
  //     // // dispatch(
  //     // //   viewUserFullProfile(
  //     // //     "f1486d19-b1e4-468a-8acd-eff9bbfa33e0",
  //     // //     1,
  //     // //     "EG",
  //     // //     3,
  //     // //     1,
  //     // //     16097031
  //     // //   )
  //     // // );
  //     // // }
  //   }, [createdUsersData]);

  // useEffect(() => {
  //   if (reportedConversationView.length !== 0) {
  //     handleOpen();
  //   }
  // }, [reportedConversationView]);

  //   useEffect(() => {
  //     if (currentClickedUser) {
  //       console.log("currentClickedUser from component ", currentClickedUser);
  //       dispatch(
  //         viewUserFullProfile(
  //           currentClickedUser.id,
  //           currentClickedUser.gender,
  //           currentClickedUser.userCountry,
  //           currentClickedUser.userCity,
  //           currentClickedUser.userVa,
  //           currentClickedUser.jnt
  //         )
  //       );
  //       //  setFinalUserPhotoUrl(mapUserPhotoUrl());
  //       // handleOpen();
  //     }
  //   }, [currentClickedUser]);

  //   useEffect(() => {
  //     if (userFullProfileData && currentClickedUser) {
  //       console.log("userFullProfileData from component ", userFullProfileData);
  //       setFinalUserPhotoUrl(
  //         mapUserPhotoUrl(
  //           currentClickedUser.id,
  //           userFullProfileData.mainpicture,
  //           userFullProfileData.mainpictureSR
  //         )
  //       );
  //       if (userFullProfileData.privatepictures !== null) {
  //         setFinalUserPhotoPPUrl(
  //           mapUserPhotoUrl(
  //             currentClickedUser.id,
  //             userFullProfileData.privatepictures,
  //             userFullProfileData.privatepicturesSR
  //           )
  //         );
  //       }
  //       // handleOpen();
  //     }
  //   }, [userFullProfileData]);

  //   useEffect(() => {
  //     if (finalUserPhotoUrl) {
  //       console.log("finalUserPhotoUrl from component ", finalUserPhotoUrl);
  //       handleOpen();
  //     }
  //   }, [finalUserPhotoUrl]);

  //   useEffect(() => {
  //     if (finalUserPhotoPPUrl) {
  //       console.log("finalUserPhotoPPUrl from component ", finalUserPhotoPPUrl);
  //     }
  //   }, [finalUserPhotoPPUrl]);

  useEffect(() => {
    if (showMessage) {
      NotificationManager.error(alertMessage);
      dispatch(hideMessage());
    }
  }, [showMessage]);



  useEffect(() => {
    if (reporterDetails) {
      handleOpen();
    }
  }, [reporterDetails]);

  useEffect(() => {
    if (reportedDetails) {
      handleOpen();
    }
  }, [reportedDetails]);

  // useEffect(() => {
  //   if (clickedConversationId) {
  //     dispatch(
  //       viewReportedConversation(
  //         scoreLConversationMessages,
  //         OffsetConversationMessages,
  //         1,
  //         clickedReporterId,
  //         clickedReportedId,
  //         clickedConversationId
  //       )
  //     );
  //     //  handleOpen();
  //   }
  // }, [clickedConversationId]);

  // // handle scroll for list of Conversation messages
  // const handleScrollConversationMessages = () => {
  //   if (!endOfConversationMessages) {
  //     // sent get Conversation messages (next options)
  //     dispatch(
  //       viewReportedConversation(
  //         scoreLConversationMessages,
  //         OffsetConversationMessages,
  //         1,
  //         clickedReporterId,
  //         clickedReportedId,
  //         clickedConversationId
  //       )
  //     );
  //   }
  // };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {location.pathname === "/ReportedProfileList"
                  ? columns.map(column => (
                      <TableCell
                        key={column.id}
                        // align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))
                  : location.pathname === "/ReportedConvList"
                  ? columns1.map(column => (
                      <TableCell
                        key={column.id}
                        // align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))
                  : ""}
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log("reportedProfileData ",reportedProfileData)}
              {location.pathname === "/ReportedProfileList" &&
                reportedProfileData.length != 0 &&
                reportedProfileData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.reporterId}
                      >
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : column.id === "reporterId" ||
                                column.id === "reportedId" ? (
                                <>
                                  {value}
                                  <PageviewIcon
                                    color="secondary"
                                    fontSize="large"
                                    onClick={() => {
                                      if (column.id === "reporterId") {
                                        setReportedDetails(null);
                                        setReporterDetails(
                                          reportedProfileData[i].reporterDetails
                                        );
                                      } else if (column.id === "reportedId") {
                                        setReporterDetails(null);

                                        setReportedDetails(
                                          reportedProfileData[i].reportedDetails
                                        );
                                      }
                                      //   setFinalUserPhotoUrl(null);
                                      //   setCurrentClickedUser(params.row);
                                      // dispatch(
                                      //   viewUserFullProfile(
                                      //     params.row.id,
                                      //     params.row.gender,
                                      //     params.row.userCountry,
                                      //     params.row.userCity,
                                      //     params.row.userVa,
                                      //     params.row.jnt
                                      //   )
                                      // );
                                    }}
                                  />
                                </>
                              ) : column.id === "reasonId" ? (
                                t(ARRAYS_OF_REASONS[value - 1])
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              {location.pathname === "/ReportedConvList" &&
                reportedConversationData.length != 0 &&
                reportedConversationData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.reporterId}
                      >
                        {columns1.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : column.id === "reporterId" ||
                                column.id === "reportedId" ||
                                column.id === "conversationId" ? (
                                <>
                                  <span style={{ marginRight: "1rem" }}>
                                    {value}
                                  </span>
                                  <PageviewIcon
                                    color="secondary"
                                    fontSize="large"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (column.id === "reporterId") {
                                        setReportedDetails(null);
                                        setClickedConversationId(null);
                                        setReporterDetails(
                                          reportedConversationData[i]
                                            .reporterDetails
                                        );
                                      } else if (column.id === "reportedId") {
                                        setReporterDetails(null);
                                        setClickedConversationId(null);
                                        setReportedDetails(
                                          reportedConversationData[i]
                                            .reportedDetails
                                        );
                                      } else if (
                                        column.id === "conversationId"
                                      ) {
                                        setReportedDetails(null);
                                        setReporterDetails(null);
                                        dispatch(resetConversationViewData());
                                        dispatch(openPopupChat(true));
                                        setClickedReportedId(
                                          reportedConversationData[i]
                                        );
                                        setClickedReporterId(
                                          reportedConversationData[i]
                                        );
                                        setClickedConversationId(
                                          reportedConversationData[i]
                                            .conversationId
                                        );
                                      }
                                      //   setFinalUserPhotoUrl(null);
                                      //   setCurrentClickedUser(params.row);
                                      // dispatch(
                                      //   viewUserFullProfile(
                                      //     params.row.id,
                                      //     params.row.gender,
                                      //     params.row.userCountry,
                                      //     params.row.userCity,
                                      //     params.row.userVa,
                                      //     params.row.jnt
                                      //   )
                                      // );
                                    }}
                                  />
                                </>
                              ) : column.id === "reasonId" ? (
                                t(ARRAYS_OF_REASONS_CONV[value - 1])
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={
            location.pathname === "/ReportedProfileList" && reportedProfileData
              ? totalCountReportedProfileData
              : //reportedProfileData.length
              location.pathname === "/ReportedConvList" &&
                reportedConversationData
              ? totalCountReportedConversationData
              : // reportedConversationData.length
                ""
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* <div style={{ height: 400, width: "100%" }}>
        {finalCreatedUsersData.length !== 0 && (
        <DataGrid
          rows={
            location.pathname === "/ReportedProfileList" && reportedProfileData
              ? reportedProfileData
              : location.pathname === "/ReportedConvList" &&
                reportedConversationData
              ? reportedConversationData
              : []
          }
          columns={columns2}
          filterModel={filterModel}
          onFilterModelChange={(model) => setFilterModel(model)}
        />
        )}
      </div> */}

      {(reportedDetails || reporterDetails) && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              {(reportedDetails || reporterDetails) && (
                <Paper className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columnsDetails.map(column => (
                            <TableCell
                              key={column.id}
                              // align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportedDetails && (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={reportedDetails.jnt}
                          >
                            {columnsDetails.map(column => {
                              const value = reportedDetails[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : column.id === "userCountry"
                                    ? t(
                                        COUNTRY_MAP[reportedDetails.userCountry]
                                      )
                                    : column.id === "userCity"
                                    ? t(
                                        COUNTRY_CITY_MAP[
                                          reportedDetails.userCountry
                                        ][reportedDetails.userCity - 1]
                                      )
                                    : column.id === "jnt"
                                    ? moment(
                                        new Date(reportedDetails.jnt * 100000)
                                      ).format("YYYY-MM-DD")
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )}
                        {reporterDetails && (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={reporterDetails.jnt}
                          >
                            {columnsDetails.map(column => {
                              const value = reporterDetails[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : column.id === "userCountry"
                                    ? t(
                                        COUNTRY_MAP[reporterDetails.userCountry]
                                      )
                                    : column.id === "userCity"
                                    ? t(
                                        COUNTRY_CITY_MAP[
                                          reporterDetails.userCountry
                                        ][reporterDetails.userCity - 1]
                                      )
                                    : column.id === "jnt"
                                    ? moment(
                                        new Date(reporterDetails.jnt * 100000)
                                      ).format("YYYY-MM-DD")
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
              {/* {clickedConversationId && (
                <> */}
              {/* <Typography variant="h6" gutterBottom>
                    {t(`chat.msg`)}
                  </Typography>
                  <div className="users-main-content users-main-content-2">
                    <div
                      id="scrollableDiv"
                      style={{
                        height: 300,
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column-reverse"
                      }}
                    >
                      <InfiniteScroll
                        dataLength={reportedConversationView.length}
                        next={handleScrollConversationMessages}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse"
                        }}
                        inverse={true} //
                        hasMore={!endOfConversationMessages}
                        loader={<CircularProgress />}
                        scrollableTarget="scrollableDiv"
                        className="scroll-m"
                      >
                        {reportedConversationView.map((conversation, index) =>
                          conversation.o === 1 ? (
                            <>
                              <SentMessageCell
                                key={index}
                                conversation={conversation}
                                // myPhoto={myPhoto}
                                // seen={seenFlag === 0 ? false : true}
                              />
                            </>
                          ) : (
                            <ReceivedMessageCell
                              key={index}
                              conversation={conversation}
                              //   user={clickedUserChat}
                            />
                          )
                        )}
                      </InfiniteScroll>
                    </div>
                  </div> */}

              {/* }
                </>
              )} */}
            </div>
          </Fade>
        </Modal>
      )}

      {clickedConversationId && openChat && (
        <ModalChat
          clickedConversationId={clickedConversationId}
          clickedReportedRow={clickedReportedId}
          // clickedReporterId={clickedReporterId}
        ></ModalChat>
      )}
      <NotificationContainer />
    </>
  );
};

export default UsersList2;
