import { callAxios } from "./callAxios";

import { getCookie } from "../../util/session";
const home = {};

home.getCreatedUsers = function(
  limit,
  offset,
  userCountry,
  userCity,
  gender,
  phoneNumber,
  reportedProfile,
  reportedProfileReasonId,
  reportedConversation,
  reportedConversationReasonId,
  suspended,
  count,
  dateFrom,
  dateTo,
  recentFlag,
  userId,
  maritalStatus,
  createdDateFrom,
  createdDateTo,
  check,
  checkRange,
  checkTimestampOrder,
  createdTimeOrder,
  ageRange,
  orand,
  subscriptionFlag,
  subscriptionFlagOrder
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url:(subscriptionFlag==""||subscriptionFlag=="2")? `/user/createduserslist?limit=${limit}&offset=${offset}&userCountry=${userCountry}&userCity=${userCity}&gender=${gender}&phoneNumber=${phoneNumber}&reportedProfile=${reportedProfile}&reportedProfileReasonId=${reportedProfileReasonId}&reportedConversation=${reportedConversation}&reportedConversationReasonId=${reportedConversationReasonId}&count=${count}&suspended=${suspended}&dateFrom=${dateFrom}&dateTo=${dateTo}&recentFlag=${recentFlag},
        &userId=${userId}&m=${maritalStatus}&createdDateFrom=${createdDateFrom}&createdDateTo=${createdDateTo}&check=${check}&checkRange=${checkRange}&checkTimestampOrder=${checkTimestampOrder}&createdTimeOrder=${createdTimeOrder}&birthdayRange=${ageRange}&andOr=${orand}&subscriptionFlag=${subscriptionFlag}`
        :
        `/user/createduserslist?limit=${limit}&offset=${offset}&userCountry=${userCountry}&userCity=${userCity}&gender=${gender}&phoneNumber=${phoneNumber}&reportedProfile=${reportedProfile}&reportedProfileReasonId=${reportedProfileReasonId}&reportedConversation=${reportedConversation}&reportedConversationReasonId=${reportedConversationReasonId}&count=${count}&suspended=${suspended}&dateFrom=${dateFrom}&dateTo=${dateTo}&recentFlag=${recentFlag},
        &userId=${userId}&m=${maritalStatus}&createdDateFrom=${createdDateFrom}&createdDateTo=${createdDateTo}&check=${check}&checkRange=${checkRange}&checkTimestampOrder=${checkTimestampOrder}&createdTimeOrder=${createdTimeOrder}&birthdayRange=${ageRange}&andOr=${orand}&subscriptionFlag=${subscriptionFlag}&subscriptionFlagOrder=${subscriptionFlagOrder}`
        ,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;
      if (response.data) {
        resolve(response.data);
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};


home.getUsersWithoutL2 = function(
  limit,
  offset,
  userCountry,
  userCity,
  gender,
  phoneVerified,
  phoneNumber,
  createdDateFrom,
  createdDateTo,
  jnt,
  count
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/layer2/layer2-check-list?limit=${limit}&offset=${offset}&userCountry=${userCountry}&userCity=${userCity}&gender=${gender}&phoneVerified=${phoneVerified}&phoneNumber=${phoneNumber}&count=${count}&createdDateFrom=${createdDateFrom}&createdDateTo=${createdDateTo}&jnt=${jnt}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.data) {
        resolve(response.data);
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};


home.getRecentCheckList = function(
  limit,
  offset,
  userCountry,
  userCity,
  gender,
  phoneVerified,
  phoneNumber,
  createdDateFrom,
  createdDateTo,
  jnt,
  count,
  subscriptionCreatedDateFrom,
  subscriptionCreatedDateTo,
  mpFlag
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/recent/recent-check-list?limit=${limit}&offset=${offset}&userCountry=${userCountry}&userCity=${userCity}&gender=${gender}&phoneVerified=${phoneVerified}&phoneNumber=${phoneNumber}&count=${count}&createdDateFrom=${createdDateFrom}&createdDateTo=${createdDateTo}&jnt=${jnt}&subscriptionCreatedDateFrom=${subscriptionCreatedDateFrom}&subscriptionCreatedDateTo=${subscriptionCreatedDateTo}&mpFlag=${mpFlag}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;
      if (response.data) {
        resolve(response.data);
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};





home.viewUserFullProfile = function(
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/user/view-user-profile`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        },
        data: {
          userId,
          gender,
          userCountry,
          userCity,
          userVa,
          jnt
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.data) {
        resolve(response.data);
      } else if (!response.data && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.getRecentUsers = function(limit, offset, userId, gender, count) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/recent/recent-user-list-admin?limit=${limit}&offset=${offset}&gender=${gender}&userId=${userId}&count=${count}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.data) {
        resolve(response.data);
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.suspendUser = function(
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/suspension/suspend-user`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        },
        data: {
          userId,
          gender,
          userCountry,
          userCity,
          userVa,
          jnt
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve({ suspended: true });
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.unsuspendUser = function(
  userId,
  gender,
  userCountry,
  userCity,
  userVa,
  jnt
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/suspension/unsuspend-user`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        },
        data: {
          userId,
          gender,
          userCountry,
          userCity,
          userVa,
          jnt
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve({ unsuspended: true });
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.getReportedProfileList = function(
  limit,
  offset,
  reporterId,
  reportedId,
  reasonId,
  count
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/reports/reported-profile-list?limit=${limit}&offset=${offset}&reporterId=${reporterId}&reportedId=${reportedId}&reasonId=${reasonId}&count=${count}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve({ profileList: response.data });
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.getReportedConversationList = function(
  limit,
  offset,
  reporterId,
  reportedId,
  reasonId,
  count
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/reports/reported-conversation-list?limit=${limit}&offset=${offset}&reporterId=${reporterId}&reportedId=${reportedId}&reasonId=${reasonId}&count=${count}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve({ List: response.data });
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.viewReportedConversation = function(
  scoreL,
  offset,
  limitCount,
  reporterId,
  reportedId,
  conversationId
) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/reports/view-reported-conversation-profile`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        },
        data: {
          scoreL,
          offset,
          limitCount,
          reporterId,
          reportedId,
          conversationId
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve({ List: response.data.conversation });
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.addAdminCheck = function(userId, comment) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/admin-check/add-admin-check`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        },
        data: {
          userId,
          comment
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve(response.data);
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.getAdminCheckList = function(limit, offset, userId, count) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/admin-check/admin-check-list?limit=${limit}&offset=${offset}&userId="${userId}"&count=${count}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      if (response.status === 1) {
        resolve(response.data);
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.readUserVoiceMessage=function(conversationId,
  genderOfReporter,
  reporterUserCountry,
  reporterUserCity,
  reporterUserVa,
  reporterId,
  reporterjnt,
  genderOfReported,
  reportedUserCountry,
  reportedUserCity,
  reportedUserVa,
  reportedId,
  reportedjnt,
  reportedMessage,
  reportPosition) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/reports/read-user-voice-message`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        },
        data:{
          conversationId,
          genderOfReporter,
          reporterUserCountry,
          reporterUserCity,
          reporterUserVa,
          reporterId,
          reporterjnt,
          genderOfReported,
          reportedUserCountry,
          reportedUserCity,
          reportedUserVa,
          reportedId,
          reportedjnt,
          reportedMessage,
          reportPosition
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      console.log("response read voice ",response)
      if (response.status === 1) {
        resolve(response.data);
      } else if (!(response.status === 1) && response.message) {
        resolve({ message: response.message });
      } else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.addUserSubscription=function(userId, amount, paymentPeriod,paymentType,paymentCurrency) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/subscription/add-subscription`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        },
        data:{
          userId, amount, paymentPeriod,paymentType,paymentCurrency
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      console.log("addUserSubscription",response)
      if (response) {
        resolve(response.data);
      }
      else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

home.getUserSubscriptionList=function(userId,limit,offset,count) {
  return new Promise(async (resolve, reject) => {
    try {
      const tokenValue = getCookie("access_token", false);
      const options = {
        url: `/subscription/admin-subscription-list?limit=${limit}&offset=${offset}&userId=${userId}&count=${count}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + tokenValue
        }
      };

      let responseX = await callAxios(options);
      let response = responseX.data;

      console.log("addUserSubscription",response)
      if (response) {
        resolve(response.data);
      }
      else {
        resolve({ message: "no response !" });
      }
    } catch (err) {
      resolve({ message: err.message });
    }
  }).catch(err => {
    console.log(err);
  });
};

export { home };
