import {
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  GET_CREATED_USERS_SUCCESS,
  VIEW_USER_FULL_PROFILE_SUCCESS,
  GET_RECENT_USERS_SUCCESS,
  SUSPEND_USER_SUCCESS,
  UNSUSPEND_USER_SUCCESS,
  GET_REPORTED_PROFILE_LIST_SUCCESS,
  GET_REPORTED_CONVERSATION_LIST_SUCCESS,
  VIEW_REPORTED_CONVERSATION_SUCCESS,
  RESET_CREATED_USERS,
  CHANGE_LIMIT_DATA_RETURNED,
  SET_PAGE_INDEX,
  SET_CLIKED_ROW,
  UPDATE_DATA_LIST,
  RESET_REPORTED_PROFILE_LIST,
  RESET_CONVERSATION_PROFILE_LIST,
  ADD_ADMIN_CHECK_SUCCESS,
  ADMIN_CHECK_LIST_SUCCESS,
  RESET_OFFSET_ADMIN_CHECK_LIST,
  SET_COUNTRY_SELECTED,
  SET_AGE_FROM_SELECTED,
  SET_AND_OR_OPTION_SELECTED,
  SET_AGE_TO_SELECTED,
  SET_CHECKED_DATE_FROM_SELECTED,
  SET_CHECKED_OPTION_SELECTED,
  SET_CHECKED_RECENT_USERS_SELECTED,
  SET_CHECK_ORDER_BY_SELECTED,
  SET_SUBSCRIBE_ORDER_BY_SELECTED,
  SET_CITY_SELECTED,
  SET_CREATED_DATE_FROM_SELECTED,
  SET_CREATED_DATE_TO_SELECTED,
  SET_CREATE_ORDER_BY_SELECTED,
  SET_DATE_FROM_SELECTED,
  SET_DATE_TO_SELECTED,
  SET_GENDER_SELECTED,
  SET_MARITAL_STATUS_SELECTED,
  SET_PHONE_SELECTED,
  SET_REPORTED_CONVERSATION_REASON_SELECTED,
  SET_REPORTED_CONVERSATION_SELECTED,
  SET_REPORTED_PROFILE_REASON_SELECTED,
  SET_REPORTED_PROFILE_SELECTED,
  SET_SUSPENDED_SELECTED,
  SET_USER_ID_SELECTED,
  SET_CHECKED_DATE_TO_SELECTED,
  SET_APP_LOCAL,
  OPEN_POPUP_CHAT,
  OPEN_POPUP_ACTION,
  RESET_CONVERSATION_VIEW_DATA,
  GET_USERS_WITHOUT_L2_SUCCESS,
  GET_RECENT_CHECK_LIST_SUCCESS,
  SET_COUNTRY_SELECTED_UNCOMPLETED,
  SET_CITY_SELECTED_UNCOMPLETED,
  SET_GENDER_SELECTED_UNCOMPLETED,
  SET_PHONE_VERIFIED_UNCOMPLETED,
  SET_MAIN_PHOTO,
  SET_PHONE_SELECTED_UNCOMPLETED,
  SET_CREATED_DATE_FROM_SELECTED_UNCOMPLETED,
  SET_CREATED_DATE_TO_SELECTED_UNCOMPLETED,
  SET_CURRENT_LIST_TYPE,
  RESET_USERS_WITHOUT_L2,
  RESET_RECENT_CHECK_LIST,
  READ_USER_VOICE_MESSAGE_SUCCESS,
  UPDATE_AUDIO_BLOB_LIST,
  OPEN_ADD_SUBSCRIPTION_POPUP,
  SET_SUBSCRIBE_OPTION_SELECTED,
  ADD_USER_SUBSCRIPTION_SUCCESS,
  SET_SUBSCRIPTION_DATE_FROM_SELECTED,
  SET_SUBSCRIPTION_DATE_TO_SELECTED,
  GET_USER_SUBSCRIPTION_LIST_SUCCESS
} from "../ActionTypes";

import { mapArrayTo2Arr } from "../../helpers/mapArrayTo2Arr";
import { calcValueOfSlAndOffset } from "../../helpers/calcValueOfSlAndOffset";

const initialHomeState = {
  loader: false,
  alertMessage: "",
  showMessage: false,

  createdUsersData: [],
  recentUsersData: [],

  reportedProfileData: [],
  limitReportedProfileData: 10,
  offsetReportedProfileData: 0,
  totalCountReportedProfileData: 0,

  reportedConversationData: [],
  limitReportedConversationData: 10,
  offsetReportedConversationData: 0,
  totalCountReportedConversationData: 0,

  reportedConversationView: [],
  reportedConversationViewDates: [],
  OffsetConversationMessages: 0,
  scoreLConversationMessages: 0,
  endOfConversationMessages: false,
  limitReturnedMessages: 10,

  userFullProfileData: null,

  userSuspended: false,
  userUnsuspended: false,
  limitReturnedData: 25,
  offsetReturnedData: 0,

  totalCountReturnedData: 0,

  dataPageIndex: 0,
  clickedRowData: null,

  adminAddCheck: false,
  returnedAdminAddCheckData: null,

  adminCheckListData: [],
  limitAdminCheckListData: 10,
  offsetAdminCheckListData: 0,
  totalAdminCheckListData: 0,
  dataAdminListPageIndex: 0,

  ///Filter
  countrySelected: "",
  ageFromSelected: "",
  ageToSelected: "",
  andOrOptionSelected: 0,

  checkedDateFromSelected: "",
  checkedDateToSelected: "",

  checkedOptionSelected: "",
  checkedRecentUsersSelected: "",
  checkOrderBySelected: 0,
  subscribeOrderBySelected:0,
  subscribeOptionSelected: "",
  citySelected: "",
  createdDateFromSelected: "",
  createdDateToSelected: "",
  createdOrderBySelected: 0,
  dateFromSelected: "",
  userIdSelected: "",
  dateToSelected: "",
  genderSelected: "",
  maritalStatusSelected: "",
  phoneSelected: "",
  reportedConversationReasonSelected: "",
  reportedConversationSelected: "",
  suspendedSelected: "",
  reportedProfileSelected: "",
  reportedProfileReasonSelected: "",

  local: "en",

  openChat: false,
  openActionList:false,

  openSubscriptionPopup:false,

  /// uncompleted list

  usersWithoutL2Data:[],
  usersWithoutL2Limit:25,
  usersWithoutL2Offset:0,
  totalCountUsersWithoutL2Data: 0,

  countrySelectedList1: "",
  citySelectedList1: "",
  createdDateFromSelectedList1: "",
  createdDateToSelectedList1: "",
  genderSelectedList1: "",
  phoneVerifiedList1:"",
  phoneSelectedList1: "",
  subscriptionDateFromSelected:"",
  subscriptionDateToSelected:"",
  mainPhotoChecked:"",
  

   /// Recent check list
   recentCheckListData:[],
   recentCheckListLimit:25,
   recentCheckListOffset:0,
   totalCountRecentCheckListData: 0,

   countrySelectedList2: "",
   citySelectedList2: "",
   createdDateFromSelectedList2: "",
   createdDateToSelectedList2: "",
   genderSelectedList2: "",
   phoneVerifiedList2:"",
   phoneSelectedList2: "",

   currentListType:"L1",

   userSubscriptionAdded:null,
   userSubscriptionList:[],
   // User Voice Url

   userVoiceMessageUrl:null,
   
   audioBlobList:[]
   

};

const home = (state = initialHomeState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case OPEN_POPUP_CHAT: {
      return {
        ...state,
        openChat: action.payload
      };
    }
    case OPEN_POPUP_ACTION:{
      return {
        ...state,
        openActionList: action.payload
      };
    }
    case GET_USERS_WITHOUT_L2_SUCCESS:{
      if (Array.isArray(action.payload)) {
        if (action.payload.length !== 0) {
          state.usersWithoutL2Offset =
            state.usersWithoutL2Offset + state.usersWithoutL2Limit;
          state.usersWithoutL2Data = [
            ...state.usersWithoutL2Data,
            ...action.payload
          ];
        }
      } else {
        state.totalCountUsersWithoutL2Data = action.payload;
      }
      return {
        ...state,
      };
    }
    case GET_RECENT_CHECK_LIST_SUCCESS:{
      if (Array.isArray(action.payload)) {
        if (action.payload.length !== 0) {
          state.recentCheckListOffset =
            state.recentCheckListOffset + state.recentCheckListLimit;
          state.recentCheckListData = [
            ...state.recentCheckListData,
            ...action.payload
          ];
        }
      } else {
        state.totalCountRecentCheckListData = action.payload;
      }
      return {
        ...state
      };
    }
    case RESET_CONVERSATION_VIEW_DATA: {
      return {
        ...state,
        reportedConversationView: [],
        reportedConversationViewDates: [],
        OffsetConversationMessages: 0,
        scoreLConversationMessages: 0,
        endOfConversationMessages: false
      };
    }
    case SET_PAGE_INDEX: {
      if (action.payload.flag === "d") {
        state.dataPageIndex = action.payload.page;
      } else if (action.payload.flag === "da") {
        state.dataAdminListPageIndex = action.payload.page;
      }
      return {
        ...state
      };
    }
    case SET_CLIKED_ROW: {
      return {
        ...state,
        clickedRowData: action.payload
      };
    }

    case SET_APP_LOCAL: {
      return {
        ...state,
        local: action.payload
      };
    }
    case SET_COUNTRY_SELECTED: {
      return {
        ...state,
        countrySelected: action.payload
      };
    }

    case SET_AGE_FROM_SELECTED: {
      return {
        ...state,
        ageFromSelected: action.payload
      };
    }

    case SET_AGE_TO_SELECTED: {
      return {
        ...state,
        ageToSelected: action.payload
      };
    }

    case SET_AND_OR_OPTION_SELECTED: {
      return {
        ...state,
        andOrOptionSelected: action.payload
      };
    }

    case SET_CHECKED_DATE_FROM_SELECTED: {
      return {
        ...state,
        checkedDateFromSelected: action.payload
      };
    }
    case SET_CHECKED_DATE_TO_SELECTED: {
      return {
        ...state,
        subscribeOptionSelected: action.payload
      };
    }
    case SET_CHECKED_OPTION_SELECTED: {
      return {
        ...state,
        checkedOptionSelected: action.payload
      };
    }
    case SET_SUBSCRIBE_OPTION_SELECTED:{
      return {
        ...state,
        checkedOptionSelected: action.payload
      };
    }
    case SET_CHECKED_RECENT_USERS_SELECTED: {
      return {
        ...state,
        checkedRecentUsersSelected: action.payload
      };
    }
    case SET_CHECK_ORDER_BY_SELECTED: {
      return {
        ...state,
        checkOrderBySelected: action.payload
      };
    }
    case SET_SUBSCRIBE_ORDER_BY_SELECTED:{
      return {
        ...state,
        subscribeOrderBySelected: action.payload
      };
    }
    case SET_CITY_SELECTED: {
      return {
        ...state,
        citySelected: action.payload
      };
    }
    case SET_CREATED_DATE_FROM_SELECTED: {
      return {
        ...state,
        createdDateFromSelected: action.payload
      };
    }
    case SET_CREATED_DATE_TO_SELECTED: {
      return {
        ...state,
        createdDateToSelected: action.payload
      };
    }
    case SET_CREATE_ORDER_BY_SELECTED: {
      return {
        ...state,
        createdOrderBySelected: action.payload
      };
    }
    case SET_DATE_FROM_SELECTED: {
      return {
        ...state,
        dateFromSelected: action.payload
      };
    }
    case SET_USER_ID_SELECTED: {
      return {
        ...state,
        userIdSelected: action.payload
      };
    }
    case SET_DATE_TO_SELECTED: {
      return {
        ...state,
        dateToSelected: action.payload
      };
    }
    case SET_GENDER_SELECTED: {
      return {
        ...state,
        genderSelected: action.payload
      };
    }
    case SET_MARITAL_STATUS_SELECTED: {
      return {
        ...state,
        maritalStatusSelected: action.payload
      };
    }
    case SET_PHONE_SELECTED: {
      return {
        ...state,
        phoneSelected: action.payload
      };
    }
    case SET_REPORTED_CONVERSATION_REASON_SELECTED: {
      return {
        ...state,
        reportedConversationReasonSelected: action.payload
      };
    }
    case SET_REPORTED_CONVERSATION_SELECTED: {
      return {
        ...state,
        reportedConversationSelected: action.payload
      };
    }
    case SET_SUSPENDED_SELECTED: {
      return {
        ...state,
        suspendedSelected: action.payload
      };
    }
    case SET_REPORTED_PROFILE_SELECTED: {
      return {
        ...state,
        reportedProfileSelected: action.payload
      };
    }
    case SET_REPORTED_PROFILE_REASON_SELECTED: {
      return {
        ...state,
        reportedProfileReasonSelected: action.payload
      };
    }

    case UPDATE_DATA_LIST: {
      state.createdUsersData.forEach(e => {
        if (e.id === state.clickedRowData.id) {
          if (action.payload === "s") {
            e.suspended = e.suspended === 0 ? 1 : 0;
          } else if (action.payload === "ch") {
            e.checkState = e.checkState === 0 || e.checkState === 2 ? 1 : 0;
            e.checkStateTime = state.returnedAdminAddCheckData
              ? state.returnedAdminAddCheckData.checkTimestamp
              : "";
          }
        }
      });
      return {
        ...state,
        clickedRowData: null
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false
      };
    }
    case CHANGE_LIMIT_DATA_RETURNED: {
      return {
        ...state,
        limitReturnedData: action.payload
      };
    }

    case GET_CREATED_USERS_SUCCESS: {
      if (Array.isArray(action.payload)) {
        if (action.payload.length !== 0) {
          state.offsetReturnedData =
            state.offsetReturnedData + state.limitReturnedData;
          action.payload.map(user => {
            if (user.userId) {
              user.id = user.userId;
              delete user.userId;
            }
          });
        }
        state.createdUsersData = [...state.createdUsersData, ...action.payload];
      } else {
        state.totalCountReturnedData = action.payload;
      }

      return {
        ...state
        // createdUsersData: [...state.createdUsersData, ...action.payload]
      };
    }
    case GET_REPORTED_PROFILE_LIST_SUCCESS: {
      if (Array.isArray(action.payload)) {
        if (action.payload.length !== 0) {
          state.offsetReportedProfileData =
            state.offsetReportedProfileData + state.limitReportedProfileData;
          state.reportedProfileData = [
            ...state.reportedProfileData,
            ...action.payload
          ];
        }
      } else {
        state.totalCountReportedProfileData = action.payload;
      }
      return {
        ...state
      };
    }
    case GET_REPORTED_CONVERSATION_LIST_SUCCESS: {
      if (Array.isArray(action.payload)) {
        if (action.payload.length !== 0) {
          state.offsetReportedConversationData =
            state.offsetReportedConversationData +
            state.limitReportedConversationData;
          state.reportedConversationData = [
            ...state.reportedConversationData,
            ...action.payload
          ];
        }
      } else {
        state.totalCountReportedConversationData = action.payload;
      }
      return {
        ...state
      };
    }
    case VIEW_REPORTED_CONVERSATION_SUCCESS: {
      let usersList = [],
        scoreList = [],
        IndexOfMsg = null;
      if (action.payload.length != 0) {
        const { usersArr, scoreArr } = mapArrayTo2Arr(action.payload);
        usersList = usersArr;
        scoreList = scoreArr;

        const { offset, SL } = calcValueOfSlAndOffset(scoreArr);
        state.OffsetConversationMessages = offset;
        state.scoreLConversationMessages = SL;

        if (usersList.length < state.limitReturnedMessages) {
          state.endOfConversationMessages = true;
        }
      } else if (action.payload === "") {
        state.endOfConversationMessages = true;
      }
      return {
        ...state,
        reportedConversationView: [
          ...state.reportedConversationView,
          ...usersList
        ],
        reportedConversationViewDates: [
          ...state.reportedConversationViewDates,
          ...scoreList
        ]
        // seenFlag: action.payload.seen,
        // indexOfLastMsg: IndexOfMsg
      };
    }
    case VIEW_USER_FULL_PROFILE_SUCCESS: {
      return {
        ...state,
        userFullProfileData: action.payload
      };
    }
    case GET_RECENT_USERS_SUCCESS: {
      return {
        ...state,
        recentUsersData: [...state.recentUsersData, ...action.payload]
      };
    }
    case SUSPEND_USER_SUCCESS: {
      return {
        ...state,
        userSuspended: action.payload
      };
    }
    case UNSUSPEND_USER_SUCCESS: {
      return {
        ...state,
        userUnsuspended: action.payload
      };
    }
    case RESET_OFFSET_ADMIN_CHECK_LIST: {
      return {
        ...state,
        offsetAdminCheckListData: 0,
        adminCheckListData: [],
        totalAdminCheckListData: 0
      };
    }
    case RESET_CREATED_USERS: {
      return {
        ...state,
        createdUsersData: [],
        offsetReturnedData: 0,
        totalCountReturnedData: 0
      };
    }
    case RESET_USERS_WITHOUT_L2:{
      return {
        ...state,
        usersWithoutL2Data:[],
        usersWithoutL2Offset:0,
        totalCountUsersWithoutL2Data: 0,
      };
    }
    case RESET_RECENT_CHECK_LIST:{
      return {
        ...state,
        recentCheckListData:[],
        recentCheckListOffset:0,
        totalCountRecentCheckListData: 0,
      };
    }
    case RESET_CONVERSATION_PROFILE_LIST: {
      return {
        ...state,
        reportedConversationData: [],
        limitReportedConversationData: 0,
        offsetReportedConversationData: 0,
        totalCountReportedConversationData: 0
      };
    }
    case RESET_REPORTED_PROFILE_LIST: {
      return {
        ...state,
        reportedProfileData: [],
        limitReportedProfileData: 0,
        offsetReportedProfileData: 0,
        totalCountReportedProfileData: 0
      };
    }
    case ADD_ADMIN_CHECK_SUCCESS: {
      return {
        ...state,
        adminAddCheck: action.payload.flag,
        returnedAdminAddCheckData: action.payload.data
      };
    }
    case ADMIN_CHECK_LIST_SUCCESS: {
      if (Array.isArray(action.payload)) {
        if (action.payload.length !== 0) {
          state.offsetAdminCheckListData =
            state.offsetAdminCheckListData + state.limitAdminCheckListData;

          action.payload.map(user => {
            if (user.adminCheckId) {
              user.id = user.adminCheckId;
              delete user.adminCheckId;
            }
          });

          state.adminCheckListData = [
            ...state.adminCheckListData,
            ...action.payload
          ];
        }
      } else {
        state.totalAdminCheckListData = action.payload;
      }
      return {
        ...state
      };
    }
    case SET_COUNTRY_SELECTED_UNCOMPLETED:{

      if (state.currentListType=="L1") {
        state.countrySelectedList1=action.payload
      }else if(state.currentListType=="L2"){
        state.countrySelectedList2=action.payload
      }
      return {
        ...state
      };
    }
    case SET_CITY_SELECTED_UNCOMPLETED:{
      
      if (state.currentListType=="L1") {
        state.citySelectedList1=action.payload
      }else if(state.currentListType=="L2"){
        state.citySelectedList2=action.payload
      }
      return {
        ...state
      };

    }
    case SET_PHONE_SELECTED_UNCOMPLETED:{
      if (state.currentListType=="L1") {
        state.phoneSelectedList1=action.payload
      }else if(state.currentListType=="L2"){
        state.phoneSelectedList2=action.payload
      }
      return {
        ...state
      };
    }
    case SET_GENDER_SELECTED_UNCOMPLETED:{
      if (state.currentListType=="L1") {
        state.genderSelectedList1=action.payload
      }else if(state.currentListType=="L2"){
        state.genderSelectedList2=action.payload
      }
      return {
        ...state
      };
    }
    case SET_PHONE_VERIFIED_UNCOMPLETED:{
      if (state.currentListType=="L1") {
        state.phoneVerifiedList1=action.payload
      }else if(state.currentListType=="L2"){
        state.phoneVerifiedList2=action.payload
      }
      return {
        ...state
      };
    }
    case SET_MAIN_PHOTO:{
      return {
        ...state,
        mainPhotoChecked:action.payload
      };
    }
    case SET_CREATED_DATE_FROM_SELECTED_UNCOMPLETED:{
      if (state.currentListType=="L1") {
        state.createdDateFromSelectedList1=action.payload
      }else if(state.currentListType=="L2"){
        state.createdDateFromSelectedList2=action.payload
      }
      return {
        ...state
      };
    }
    case SET_CREATED_DATE_TO_SELECTED_UNCOMPLETED:{
      if (state.currentListType=="L1") {
        state.createdDateToSelectedList1=action.payload
      }else if(state.currentListType=="L2"){
        state.createdDateToSelectedList2=action.payload
      }
      return {
        ...state
      };
    }
    case SET_SUBSCRIPTION_DATE_FROM_SELECTED:{
      return {
        ...state,
        subscriptionDateFromSelected:action.payload
      };
    }
    case SET_SUBSCRIPTION_DATE_TO_SELECTED:{
      return {
        ...state,
        subscriptionDateToSelected:action.payload
      };
    }
    case SET_CURRENT_LIST_TYPE:{
      return {
        ...state,
        currentListType:action.payload
      };
    }
    case READ_USER_VOICE_MESSAGE_SUCCESS:{
      return{
        ...state,
        userVoiceMessageUrl:action.payload.signedRequest
      }
    }
    case UPDATE_AUDIO_BLOB_LIST:{
      let newList=[...state.audioBlobList];
      newList.push(action.payload)
      console.log("action.payload from update list blob ",action.payload,newList)
      return {
        ...state,
        audioBlobList:[...newList]
      };
    }
    case OPEN_ADD_SUBSCRIPTION_POPUP:{
      return {
        ...state,
        openSubscriptionPopup:action.payload
      };
    }
    case ADD_USER_SUBSCRIPTION_SUCCESS:{
      return {
        ...state,
        userSubscriptionAdded:action.payload
      };
    }
    case GET_USER_SUBSCRIPTION_LIST_SUCCESS:{
      return {
        ...state,
        userSubscriptionList:action.payload
      };
    }
    default:
      return state;
  }
};

export default home;
