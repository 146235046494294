import React,{useEffect,useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ReactAudioPlayer from 'react-audio-player';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  readUserVoiceMessage,
  updateAudioBlobList
} from "../../redux/actions/Home";

const SentMessageCell = ({ conversation, myPhoto,clickedReported,clickedConversationId, seen }) => {
  const dispatch = useDispatch();
  const userVoiceMessageUrl=useSelector(state => state.home.userVoiceMessageUrl)
  const audioBlobList=useSelector(state => state.home.audioBlobList);
  const [newBlob,setNewBlob]=useState(null);
  useEffect(()=>{
    if (userVoiceMessageUrl &&userVoiceMessageUrl.includes(conversation.m)) {
      // console.log("userVoiceMessageUrl from component ",userVoiceMessageUrl)
      handleGetBlob()
      }

  },[userVoiceMessageUrl])
  useEffect(() => {
    if (audioBlobList.length!=0) {
    let blobObject = audioBlobList.find((a, i)=>{
      if(a.audioName == conversation.m)
        return true;
      }
    )
    setNewBlob(blobObject)
    }
  }, [audioBlobList]);

  const handleGetBlob = async() => {
    let blob = await fetch(userVoiceMessageUrl).then(r => r.blob());
    let blobUrl = URL.createObjectURL(blob)
    dispatch(updateAudioBlobList({audioName:conversation.m,audioUrl:blobUrl}))
};

  return (
    <>
    {conversation.m.includes(".amr")&&
             <div className="send-date">
             <Typography variant="overline" gutterBottom>
              {moment(Number(conversation.d)).format("MM/DD/YYYY hh:mm A")}
              </Typography>
              </div>  
       }
    <div className="d-flex flex-nowrap chat-item flex-row-reverse">
      <img
        className="rounded-circle avatar size-40 align-self-end"
        src={myPhoto ? myPhoto : "https://via.placeholder.com/150x150"}
        alt=""
      />

      {conversation.m.includes(".amr")?
                    <Grid container className="flex-row-reverse">
                    <Grid item xs={4} className="audio-container">
                      <ReactAudioPlayer
                      className="audioPlayer1"
                      // autoPlay
                      controls
                      // src={audioMessageUrl!=null&&audioMessageUrl.includes(conversation.m)?audioMessageUrl:conversation.m}
                       src={newBlob!=undefined?newBlob.audioUrl:conversation.m}
                     
                      onPlay={()=>{}}
                      onCanPlay={()=>{}}
                    />
                   
                    </Grid>
                    {(!userVoiceMessageUrl || !userVoiceMessageUrl.includes(conversation.m))&&newBlob==undefined&&
                    <Grid item xs={2}>
                    <ArrowDownwardIcon className="load-icon" onClick={()=>{
                      // console.log(" conversation ",conversation,clickedReported,clickedConversationId)
                          dispatch(readUserVoiceMessage(
                            clickedConversationId,
                            clickedReported.reporterDetails.gender,
                            clickedReported.reporterDetails.userCountry,
                            clickedReported.reporterDetails.userCity,
                            clickedReported.reporterDetails.userVa,
                            clickedReported.reporterId,
                            clickedReported.reporterDetails.jnt,
                            clickedReported.reportedDetails.gender,
                            clickedReported.reportedDetails.userCountry,
                            clickedReported.reportedDetails.userCity,
                            clickedReported.reportedDetails.userVa,
                            clickedReported.reportedId,
                            clickedReported.reportedDetails.jnt,
                            conversation.m,
                            1
                          ))
                    }}/> 
                    </Grid>
                  }
                    </Grid>
                    :

      <div className="bubble jambo-card">
        <div className="message" style={{ wordWrap: "break-word" }}>
          {conversation.m}
        </div>
        <div className="time text-muted text-right mt-2">
          <DoneAllIcon
            fontSize="small"
            className="seenMgs"
            style={
              conversation.seen == false
                ? { color: "#a1a1a1" }
                : { color: "white" }
            }
          />
          {moment(Number(conversation.d)).format("hh:mm A")}
        </div>
      </div>
      }
    </div>
      
     </>
  );
};

export default SentMessageCell;
