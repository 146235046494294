import axios from "axios";
import { setCookie, removeCookie, getCookie } from "../../util/session";
let axiosRequest = axios.create({
  baseURL: "https://adminback1.hootammo.com/backend/api/",
  responseType: "json"
});

let callAxios = options => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosRequest(options);
      if (!response.data) {
        resolve({ data: { message: response.message } });
      } else {
        if (response.data.token && response.data.status != "ACTIVE") {
          setCookie("access_token", response.data.token);
          // if (response.data.verify) {
          //   resolve({ data: { response: "ok" } });
          // } else if (response.data.signedRequest) {
          //   resolve({ data: { signedRequest: response.data.signedRequest } });
          // } else {
          options.headers.Authorization = "Bearer " + response.data.token;
          response = await axiosRequest(options);
          resolve(response);
          // }
        } else if (response.data.message) {
          if (response.data.message == "unauthorized") {
            console.log("**********Authorization*********");
            removeCookie("access_token");
            resolve({
              data: { code: "unauthorized", message: "unauthorized" }
            });
          } else {
            resolve(response);
          }
        } else {
          resolve(response);
        }
        resolve(response);
      }
    } catch (error) {
      resolve({ data: { message: error.response.data.message } });
    }
  }).catch(err => {
    console.log(err);
  });
};

export { callAxios };
