import {
  ADMIN_LOGIN_SUCCESS,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  ADMIN_LOGOUT
} from "../ActionTypes";
const initialAuthState = {
  authUser: null,
  isLogin: false,
  userPassword: null,
  passwordChanged: false,

  passwordSent: false
};

const auth = (state = initialAuthState, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_SUCCESS: {
      ///Countries
      let newCountryList = [];
      if (action.payload.returnedData.authUser.countryList !== "") {
        for (
          let i = 0;
          i < action.payload.returnedData.authUser.countryList.length;
          i += 3
        ) {
          let x = i + 2;
          newCountryList.push(
            action.payload.returnedData.authUser.countryList.slice(i, x)
          );
        }
      }

      ///gender
      let newGenderList = [];
      if (action.payload.returnedData.authUser.genderList !== "") {
        for (
          let j = 0;
          j < action.payload.returnedData.authUser.genderList.length;
          j += 3
        ) {
          let y = j + 2;
          newGenderList.push(
            action.payload.returnedData.authUser.genderList.slice(j, y)
          );
        }
      }

      action.payload.returnedData.authUser.countryList = [...newCountryList];
      action.payload.returnedData.authUser.genderList = [...newGenderList];
      return {
        ...state,
        authUser: action.payload.returnedData.authUser,
        isLogin: true
      };
    }
    case ADMIN_LOGOUT: {
      return {
        ...state,
        authUser: null,
        isLogin: false
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordChanged: action.payload
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordSent: action.payload
      };
    }
    default:
      return state;
  }
};

export default auth;
