import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import { getCookie } from "../util/session";
import base64url from "base64url";
import MuiPhoneNumber from "material-ui-phone-number";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import Button from "@material-ui/core/Button";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import {
  hideMessage,
  setCountrySelectedUncompleted,
  setCitySelectedUncompleted,
  setGenderSelectedUncompleted,
  setPhoneVerifiedSelectedUncompleted,
  setMPCheck,
  setPhoneSelectedUncompleted,
  setCreatedDateFromSelectedUncompleted,
  setCreatedDateToSelectedUncompleted,
  setSubscriptionDateFromSelected,
  setSubscriptionDateToSelected,
  getUsersWithoutL2,
  getRecentCheckList,
  resetUsersWithoutL2,
  resetRecentCheckList
} from "../redux/actions/Home";
import {
  COUNTRY_MAP,
  COUNTRY_MAP_VALUE,
  COUNTRY_CITY_MAP,
  COUNTRY_CITY_MAP_VALUE,
  ARRAYS_OF_GENDER,
  ARRAYS_OF_REASONS,
  ARRAYS_OF_REASONS_CONV,
  ARRAYS_OF_MARTIAL_STATUS
} from "../util/data";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: "2rem auto",
      padding: "1rem"
    }
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  field: {
    margin: "10px 0"
  },
  countryList: {
    ...theme.typography.body1
  },
  phone: {
    marginTop: "1rem"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  formG: {
    margin: "2rem 1rem"
  },
  textInput: {
    margin: "0 1rem",
    "@media screen and (max-width: 575px) ": {
      margin: "1rem 0"
    }
  },
  checkbox: {
    marginTop: "1rem"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

const Filtration3 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [createdDateTo, setCreatedDateTo] = useState("");
  const [createdDateFrom, setCreatedDateFrom] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneVerified, setPhoneVerified] = useState("");
  const [tokenUserData, setTokenUserData] = useState(null);

  const currentListType=useSelector(state => state.home.currentListType);

  const countrySelectedList1 = useSelector(state => state.home.countrySelectedList1);
  const citySelectedList1 = useSelector(state => state.home.citySelectedList1);
  const createdDateFromSelectedList1 = useSelector(state => state.home.createdDateFromSelectedList1);
  const createdDateToSelectedList1 = useSelector(state => state.home.createdDateToSelectedList1);

  const genderSelectedList1 = useSelector(state => state.home.genderSelectedList1);
  const phoneVerifiedList1 = useSelector(state => state.home.phoneVerifiedList1);
  const phoneSelectedList1 = useSelector(state => state.home.phoneSelectedList1);

  const usersWithoutL2Limit=useSelector(state => state.home.usersWithoutL2Limit);

   /// Recent check list

  const countrySelectedList2 = useSelector(state => state.home.countrySelectedList2);
  const citySelectedList2 = useSelector(state => state.home.citySelectedList2);
  const createdDateFromSelectedList2 = useSelector(state => state.home.createdDateFromSelectedList2);
  const createdDateToSelectedList2 = useSelector(state => state.home.createdDateToSelectedList2);
  const genderSelectedList2 = useSelector(state => state.home.genderSelectedList2);
  const phoneVerifiedList2 = useSelector(state => state.home.phoneVerifiedList2);
  const phoneSelectedList2 = useSelector(state => state.home.phoneSelectedList2);
  const subscriptionDateFromSelected = useSelector(state => state.home.subscriptionDateFromSelected);
  const subscriptionDateToSelected = useSelector(state => state.home.subscriptionDateToSelected);
  const mainPhotoChecked=useSelector(state => state.home.mainPhotoChecked);
   
  const recentCheckListLimit=useSelector(state => state.home.recentCheckListLimit);

  const authUser = useSelector(state => state.auth.authUser);

  const showMessage = useSelector(state => state.home.showMessage);
  const alertMessage = useSelector(state => state.home.alertMessage);

  useEffect(() => {
    const tokenValue = getCookie("access_token", false);
    const parsedTokenData = JSON.parse(
      base64url.decode(`${tokenValue}`.split(".")[1])
    );
    if (tokenValue != undefined) {
      setTokenUserData(parsedTokenData);
    }
  }, []);

  const handleChangeCountry = event => {
    setCountry(event.target.value);
    dispatch(setCountrySelectedUncompleted(event.target.value));
  };

  const handleChangeCity = event => {
    setCity(event.target.value);
    dispatch(setCitySelectedUncompleted(event.target.value));
  };


  return (
    <>
      <div className={classes.root}>
        <Paper elevation={3}>
          {/* Country */}
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              {t("filter.country")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={currentListType=="L1"?countrySelectedList1:countrySelectedList2}
              onChange={handleChangeCountry}
            >
              <MenuItem value="">
                <em>{t("menu.none")}</em>
              </MenuItem>
              {((authUser.countryList.length == 0 &&
                tokenUserData &&
                tokenUserData.id == 1) ||
                              (authUser.adminRoles.includes(5)))
                ? COUNTRY_MAP_VALUE.map((value, i) => (
                    <MenuItem
                      key={COUNTRY_MAP_VALUE[i]}
                      value={COUNTRY_MAP_VALUE[i]}
                      //   control={<Radio />}
                      //   label={value}>
                    >
                      {t(COUNTRY_MAP[COUNTRY_MAP_VALUE[i]])}
                    </MenuItem>
                  ))
                : authUser.adminRoles.includes(6) &&
                  authUser.countryList.map((value, i) => (
                    <MenuItem
                      key={authUser.countryList[i]}
                      value={authUser.countryList[i]}
                      //   control={<Radio />}
                      //   label={value}>
                    >
                      {t(COUNTRY_MAP[authUser.countryList[i]])}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          {/* City */}
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              {t("filter.city")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={currentListType=="L1"?citySelectedList1:citySelectedList2}
              onChange={handleChangeCity}
            >
              <MenuItem value="">
                <em>{t("menu.none")}</em>
              </MenuItem>

              {country != "" &&
                COUNTRY_CITY_MAP[country].map((value, i) => (
                  <MenuItem
                    key={COUNTRY_CITY_MAP_VALUE[country][i]}
                    value={COUNTRY_CITY_MAP_VALUE[country][i]}
                    // control={<Radio />}
                    // label={value}
                  >
                    {t(value)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {/* Gender */}

          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              {t("filter.gender")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={currentListType=="L1"?genderSelectedList1:genderSelectedList2}
              onChange={event => {
                setGender(event.target.value);
                dispatch(setGenderSelectedUncompleted(event.target.value));
              }}
            >
              <MenuItem value="">
                <em>{t("menu.none")}</em>
              </MenuItem>
              {(authUser.genderList.length == 0 &&
                tokenUserData &&
                tokenUserData.id == 1) ||
              (authUser.genderList.length == 0 &&
                authUser.adminRoles.includes(3))
                ? ARRAYS_OF_GENDER.map((value, i) => (
                    <MenuItem
                      key={ARRAYS_OF_GENDER[i]}
                      value={i}
                      //   control={<Radio />}
                      //   label={value}>
                    >
                      {t(ARRAYS_OF_GENDER[i])}
                    </MenuItem>
                  ))
                : authUser.adminRoles.includes(4) &&
                  authUser.genderList.map((value, i) => (
                    <MenuItem
                      key={authUser.genderList[i]}
                      value={i}
                      //   control={<Radio />}
                      //   label={value}>
                    >
                      {t(ARRAYS_OF_GENDER[authUser.genderList[i]])}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>


          {/* Phone Verified */}

           <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={currentListType=="L1"?phoneVerifiedList1:phoneVerifiedList2}
                  onChange={event => {
                    setPhoneVerified(event.target.checked);
                    dispatch(setPhoneVerifiedSelectedUncompleted(event.target.checked));
                  }}
                />
              }
              label={t("filter.phoneVerified")}
            />


          {/* Phone */}
          {tokenUserData && tokenUserData.id == 1 && (
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="demo-simple-select-helper-label">
              Phone Number
            </InputLabel> */}
              <MuiPhoneNumber
                defaultCountry={"eg"}
                // disableCountryCode={true}
                onChange={(event,countrydata) => {
                  let onlyPhoneNum=event
                  onlyPhoneNum=onlyPhoneNum.slice(countrydata.dialCode.length+1, onlyPhoneNum.length)
                  setPhone(onlyPhoneNum);
                 dispatch(setPhoneSelectedUncompleted(onlyPhoneNum));
                }}
                className={classes.phone}
              />
            </FormControl>
          )}


          {/* Create Date */}
          <>
            <TextField
              id="datetime-local"
              label={t("filter.createDateFrom")}
              type="datetime-local"
              // defaultValue={Date.now()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={currentListType=="L1"?createdDateFromSelectedList1:createdDateFromSelectedList2}
              onChange={event => {
                setCreatedDateFrom(
                  event.target.value
                );
               dispatch(setCreatedDateFromSelectedUncompleted(event.target.value));
              }}
            />

            <TextField
              id="datetime-local"
              label={t("filter.createDateTo")}
              type="datetime-local"
              // defaultValue="2017-05-24T10:30"
              className={classes.textField}
              value={currentListType=="L1"?createdDateToSelectedList1:createdDateToSelectedList2}
              onChange={event => {
                setCreatedDateTo(
                  event.target.value
                );
                 dispatch(setCreatedDateToSelectedUncompleted(event.target.value));
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </>

              {/*Main Photo */}
      {currentListType=="L2"&&
       <>
           <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={mainPhotoChecked}
                  onChange={event => {

                    dispatch(setMPCheck(event.target.checked));
                  }}
                />
              }
              label={t("filter.MP")}
            />


          {/* subscription action Date */}
          
         
            <TextField
              id="datetime-local"
              label={t("filter.subscriptionDateFrom")}
              type="datetime-local"
              // defaultValue={Date.now()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={subscriptionDateFromSelected}
              onChange={event => {
               dispatch(setSubscriptionDateFromSelected(event.target.value));
              }}
            />

            <TextField
              id="datetime-local"
              label={t("filter.subscriptionDateTo")}
              type="datetime-local"
              // defaultValue="2017-05-24T10:30"
              className={classes.textField}
              value={subscriptionDateToSelected}
              onChange={event => {
                setCreatedDateTo(
                  event.target.value
                );
                 dispatch(setSubscriptionDateToSelected(event.target.value));
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </>
          }
       
         
          {/* Filter */}
          <div style={{ textAlign: "end" }}>
            <Button
              variant="contained"
              className="btn-1"
              onClick={() => {

                
                if(currentListType=="L1"){
                  let finalPhoneVerified = !phoneVerifiedList1 ? "" : 1;
                  // console.log("filter 1", countrySelectedList1,
                  // citySelectedList1,
                  // createdDateFromSelectedList1,
                  // createdDateToSelectedList1,
                  // genderSelectedList1,
                  // phoneVerifiedList1,
                  // phoneSelectedList1)

                  dispatch(resetUsersWithoutL2())
                    dispatch(getUsersWithoutL2(usersWithoutL2Limit,0,countrySelectedList1,citySelectedList1,genderSelectedList1,finalPhoneVerified,phoneSelectedList1,
                      createdDateFromSelectedList1!== ""
                        ? moment(createdDateFromSelectedList1).format("YYYY-MM-DD HH:mm:ss")
                        : ""
                      ,createdDateToSelectedList1!== ""
                      ? moment(createdDateToSelectedList1).format("YYYY-MM-DD HH:mm:ss")
                      : ""
                      ,"",1
                    ))
                    dispatch(getUsersWithoutL2(usersWithoutL2Limit,0,countrySelectedList1,citySelectedList1,genderSelectedList1,finalPhoneVerified,phoneSelectedList1,  createdDateFromSelectedList1!== ""
                    ? moment(createdDateFromSelectedList1).format("YYYY-MM-DD HH:mm:ss")
                    : ""
                  ,createdDateToSelectedList1!== ""
                  ? moment(createdDateToSelectedList1).format("YYYY-MM-DD HH:mm:ss")
                  : ""
                  ,"",""
                ))
               
                }else if(currentListType=="L2"){
                  let finalPhoneVerified = !phoneVerifiedList2 ? "" : 1;
                  // console.log("filter 2", countrySelectedList2,
                  // citySelectedList2,
                  // createdDateFromSelectedList2,
                  // createdDateToSelectedList2,
                  // genderSelectedList2,
                  // phoneVerifiedList2,
                  // phoneSelectedList2)

                  dispatch(resetRecentCheckList())
                   dispatch(getRecentCheckList(recentCheckListLimit,0,countrySelectedList2,citySelectedList2,genderSelectedList2,finalPhoneVerified,phoneSelectedList2,
                    createdDateFromSelectedList2!== ""
                      ? moment(createdDateFromSelectedList2).format("YYYY-MM-DD HH:mm:ss")
                      : ""
                    ,createdDateToSelectedList2!== ""
                    ? moment(createdDateToSelectedList2).format("YYYY-MM-DD HH:mm:ss")
                    : ""
                    ,"",1,
                    subscriptionDateFromSelected!== ""
                    ? moment(subscriptionDateFromSelected).format("YYYY-MM-DD HH:mm:ss")
                    : "",
                    subscriptionDateToSelected!== ""
                    ? moment(subscriptionDateToSelected).format("YYYY-MM-DD HH:mm:ss")
                    : ""
                    ,mainPhotoChecked
                  ))
                    dispatch(getRecentCheckList(recentCheckListLimit,0,countrySelectedList2,citySelectedList2,genderSelectedList2,finalPhoneVerified,phoneSelectedList2,
                      createdDateFromSelectedList2!== ""
                        ? moment(createdDateFromSelectedList2).format("YYYY-MM-DD HH:mm:ss")
                        : ""
                      ,createdDateToSelectedList2!== ""
                      ? moment(createdDateToSelectedList2).format("YYYY-MM-DD HH:mm:ss")
                      : ""
                      ,"","",
                      subscriptionDateFromSelected!== ""
                      ? moment(subscriptionDateFromSelected).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                      subscriptionDateToSelected!== ""
                      ? moment(subscriptionDateToSelected).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                      mainPhotoChecked
                    ))
                }

              }}
            >
              {t("filter.apply")}
            </Button>
          </div>
        </Paper>
      </div>
      <NotificationContainer />
    </>
  );
};

export default Filtration3;
