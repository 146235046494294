import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import { getCookie } from "../util/session";
import base64url from "base64url";
import MuiPhoneNumber from "material-ui-phone-number";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import Button from "@material-ui/core/Button";

import { useLocation } from "react-router-dom";

import {
  getReportedConversationList,
  getReportedProfileList,
  resetReportedProfileList,
  resetReportedConversationList
} from "../redux/actions/Home";
import { ARRAYS_OF_REASONS, ARRAYS_OF_REASONS_CONV } from "../util/data";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: "2rem auto",
      padding: "1rem"
    }
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  field: {
    margin: "10px 0"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  formG: {
    margin: "2rem 1rem"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

const Filtration2 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [reporterId, setReporterId] = useState("");
  const [reportedId, setReportedId] = useState("");
  const [reasonId, setReasonId] = useState([]);

  const theme = useTheme();

  const limitReportedProfileData = useSelector(
    state => state.home.limitReportedProfileData
  );

  const limitReportedConversationData = useSelector(
    state => state.home.limitReportedConversationData
  );
  const offsetReportedProfileData = useSelector(
    state => state.home.offsetReportedProfileData
  );
  const offsetReportedConversationData = useSelector(
    state => state.home.offsetReportedConversationData
  );

  useEffect(() => {}, []);

  const handleChange = event => {
    setReasonId(event.target.value);
  };

  return (
    <>
      <div className={classes.root}>
        <Paper elevation={3}>
          <FormControl className={classes.formControl2}>
            <TextField
              id="standard-basic"
              label="Reporter Id"
              value={reporterId}
              onChange={event => {
                setReporterId(event.target.value);
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl2}>
            <TextField
              id="standard-basic"
              label="Reported Id"
              value={reportedId}
              onChange={event => {
                setReportedId(event.target.value);
              }}
            />
          </FormControl>

          {location.pathname === "/ReportedProfileList" && (
            <FormControl className={classes.formControl2}>
              <InputLabel id="demo-mutiple-chip-label">
                {/* Reported Profile Reason */}
                {t("filter.profileReason")}
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={reasonId}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {ARRAYS_OF_REASONS.map((value, i) =>
                  !(i === ARRAYS_OF_REASONS.length - 1) ? (
                    i == 1 ? (
                      <MenuItem
                        key={ARRAYS_OF_REASONS[i]}
                        value={9}
                        style={getStyles(ARRAYS_OF_REASONS[i], reasonId, theme)}
                      >
                        {/* <FormControlLabel
                    //   control={
                    //     <Checkbox
                    //       checked={reasonProfile.i}
                    //       onChange={handleChangeReason}
                    //       name={i + 1}
                    //     />
                    //   }
                    label={value}
                  /> */}
                        {t(value)}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={ARRAYS_OF_REASONS[i]}
                        value={i + 1}
                        style={getStyles(ARRAYS_OF_REASONS[i], reasonId, theme)}
                      >
                        {/* <FormControlLabel
                      //   control={
                      //     <Checkbox
                      //       checked={reasonProfile.i}
                      //       onChange={handleChangeReason}
                      //       name={i + 1}
                      //     />
                      //   }
                      label={value}
                    /> */}
                        {t(value)}
                      </MenuItem>
                    )
                  ) : (
                    // <MenuItem
                    //   key={ARRAYS_OF_REASONS[i]}
                    //   value={i + 1}
                    //   style={getStyles(ARRAYS_OF_REASONS[i], reasonId, theme)}
                    // >
                    //   {t(value)}
                    // </MenuItem>
                    <MenuItem
                      key={ARRAYS_OF_REASONS[i]}
                      value={99}
                      style={getStyles(ARRAYS_OF_REASONS[i], reasonId, theme)}
                    >
                      {t(value)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
          {location.pathname === "/ReportedConvList" && (
            <FormControl className={classes.formControl2}>
              <InputLabel id="demo-mutiple-chip-label">
                {/* Reported Conversation Reason */}
                {t("filter.conversationReason")}
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={reasonId}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {ARRAYS_OF_REASONS_CONV.map((value, i) =>
                  !(i === ARRAYS_OF_REASONS_CONV.length - 1) ? (
                    <MenuItem
                      key={ARRAYS_OF_REASONS_CONV[i]}
                      value={i + 1}
                      style={getStyles(
                        ARRAYS_OF_REASONS_CONV[i],
                        reasonId,
                        theme
                      )}
                    >
                      {t(value)}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={ARRAYS_OF_REASONS_CONV[i]}
                      value={99}
                      style={getStyles(
                        ARRAYS_OF_REASONS_CONV[i],
                        reasonId,
                        theme
                      )}
                    >
                      {t(value)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
          <Button
            variant="contained"
            className="btn-1"
            onClick={() => {
              /////
              let finalReasonId = "";
              reasonId.forEach(e => {
                finalReasonId = `${finalReasonId}${e},`;
              });
              finalReasonId = finalReasonId.substring(
                0,
                finalReasonId.length - 1
              );
              ////

              if (location.pathname === "/ReportedProfileList") {
                dispatch(resetReportedProfileList());
                dispatch(
                  getReportedProfileList(
                    limitReportedProfileData,
                    0,
                    reporterId,
                    reportedId,
                    reasonId,
                    0
                  )
                );
                dispatch(
                  getReportedProfileList(
                    limitReportedProfileData,
                    0,
                    reporterId,
                    reportedId,
                    reasonId,
                    1
                  )
                );
              } else if (location.pathname === "/ReportedConvList") {
                dispatch(resetReportedConversationList());
                dispatch(
                  getReportedConversationList(
                    limitReportedConversationData,
                    0,
                    reporterId,
                    reportedId,
                    reasonId,
                    0
                  )
                );
                dispatch(
                  getReportedConversationList(
                    limitReportedConversationData,
                    0,
                    reporterId,
                    reportedId,
                    reasonId,
                    1
                  )
                );
              }
            }}
          >
            {/* Apply Filter */}
            {t("filter.apply")}
          </Button>
        </Paper>
      </div>
      <NotificationContainer />
    </>
  );
};

export default Filtration2;
