import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
///Modal
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
/////
import { styled } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from '@material-ui/core/Grid';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import {
    openPopupAddSubscription,
    addUserSubscription
} from "../../redux/actions/Home";

import { TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReceivedMessageCell from "../ReceivedMessageCell";
import SentMessageCell from "../SentMessageCell";

import { useTranslation } from "react-i18next";

import {CURRENCIES, PAYMENTPERIOD ,PAYMENTMETHOD,CURRENCIES_MAP,PAYMENTPERIOD_MAP,PAYMENTMETHOD_MAP}from "../..//util/data"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "11rem",
    maxWidth: "12rem"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    width: "55%",
    "@media screen and (max-width: 575px) ": {
      width: "75% !important"
    }
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  avatar: {
    margin: "auto"
  },
  icons: {
    width: "35%",
    margin: "auto"
  }
}));

export default function ModalAddSubscription({
    subscribedUser
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openSubscriptionPopup = useSelector(state => state.home.openSubscriptionPopup);
  const userSubscriptionList= useSelector(state => state.home.userSubscriptionList);
  ////// modal
  const [open, setOpen] = useState(false);

  const [subPeriodSelected, setSubPeriodSelected] = useState("");
  const [paymentMethod,setPaymentMethod]= useState("");
  const [amount,setAmount]= useState("");
  const [paymentCurrent,setPaymentCurrent]= useState("");

  const [confirmSub,setConfirmSub]= useState(false);
  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(openPopupAddSubscription(false));
  };

  const StyledFormControl = styled(FormControl)({
    formControl: {
      margin: 2,
      "& select": {
        paddingRight: "22px"
      }
    },
    selectEmpty: {
      marginTop: 0
    }
  });

  useEffect(() => {
  }, []);

  return (
    <>
      {/*  */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openSubscriptionPopup}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openSubscriptionPopup}>
          <div className={classes.paper}>
          {confirmSub?
                   <Grid container  >
                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                         {t(`subscribe.confirm`)}
                      </Typography>
                          </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setConfirmSub(true)
                          handleClose()
                        }}
                        color="primary"
                        className="linear-g-r"
                      >
                        {t("data.cancel")}
                      </Button>
                    </Grid>

                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(addUserSubscription(subscribedUser.userId,amount,subPeriodSelected,paymentMethod,paymentCurrent))
                      setConfirmSub(false)
                      handleClose()
                    }}
                    color="primary"
                    className="linear-g-r"
                  >
                    {t("data.confirm")}
                  </Button>
                </Grid>
                    </Grid>
          :
          <Grid container  >
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div" style={{marginTop:"1rem"}}>
                        {t("sub.addSub")}
                      </Typography>
                      </Grid>

           {/* subscription Period */}
                    <Grid item xs={6} className="cust-grid">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">
                        {t("user.subPeriod")}
                        </InputLabel>
                        <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={subPeriodSelected}
                        onChange={(event)=>{
                            setSubPeriodSelected(event.target.value)
                        }}
                        >
                        <MenuItem value="">
                            <em>{t("menu.none")}</em>
                        </MenuItem>
                            {PAYMENTPERIOD.map((value, i) => (
                                i%2==0? <MenuItem
                                key={value}
                                value={PAYMENTPERIOD[i+1]}
                                //   control={<Radio />}
                                //   label={value}>
                                >
                                {t(value)}
                                </MenuItem>
                                :""
                            ))}
                        </Select>
                    </FormControl>
                    </Grid>
                {/* payment methods */}
                        <Grid item xs={6} className="cust-grid">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-helper-label">
                            {t("user.paymentmethod")}
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={paymentMethod}
                            onChange={(event)=>{
                                setPaymentMethod(event.target.value)
                            }}
                            >
                            <MenuItem value="">
                                <em>{t("menu.none")}</em>
                            </MenuItem>
                                {PAYMENTMETHOD.map((value, i) => (
                                    i%2==0?
                                    <MenuItem
                                    key={value}
                                    value={PAYMENTMETHOD[i+1]}
                                    >
                                    {t(value)}
                                    </MenuItem>
                                    :""
                                    
                                  )
                                )}
                            </Select>
                        </FormControl>
                        </Grid>
                        {/*Payment amount */}
                        <Grid item xs={6} className="cust-grid" style={{padding:"0 0rem 0 0.5rem"}}>

                            <TextField
                                id="standard-basic"
                                label={t("user.paymentamount")}
                                value={amount}
                                onChange={event => {
                                  setAmount(event.target.value)
                                }}
                                type="number"
                            />
                    </Grid>

               {/*Payment Currency */}
                    <Grid item xs={6} className="cust-grid">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">
                        {t("user.paymentcurrency")}
                        </InputLabel>
                        <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={paymentCurrent}
                        onChange={(event)=>{
                            setPaymentCurrent(event.target.value)
                        }}
                        >
                        <MenuItem value="">
                            <em>{t("menu.none")}</em>
                        </MenuItem>
                            {CURRENCIES.map((value, i) => (
                                i%2==0?
                                <MenuItem
                                key={value}
                                value={CURRENCIES[i+1]}
                                //   control={<Radio />}
                                //   label={value}>
                                >
                                {t(value)}
                                </MenuItem>
                                :""
                            ))}
                        </Select>
                    </FormControl>
                    </Grid>

              {/*subscribe btn */}
              {subPeriodSelected!==""&&paymentMethod!==""&& amount!==""&&paymentCurrent!==""&&
              <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                 /// open modal to Confirm subscription
                setConfirmSub(true)

                }}
              >
                  {t("user.addSub")}
              </Button>
          </Grid>}

          </Grid>

          }

           {userSubscriptionList.length!=0&&
           <>
           <Typography variant="h6" gutterBottom component="div" style={{marginTop:"1rem"}}>
            {t("sub.subList")}
           </Typography>

          {userSubscriptionList.map(sub => (
            <Card sx={{ minWidth: "25%" }} className="cust-card">
            <CardContent>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {t("user.paymentmethod")} :<span className="icon-color">{t(PAYMENTMETHOD_MAP[sub.paymentType])}</span>
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {t("user.subPeriod")} :<span className="icon-color"> {t(PAYMENTPERIOD_MAP[sub.paymentPeriod])}</span>
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {t("user.paymentamount")} :<span className="icon-color"> {sub.amount} {t(CURRENCIES_MAP[sub.paymentCurrency])}</span>
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {t("sub.createdDate")} :<span className="icon-color"> {moment(sub.createdDate).format(
                "YYYY-MM-DD HH:mm A"
              )}</span>
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {t("sub.modifiedDate")} : <span className="icon-color">{moment(sub.modifiedDate).format(
                "YYYY-MM-DD HH:mm A"
              )}</span>
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {sub.endDate!=null&&
                <>
                  {t("sub.endDate")} :<span className="icon-color"> {moment(sub.endDate).format(
                    "YYYY-MM-DD HH:mm A"
                  )}</span>
                  </>}
                  {sub.endDate==null&&
                <>
                  {t("sub.endDate")} :<span className="icon-color">{t("sub.endDateNull")}</span>
                  </>}
              </Typography>
             
            </CardContent>
          </Card>
          ))
            }
        
            </>
            }
           

          
          </div>
        </Fade>
      </Modal>
      {/*  */}
    </>
  );
}
