// import IntlMessages from "../util/IntlMessages";

// export const ARRAYS_OF_REASONS = [
//   "reason.1" ,
//   "reason.2" ,
//   "reason.3" ,
//   "reason.4" ,
//   "reason.5" ,
//   "reason.6" ,
//   "reason.7" ,
//   "reason.8"
// ];

// export const ARRAYS_OF_REASONS_CONV = [
//   "reasonConv.1" ,
//   "reasonConv.2" ,
//   "reasonConv.3" ,
//   "reason.8"
// ];

// export const ARRAYS_OF_REASONS_IUSSE = [
//   "reasonIusse.1" ,
//   "reasonIusse.2" ,
//   "reason.8"
// ];

// export const ARRAYS_OF_GENDER = [
//   "gender.0" ,
//   "gender.1"
// ];

export const PAYMENTMETHOD=[
  "user.paymentmethod1",
  "FAWRY",
  "user.paymentmethod2",
  "VC",
  "user.paymentmethod3",
  "ACH",
  "user.paymentmethod4",
  "CASH",
  "user.paymentmethod5",
  "LINK",
  "user.paymentmethod6",
  "SWIFT",
]

export const PAYMENTPERIOD=[
  "user.subPeriodData0",
  "0",
  "user.subPeriodData1",
  "1",
  "user.subPeriodData2",
  "3",
  "user.subPeriodData3",
  "6",
  "user.subPeriodData4",
  "12"
]

export const CURRENCIES=[
  "currency.USD",
  "USD",
  "currency.EGP",
  "EGP",
  "currency.EUR",
  "EUR",
  "currency.GBP",
  "GBP"
]

export const PAYMENTMETHOD_MAP = new Map();
PAYMENTMETHOD_MAP["FAWRY"] = "user.paymentmethod1";
PAYMENTMETHOD_MAP["VC"] = "user.paymentmethod2";
PAYMENTMETHOD_MAP["ACH"] = "user.paymentmethod3";
PAYMENTMETHOD_MAP["CASH"] = "user.paymentmethod4";
PAYMENTMETHOD_MAP["LINK"] = "user.paymentmethod5";
PAYMENTMETHOD_MAP["SWIFT"] = "user.paymentmethod6";

export const PAYMENTPERIOD_MAP = new Map();
PAYMENTPERIOD_MAP["0"] = "user.subPeriodData0";
PAYMENTPERIOD_MAP["1"] = "user.subPeriodData1";
PAYMENTPERIOD_MAP["3"] = "user.subPeriodData2";
PAYMENTPERIOD_MAP["6"] = "user.subPeriodData3";
PAYMENTPERIOD_MAP["12"] = "user.subPeriodData4";

export const CURRENCIES_MAP = new Map();
CURRENCIES_MAP["USD"] = "currency.USD";
CURRENCIES_MAP["EGP"] = "currency.GBP";
CURRENCIES_MAP["EUR"] = "currency.EUR";
CURRENCIES_MAP["GBP"] = "currency.GBP";

export const ARRAYS_OF_MARTIAL_STATUS_VALUES = [
  [0, 1, 2],
  [0, 1, 2, 3]
];

export const ARRAYS_OF_MARTIAL_STATUS = [
  ["martialF.1", "martialF.2", "martialF.3"],
  ["martialM.1", "martialM.2", "martialM.3", "martialM.4"]
];

export const LIST_OF_ROLES = [
  "admin.allcreated",
  "admin.onlyreported",
  "admin.allgenders",
  "admin.specificgender",
  "admin.allcountries",
  "admin.listofcountries",
  "admin.suspend&unsuspend",
  "admin.none",
  "admin.onlysuspend",
  "admin.readIncomplete",
  "admin.tackActions"
];

export const COUNTRY_MAP = new Map();
COUNTRY_MAP["EG"] = "co.1";

export const COUNTRY_MAP_VALUE = [
  "AF",
  "AL",
  "DZ",
  "AZ",
  "BH",
  "BD",
  "BJ",
  "BA",
  "BF",
  "CM",
  "CA",
  "TD",
  "CN",
  "CO",
  "KM",
  "CI",
  "DJ",
  "EG",
  "GM",
  "DE",
  "GH",
  "GW",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IT",
  "JO",
  "KE",
  "KW",
  "KG",
  "LB",
  "LR",
  "LY",
  "LI",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MR",
  "ME",
  "MA",
  "MM",
  "NE",
  "NG",
  "OM",
  "PK",
  "PS",
  "PH",
  "QA",
  "MK",
  "RU",
  "ST",
  "SA",
  "SN",
  "SL",
  "SG",
  "SO",
  "SD",
  "SY",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TN",
  "TR",
  "TM",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UZ",
  "YE"
];
export const COUNTRY_CITY_MAP = new Map();
COUNTRY_CITY_MAP["EG"] = [
  "eg.1",
  "eg.2",
  "eg.3",
  "eg.4",
  "eg.5",
  "eg.6",
  "eg.7",
  "eg.8",
  "eg.9",
  "eg.10",
  "eg.11",
  "eg.12",
  "eg.13",
  "eg.14",
  "eg.15",
  "eg.16",
  "eg.17",
  "eg.18",
  "eg.19",
  "eg.20",
  "eg.21",
  "eg.22",
  "eg.23",
  "eg.24",
  "eg.25",
  "eg.26",
  "eg.27"
];

COUNTRY_MAP["DZ"] = "co.2";
COUNTRY_CITY_MAP["DZ"] = [
  "DZA.1",
  "DZA.2",
  "DZA.3",
  "DZA.4",
  "DZA.5",
  "DZA.6",
  "DZA.7",
  "DZA.8",
  "DZA.9",
  "DZA.10",
  "DZA.11",
  "DZA.12",
  "DZA.13",
  "DZA.14",
  "DZA.15",
  "DZA.16",
  "DZA.17",
  "DZA.18",
  "DZA.19",
  "DZA.20",
  "DZA.21",
  "DZA.22",
  "DZA.23",
  "DZA.24",
  "DZA.25",
  "DZA.26",
  "DZA.27",
  "DZA.28",
  "DZA.29",
  "DZA.30",
  "DZA.31",
  "DZA.32",
  "DZA.33",
  "DZA.34",
  "DZA.35",
  "DZA.36",
  "DZA.37",
  "DZA.38",
  "DZA.39",
  "DZA.40",
  "DZA.41",
  "DZA.42",
  "DZA.43",
  "DZA.44",
  "DZA.45",
  "DZA.46",
  "DZA.47",
  "DZA.48",
  "DZA.49",
  "DZA.50",
  "DZA.51",
  "DZA.52",
  "DZA.53",
  "DZA.54",
  "DZA.55",
  "DZA.56",
  "DZA.57",
  "DZA.58"
];

COUNTRY_MAP["MA"] = "co.3";
COUNTRY_CITY_MAP["MA"] = [
  "MA.1",
  "MA.2",
  "MA.3",
  "MA.4",
  "MA.5",
  "MA.6",
  "MA.7",
  "MA.8",
  "MA.9",
  "MA.10",
  "MA.11",
  "MA.12",
  "MA.13",
  "MA.14",
  "MA.15",
  "MA.16",
  "MA.17",
  "MA.18",
  "MA.19",
  "MA.20",
  "MA.21",
  "MA.22",
  "MA.23",
  "MA.24",
  "MA.25",
  "MA.26",
  "MA.27",
  "MA.28",
  "MA.29",
  "MA.30",
  "MA.31",
  "MA.32",
  "MA.33",
  "MA.34",
  "MA.35",
  "MA.36",
  "MA.37",
  "MA.38",
  "MA.39",
  "MA.40",
  "MA.41",
  "MA.42",
  "MA.43",
  "MA.44",
  "MA.45",
  "MA.46",
  "MA.47",
  "MA.48",
  "MA.49",
  "MA.50",
  "MA.51",
  "MA.52",
  "MA.53",
  "MA.54",
  "MA.55",
  "MA.56",
  "MA.57",
  "MA.58",
  "MA.59",
  "MA.60",
  "MA.61",
  "MA.62"
];

COUNTRY_MAP["SA"] = "co.4";
COUNTRY_CITY_MAP["SA"] = [
  "SAU.1",
  "SAU.2",
  "SAU.3",
  "SAU.4",
  "SAU.5",
  "SAU.6",
  "SAU.7",
  "SAU.8",
  "SAU.9",
  "SAU.10",
  "SAU.11",
  "SAU.12",
  "SAU.13"
];

COUNTRY_MAP["KW"] = "co.5";
COUNTRY_CITY_MAP["KW"] = ["KWT.1", "KWT.2", "KWT.3", "KWT.4", "KWT.5", "KWT.6"];

COUNTRY_MAP["AE"] = "co.6";
COUNTRY_CITY_MAP["AE"] = ["ARE.1", "ARE.2", "ARE.3", "ARE.4", "ARE.5", "ARE.6"];

COUNTRY_MAP["US"] = "co.7";
COUNTRY_CITY_MAP["US"] = [
  "US.1",
  "US.2",
  "US.3",
  "US.4",
  "US.5",
  "US.6",
  "US.7",
  "US.8",
  "US.9",
  "US.10",
  "US.11",
  "US.12",
  "US.13",
  "US.14",
  "US.15",
  "US.16",
  "US.17",
  "US.18",
  "US.19",
  "US.20",
  "US.21",
  "US.22",
  "US.23",
  "US.24",
  "US.25",
  "US.26",
  "US.27",
  "US.28",
  "US.29",
  "US.30",
  "US.31",
  "US.32",
  "US.33",
  "US.34",
  "US.35",
  "US.36",
  "US.37",
  "US.38",
  "US.39",
  "US.40",
  "US.41",
  "US.42",
  "US.43",
  "US.44",
  "US.45",
  "US.46",
  "US.47",
  "US.48",
  "US.49",
  "US.50",
  "US.51"
];

COUNTRY_MAP["CA"] = "co.8";
COUNTRY_CITY_MAP["CA"] = [
  "CA.1",
  "CA.2",
  "CA.3",
  "CA.4",
  "CA.5",
  "CA.6",
  "CA.7",
  "CA.8",
  "CA.9",
  "CA.10",
  "CA.11",
  "CA.12",
  "CA.13"
];

COUNTRY_MAP["SG"] = "co.9";
COUNTRY_CITY_MAP["SG"] = [
  "SG.1",
  "SG.2",
  "SG.3",
  "SG.4",
  "SG.5",
  "SG.6",
  "SG.7",
  "SG.8",
  "SG.9",
  "SG.10",
  "SG.11",
  "SG.12",
  "SG.13",
  "SG.14",
  "SG.15",
  "SG.16",
  "SG.17",
  "SG.18",
  "SG.19",
  "SG.20",
  "SG.21",
  "SG.22",
  "SG.23",
  "SG.24",
  "SG.25",
  "SG.26",
  "SG.27",
  "SG.28",
  "SG.29",
  "SG.30",
  "SG.31",
  "SG.32",
  "SG.33",
  "SG.34",
  "SG.35",
  "SG.36",
  "SG.37",
  "SG.38",
  "SG.39",
  "SG.40",
  "SG.41",
  "SG.42",
  "SG.43",
  "SG.44",
  "SG.45",
  "SG.46",
  "SG.47",
  "SG.48",
  "SG.49",
  "SG.50",
  "SG.51",
  "SG.52",
  "SG.53",
  "SG.54",
  "SG.55",
  "SG.56",
  "SG.57",
  "SG.58",
  "SG.59",
  "SG.60",
  "SG.61",
  "SG.62",
  "SG.63",
  "SG.64",
  "SG.65",
  "SG.66",
  "SG.67",
  "SG.68",
  "SG.69",
  "SG.70",
  "SG.71",
  "SG.72",
  "SG.73",
  "SG.74",
  "SG.75",
  "SG.76"
];

COUNTRY_MAP["UA"] = "co.10";
COUNTRY_CITY_MAP["UA"] = [
  "UA.1",
  "UA.2",
  "UA.3",
  "UA.4",
  "UA.5",
  "UA.6",
  "UA.7",
  "UA.8",
  "UA.9",
  "UA.10",
  "UA.11",
  "UA.12",
  "UA.13",
  "UA.14",
  "UA.15",
  "UA.16",
  "UA.17",
  "UA.18",
  "UA.19",
  "UA.20",
  "UA.21",
  "UA.22",
  "UA.23",
  "UA.24"
];

COUNTRY_MAP["PT"] = "co.11";
COUNTRY_CITY_MAP["PT"] = [
  "PT.1",
  "PT.2",
  "PT.3",
  "PT.4",
  "PT.5",
  "PT.6",
  "PT.7",
  "PT.8",
  "PT.9",
  "PT.10",
  "PT.11",
  "PT.12",
  "PT.13",
  "PT.14",
  "PT.15",
  "PT.16",
  "PT.17",
  "PT.18",
  "PT.19",
  "PT.20",
  "PT.21",
  "PT.22",
  "PT.23",
  "PT.24",
  "PT.25",
  "PT.26",
  "PT.27",
  "PT.28",
  "PT.29",
  "PT.30",
  "PT.31",
  "PT.32",
  "PT.33",
  "PT.34",
  "PT.35",
  "PT.36",
  "PT.37",
  "PT.38",
  "PT.39",
  "PT.40",
  "PT.41",
  "PT.42",
  "PT.43",
  "PT.44",
  "PT.45",
  "PT.46",
  "PT.47",
  "PT.48",
  "PT.49",
  "PT.50",
  "PT.51",
  "PT.52",
  "PT.53",
  "PT.54",
  "PT.55"
];

COUNTRY_MAP["AF"] = "co.12";
COUNTRY_CITY_MAP["AF"] = [
  "AF.1",
  "AF.2",
  "AF.3",
  "AF.4",
  "AF.5",
  "AF.6",
  "AF.7",
  "AF.8",
  "AF.9",
  "AF.10",
  "AF.11",
  "AF.12",
  "AF.13",
  "AF.14",
  "AF.15",
  "AF.16",
  "AF.17",
  "AF.18"
];

COUNTRY_MAP["AL"] = "co.13";
COUNTRY_CITY_MAP["AL"] = [
  "AL.1",
  "AL.2",
  "AL.3",
  "AL.4",
  "AL.5",
  "AL.6",
  "AL.7",
  "AL.8",
  "AL.9",
  "AL.10",
  "AL.11",
  "AL.12",
  "AL.13",
  "AL.14",
  "AL.15",
  "AL.16",
  "AL.17",
  "AL.18",
  "AL.19",
  "AL.20",
  "AL.21",
  "AL.22",
  "AL.23",
  "AL.24",
  "AL.25",
  "AL.26",
  "AL.27",
  "AL.28",
  "AL.29",
  "AL.30",
  "AL.31",
  "AL.32",
  "AL.33",
  "AL.34",
  "AL.35",
  "AL.36",
  "AL.37",
  "AL.38",
  "AL.39",
  "AL.40",
  "AL.41",
  "AL.42",
  "AL.43",
  "AL.44",
  "AL.45",
  "AL.46",
  "AL.47",
  "AL.48",
  "AL.49",
  "AL.50",
  "AL.51",
  "AL.52",
  "AL.53",
  "AL.54",
  "AL.55",
  "AL.56",
  "AL.57",
  "AL.58",
  "AL.59",
  "AL.60",
  "AL.61",
  "AL.62",
  "AL.63",
  "AL.64",
  "AL.65",
  "AL.66",
  "AL.67",
  "AL.68",
  "AL.69",
  "AL.70",
  "AL.71",
  "AL.72",
  "AL.73",
  "AL.74",
  "AL.75"
];

COUNTRY_MAP["AZ"] = "co.14";
COUNTRY_CITY_MAP["AZ"] = [
  "AZ.1",
  "AZ.2",
  "AZ.3",
  "AZ.4",
  "AZ.5",
  "AZ.6",
  "AZ.7",
  "AZ.8",
  "AZ.9",
  "AZ.10",
  "AZ.11",
  "AZ.12",
  "AZ.13",
  "AZ.14",
  "AZ.15",
  "AZ.16",
  "AZ.17",
  "AZ.18",
  "AZ.19",
  "AZ.20",
  "AZ.21",
  "AZ.22",
  "AZ.23",
  "AZ.24",
  "AZ.25",
  "AZ.26",
  "AZ.27",
  "AZ.28",
  "AZ.29",
  "AZ.30",
  "AZ.31",
  "AZ.32",
  "AZ.33",
  "AZ.34",
  "AZ.35",
  "AZ.36",
  "AZ.37",
  "AZ.38",
  "AZ.39",
  "AZ.40",
  "AZ.41",
  "AZ.42",
  "AZ.43",
  "AZ.44",
  "AZ.45",
  "AZ.46",
  "AZ.47",
  "AZ.48",
  "AZ.49",
  "AZ.50",
  "AZ.51",
  "AZ.52",
  "AZ.53",
  "AZ.54",
  "AZ.55",
  "AZ.56",
  "AZ.57",
  "AZ.58",
  "AZ.59",
  "AZ.60",
  "AZ.61",
  "AZ.62",
  "AZ.63",
  "AZ.64",
  "AZ.65"
];

COUNTRY_MAP["BA"] = "co.15";
COUNTRY_CITY_MAP["BA"] = [
  "BA.1",
  "BA.2",
  "BA.3",
  "BA.4",
  "BA.5",
  "BA.6",
  "BA.7",
  "BA.8",
  "BA.9",
  "BA.10",
  "BA.11",
  "BA.12",
  "BA.13",
  "BA.14",
  "BA.15",
  "BA.16",
  "BA.17",
  "BA.18",
  "BA.19",
  "BA.20",
  "BA.21",
  "BA.22",
  "BA.23",
  "BA.24",
  "BA.25"
];

COUNTRY_MAP["BD"] = "co.16";
COUNTRY_CITY_MAP["BD"] = [
  "BD.1",
  "BD.2",
  "BD.3",
  "BD.4",
  "BD.5",
  "BD.6",
  "BD.7",
  "BD.8",
  "BD.9",
  "BD.10",
  "BD.11"
];

COUNTRY_MAP["BF"] = "co.17";
COUNTRY_CITY_MAP["BF"] = [
  "BF.1",
  "BF.2",
  "BF.3",
  "BF.4",
  "BF.5",
  "BF.6",
  "BF.7",
  "BF.8",
  "BF.9",
  "BF.10",
  "BF.11",
  "BF.12",
  "BF.13",
  "BF.14",
  "BF.15",
  "BF.16",
  "BF.17",
  "BF.18",
  "BF.19",
  "BF.20",
  "BF.21",
  "BF.22",
  "BF.23",
  "BF.24",
  "BF.25",
  "BF.26",
  "BF.27",
  "BF.28",
  "BF.29",
  "BF.30",
  "BF.31",
  "BF.32",
  "BF.33",
  "BF.34",
  "BF.35",
  "BF.36",
  "BF.37",
  "BF.38",
  "BF.39",
  "BF.40",
  "BF.41",
  "BF.42",
  "BF.43",
  "BF.44",
  "BF.45",
  "BF.46",
  "BF.47",
  "BF.48",
  "BF.49",
  "BF.50",
  "BF.51",
  "BF.52",
  "BF.53",
  "BF.54",
  "BF.55",
  "BF.56",
  "BF.57",
  "BF.58",
  "BF.59",
  "BF.60",
  "BF.61",
  "BF.62",
  "BF.63",
  "BF.64",
  "BF.65"
];

COUNTRY_MAP["BG"] = "co.18";
COUNTRY_CITY_MAP["BG"] = [
  "BG.1",
  "BG.2",
  "BG.3",
  "BG.4",
  "BG.5",
  "BG.6",
  "BG.7",
  "BG.8",
  "BG.9",
  "BG.10",
  "BG.11",
  "BG.12",
  "BG.13",
  "BG.14",
  "BG.15",
  "BG.16",
  "BG.17",
  "BG.18",
  "BG.19",
  "BG.20",
  "BG.21",
  "BG.22",
  "BG.23",
  "BG.24",
  "BG.25",
  "BG.26",
  "BG.27",
  "BG.28"
];

COUNTRY_MAP["BH"] = "co.19";
COUNTRY_CITY_MAP["BH"] = [
  "BH.1",
  "BH.2",
  "BH.3",
  "BH.4",
  "BH.5",
  "BH.6",
  "BH.7",
  "BH.8",
  "BH.9",
  "BH.10"
];

COUNTRY_MAP["BJ"] = "co.20";
COUNTRY_CITY_MAP["BJ"] = [
  "BJ.1",
  "BJ.2",
  "BJ.3",
  "BJ.4",
  "BJ.5",
  "BJ.6",
  "BJ.7",
  "BJ.8",
  "BJ.9",
  "BJ.10",
  "BJ.11",
  "BJ.12",
  "BJ.13",
  "BJ.14",
  "BJ.15",
  "BJ.16",
  "BJ.17",
  "BJ.18",
  "BJ.19",
  "BJ.20",
  "BJ.21",
  "BJ.22",
  "BJ.23",
  "BJ.24",
  "BJ.25",
  "BJ.26",
  "BJ.27",
  "BJ.28",
  "BJ.29",
  "BJ.30",
  "BJ.31",
  "BJ.32",
  "BJ.33",
  "BJ.34",
  "BJ.35",
  "BJ.36",
  "BJ.37",
  "BJ.38",
  "BJ.39"
];

COUNTRY_MAP["CI"] = "co.21";
COUNTRY_CITY_MAP["CI"] = [
  "CI.1",
  "CI.2",
  "CI.3",
  "CI.4",
  "CI.5",
  "CI.6",
  "CI.7",
  "CI.8",
  "CI.9",
  "CI.10",
  "CI.11",
  "CI.12",
  "CI.13",
  "CI.14",
  "CI.15",
  "CI.16",
  "CI.17",
  "CI.18",
  "CI.19",
  "CI.20",
  "CI.21",
  "CI.22",
  "CI.23",
  "CI.24",
  "CI.25",
  "CI.26",
  "CI.27",
  "CI.28",
  "CI.29",
  "CI.30",
  "CI.31",
  "CI.32",
  "CI.33",
  "CI.34",
  "CI.35",
  "CI.36",
  "CI.37",
  "CI.38",
  "CI.39",
  "CI.40"
];

COUNTRY_MAP["CM"] = "co.22";
COUNTRY_CITY_MAP["CM"] = [
  "CM.1",
  "CM.2",
  "CM.3",
  "CM.4",
  "CM.5",
  "CM.6",
  "CM.7",
  "CM.8",
  "CM.9",
  "CM.10"
];

COUNTRY_MAP["CN"] = "co.23";
COUNTRY_CITY_MAP["CN"] = [
  "CN.1",
  "CN.2",
  "CN.3",
  "CN.4",
  "CN.5",
  "CN.6",
  "CN.7",
  "CN.8",
  "CN.9",
  "CN.10",
  "CN.11",
  "CN.13",
  "CN.14",
  "CN.15",
  "CN.16",
  "CN.17",
  "CN.18",
  "CN.19",
  "CN.20",
  "CN.21",
  "CN.22",
  "CN.23",
  "CN.24",
  "CN.25",
  "CN.26",
  "CN.27",
  "CN.28",
  "CN.29",
  "CN.30",
  "CN.31",
  "CN.32",
  "CN.33"
];

COUNTRY_MAP["DE"] = "co.24";
COUNTRY_CITY_MAP["DE"] = [
  "DE.1",
  "DE.2",
  "DE.3",
  "DE.4",
  "DE.5",
  "DE.6",
  "DE.7",
  "DE.8",
  "DE.9",
  "DE.10",
  "DE.11",
  "DE.12",
  "DE.13",
  "DE.14",
  "DE.15",
  "DE.16",
  "DE.17"
];

COUNTRY_MAP["DJ"] = "co.25";
COUNTRY_CITY_MAP["DJ"] = [
  "DJ.1",
  "DJ.2",
  "DJ.3",
  "DJ.4",
  "DJ.5",
  "DJ.6",
  "DJ.7",
  "DJ.8",
  "DJ.9",
  "DJ.10",
  "DJ.11",
  "DJ.12",
  "DJ.13",
  "DJ.14",
  "DJ.15",
  "DJ.16",
  "DJ.17",
  "DJ.18",
  "DJ.19",
  "DJ.20"
];

COUNTRY_MAP["ES"] = "co.26";
COUNTRY_CITY_MAP["ES"] = [
  "ES.1",
  "ES.2",
  "ES.3",
  "ES.4",
  "ES.5",
  "ES.6",
  "ES.7",
  "ES.8",
  "ES.9",
  "ES.10",
  "ES.11",
  "ES.12",
  "ES.13",
  "ES.14",
  "ES.15",
  "ES.16",
  "ES.17",
  "ES.18",
  "ES.19",
  "ES.20",
  "ES.21",
  "ES.22",
  "ES.23",
  "ES.24",
  "ES.25",
  "ES.26",
  "ES.27",
  "ES.28",
  "ES.29",
  "ES.30",
  "ES.31",
  "ES.32",
  "ES.33",
  "ES.34",
  "ES.35",
  "ES.36",
  "ES.37",
  "ES.38",
  "ES.39",
  "ES.40",
  "ES.41",
  "ES.42",
  "ES.43",
  "ES.44",
  "ES.45",
  "ES.46",
  "ES.47",
  "ES.48",
  "ES.49"
];

COUNTRY_MAP["FR"] = "co.27";
COUNTRY_CITY_MAP["FR"] = [
  "FR.1",
  "FR.2",
  "FR.3",
  "FR.4",
  "FR.5",
  "FR.6",
  "FR.7",
  "FR.8",
  "FR.9",
  "FR.10",
  "FR.11",
  "FR.12",
  "FR.13",
  "FR.14",
  "FR.15",
  "FR.16",
  "FR.17",
  "FR.18",
  "FR.19",
  "FR.20",
  "FR.21",
  "FR.22",
  "FR.23",
  "FR.24",
  "FR.25",
  "FR.26",
  "FR.27",
  "FR.28",
  "FR.29",
  "FR.30",
  "FR.31",
  "FR.32",
  "FR.33",
  "FR.34",
  "FR.35",
  "FR.36",
  "FR.37",
  "FR.38",
  "FR.39",
  "FR.40",
  "FR.41",
  "FR.42"
];

COUNTRY_MAP["GB"] = "co.28";
COUNTRY_CITY_MAP["GB"] = [
  "GB.1",
  "GB.2",
  "GB.3",
  "GB.4",
  "GB.5",
  "GB.6",
  "GB.7",
  "GB.8",
  "GB.9",
  "GB.10",
  "GB.11",
  "GB.12",
  "GB.13",
  "GB.14",
  "GB.15",
  "GB.16",
  "GB.17",
  "GB.18",
  "GB.19",
  "GB.20",
  "GB.21",
  "GB.22",
  "GB.23",
  "GB.24",
  "GB.25",
  "GB.26",
  "GB.27",
  "GB.28",
  "GB.29",
  "GB.30",
  "GB.31",
  "GB.32",
  "GB.33",
  "GB.34",
  "GB.35",
  "GB.36",
  "GB.37",
  "GB.38",
  "GB.39",
  "GB.40",
  "GB.41",
  "GB.42",
  "GB.43",
  "GB.44",
  "GB.45",
  "GB.46",
  "GB.47",
  "GB.48",
  "GB.49",
  "GB.50",
  "GB.51",
  "GB.52",
  "GB.53",
  "GB.54",
  "GB.55",
  "GB.56",
  "GB.57",
  "GB.58",
  "GB.59",
  "GB.60",
  "GB.61",
  "GB.62",
  "GB.63",
  "GB.64",
  "GB.65",
  "GB.66",
  "GB.67",
  "GB.68",
  "GB.69",
  "GB.70",
  "GB.71",
  "GB.72",
  "GB.73",
  "GB.74",
  "GB.75",
  "GB.76",
  "GB.77",
  "GB.78",
  "GB.79",
  "GB.80",
  "GB.81",
  "GB.82",
  "GB.83",
  "GB.84",
  "GB.85",
  "GB.86",
  "GB.87",
  "GB.88",
  "GB.89",
  "GB.90",
  "GB.91",
  "GB.92",
  "GB.93",
  "GB.94",
  "GB.95",
  "GB.96",
  "GB.97",
  "GB.98",
  "GB.99",
  "GB.100",
  "GB.101",
  "GB.102",
  "GB.103",
  "GB.104",
  "GB.105",
  "GB.106",
  "GB.107",
  "GB.108",
  "GB.109",
  "GB.110",
  "GB.111",
  "GB.112",
  "GB.113",
  "GB.114",
  "GB.115",
  "GB.116",
  "GB.117",
  "GB.118",
  "GB.119",
  "GB.120",
  "GB.121",
  "GB.122",
  "GB.123",
  "GB.124",
  "GB.125",
  "GB.126",
  "GB.127",
  "GB.128",
  "GB.129",
  "GB.130",
  "GB.131",
  "GB.132",
  "GB.133",
  "GB.134",
  "GB.135",
  "GB.136",
  "GB.137",
  "GB.138",
  "GB.139",
  "GB.140",
  "GB.141",
  "GB.142",
  "GB.143",
  "GB.144",
  "GB.145",
  "GB.146",
  "GB.147",
  "GB.148",
  "GB.149",
  "GB.150",
  "GB.151",
  "GB.152",
  "GB.153",
  "GB.154",
  "GB.155",
  "GB.156",
  "GB.157",
  "GB.158",
  "GB.159",
  "GB.160",
  "GB.161",
  "GB.162",
  "GB.163",
  "GB.164",
  "GB.165",
  "GB.166",
  "GB.167",
  "GB.168",
  "GB.169",
  "GB.170",
  "GB.171",
  "GB.172",
  "GB.173",
  "GB.174",
  "GB.175",
  "GB.176",
  "GB.177",
  "GB.178",
  "GB.179",
  "GB.180",
  "GB.181",
  "GB.182",
  "GB.183",
  "GB.184",
  "GB.185",
  "GB.186",
  "GB.187",
  "GB.188",
  "GB.189",
  "GB.190",
  "GB.191",
  "GB.192",
  "GB.193",
  "GB.194",
  "GB.195",
  "GB.196",
  "GB.197",
  "GB.198",
  "GB.199",
  "GB.200",
  "GB.201",
  "GB.201",
  "GB.202",
  "GB.203",
  "GB.204",
  "GB.205",
  "GB.206",
  "GB.207",
  "GB.208",
  "GB.209",
  "GB.210",
  "GB.211",
  "GB.212",
  "GB.213",
  "GB.214",
  "GB.216",
  "GB.217",
  "GB.218",
  "GB.219",
  "GB.220",
  "GB.221",
  "GB.222",
  "GB.223",
  "GB.224",
  "GB.225",
  "GB.226",
  "GB.227",
  "GB.228",
  "GB.229",
  "GB.230",
  "GB.231",
  "GB.232",
  "GB.233",
  "GB.234",
  "GB.235",
  "GB.236",
  "GB.237",
  "GB.238",
  "GB.239",
  "GB.240",
  "GB.241",
  "GB.242",
  "GB.243",
  "GB.244",
  "GB.245",
  "GB.246",
  "GB.247",
  "GB.248",
  "GB.249"
];

COUNTRY_MAP["GH"] = "co.29";
COUNTRY_CITY_MAP["GH"] = [
  "GH.1",
  "GH.2",
  "GH.3",
  "GH.4",
  "GH.5",
  "GH.6",
  "GH.7",
  "GH.8",
  "GH.9",
  "GH.10"
];

COUNTRY_MAP["GM"] = "co.30";
COUNTRY_CITY_MAP["GM"] = [
  "GM.1",
  "GM.2",
  "GM.3",
  "GM.4",
  "GM.5",
  "GM.6",
  "GM.7",
  "GM.8",
  "GM.9",
  "GM.10",
  "GM.11",
  "GM.12",
  "GM.13",
  "GM.14",
  "GM.15",
  "GM.16",
  "GM.17",
  "GM.18",
  "GM.19",
  "GM.20",
  "GM.21",
  "GM.22",
  "GM.23",
  "GM.24",
  "GM.25",
  "GM.26",
  "GM.27",
  "GM.28",
  "GM.29",
  "GM.30",
  "GM.31",
  "GM.32",
  "GM.33",
  "GM.34",
  "GM.35",
  "GM.36",
  "GM.37",
  "GM.38",
  "GM.39",
  "GM.40",
  "GM.41",
  "GM.42",
  "GM.43",
  "GM.44",
  "GM.45",
  "GM.46",
  "GM.47",
  "GM.48",
  "GM.49",
  "GM.50",
  "GM.51",
  "GM.52",
  "GM.53",
  "GM.54",
  "GM.55",
  "GM.56",
  "GM.57",
  "GM.58",
  "GM.59",
  "GM.60",
  "GM.61",
  "GM.62",
  "GM.63",
  "GM.64",
  "GM.65",
  "GM.66",
  "GM.67",
  "GM.68",
  "GM.69",
  "GM.70",
  "GM.71",
  "GM.72",
  "GM.73",
  "GM.74",
  "GM.75",
  "GM.76",
  "GM.77",
  "GM.78",
  "GM.79",
  "GM.80",
  "GM.81",
  "GM.82",
  "GM.83",
  "GM.84",
  "GM.85",
  "GM.86",
  "GM.87",
  "GM.88",
  "GM.89",
  "GM.90",
  "GM.91",
  "GM.92",
  "GM.93",
  "GM.94",
  "GM.95",
  "GM.96",
  "GM.97",
  "GM.98",
  "GM.99",
  "GM.100",
  "GM.101",
  "GM.102",
  "GM.103",
  "GM.104"
];

COUNTRY_MAP["GN"] = "co.31";
COUNTRY_CITY_MAP["GN"] = [
  "GN.1",
  "GN.2",
  "GN.3",
  "GN.4",
  "GN.5",
  "GN.6",
  "GN.7",
  "GN.8",
  "GN.9",
  "GN.10",
  "GN.11",
  "GN.12",
  "GN.13",
  "GN.14",
  "GN.15",
  "GN.16",
  "GN.17",
  "GN.18",
  "GN.19",
  "GN.20",
  "GN.21",
  "GN.22",
  "GN.23",
  "GN.24",
  "GN.25",
  "GN.26",
  "GN.27",
  "GN.28",
  "GN.29",
  "GN.30",
  "GN.31",
  "GN.32",
  "GN.33",
  "GN.34",
  "GN.35",
  "GN.36",
  "GN.37",
  "GN.38",
  "GN.39",
  "GN.40"
];

COUNTRY_MAP["GW"] = "co.32";
COUNTRY_CITY_MAP["GW"] = [
  "GW.1",
  "GW.2",
  "GW.3",
  "GW.4",
  "GW.5",
  "GW.6",
  "GW.7",
  "GW.8",
  "GW.9",
  "GW.10",
  "GW.11"
];

COUNTRY_MAP["ID"] = "co.33";
COUNTRY_CITY_MAP["ID"] = [
  "ID.1",
  "ID.2",
  "ID.3",
  "ID.4",
  "ID.5",
  "ID.6",
  "ID.7",
  "ID.8",
  "ID.9",
  "ID.10",
  "ID.11",
  "ID.12",
  "ID.13",
  "ID.14",
  "ID.15",
  "ID.16",
  "ID.17",
  "ID.18",
  "ID.19",
  "ID.20",
  "ID.21",
  "ID.22",
  "ID.23",
  "ID.24",
  "ID.25",
  "ID.26",
  "ID.27",
  "ID.28",
  "ID.29",
  "ID.30",
  "ID.31",
  "ID.32",
  "ID.33",
  "ID.34",
  "ID.35",
  "ID.36",
  "ID.37",
  "ID.38"
];

COUNTRY_MAP["IN"] = "co.34";
COUNTRY_CITY_MAP["IN"] = [
  "IN.1",
  "IN.2",
  "IN.3",
  "IN.4",
  "IN.5",
  "IN.6",
  "IN.7",
  "IN.8",
  "IN.9",
  "IN.10",
  "IN.11",
  "IN.12",
  "IN.13",
  "IN.14",
  "IN.15",
  "IN.16",
  "IN.17",
  "IN.18",
  "IN.19",
  "IN.20",
  "IN.21",
  "IN.22",
  "IN.23",
  "IN.24",
  "IN.25",
  "IN.26",
  "IN.27",
  "IN.28",
  "IN.29",
  "IN.30",
  "IN.31",
  "IN.32",
  "IN.33",
  "IN.34",
  "IN.35",
  "IN.36"
];

COUNTRY_MAP["IQ"] = "co.35";
COUNTRY_CITY_MAP["IQ"] = [
  "IQ.1",
  "IQ.2",
  "IQ.3",
  "IQ.4",
  "IQ.5",
  "IQ.6",
  "IQ.7",
  "IQ.8",
  "IQ.9",
  "IQ.10",
  "IQ.11",
  "IQ.12",
  "IQ.13",
  "IQ.14",
  "IQ.15",
  "IQ.16",
  "IQ.17",
  "IQ.18",
  "IQ.19",
  "IQ.20",
  "IQ.21",
  "IQ.22",
  "IQ.23",
  "IQ.24",
  "IQ.25",
  "IQ.26",
  "IQ.27",
  "IQ.28",
  "IQ.29",
  "IQ.30",
  "IQ.31",
  "IQ.32",
  "IQ.33",
  "IQ.34",
  "IQ.35",
  "IQ.36",
  "IQ.37",
  "IQ.38",
  "IQ.39",
  "IQ.40",
  "IQ.41",
  "IQ.42",
  "IQ.43",
  "IQ.44",
  "IQ.45",
  "IQ.46",
  "IQ.47",
  "IQ.48",
  "IQ.49",
  "IQ.50",
  "IQ.51",
  "IQ.52",
  "IQ.53",
  "IQ.54",
  "IQ.55",
  "IQ.56",
  "IQ.57",
  "IQ.58",
  "IQ.59",
  "IQ.60",
  "IQ.61",
  "IQ.62",
  "IQ.63",
  "IQ.64",
  "IQ.65",
  "IQ.66",
  "IQ.67",
  "IQ.68",
  "IQ.69",
  "IQ.70",
  "IQ.71",
  "IQ.72",
  "IQ.73",
  "IQ.74",
  "IQ.75",
  "IQ.76",
  "IQ.77",
  "IQ.78",
  "IQ.79",
  "IQ.80",
  "IQ.81",
  "IQ.82"
];

COUNTRY_MAP["IR"] = "co.36";
COUNTRY_CITY_MAP["IR"] = [
  "IR.1",
  "IR.2",
  "IR.3",
  "IR.4",
  "IR.5",
  "IR.6",
  "IR.7",
  "IR.8",
  "IR.9",
  "IR.10",
  "IR.11",
  "IR.12",
  "IR.13",
  "IR.14",
  "IR.15",
  "IR.16",
  "IR.17",
  "IR.18",
  "IR.19",
  "IR.20",
  "IR.21",
  "IR.22",
  "IR.23",
  "IR.24",
  "IR.25",
  "IR.26",
  "IR.27",
  "IR.28",
  "IR.29",
  "IR.30",
  "IR.31"
];

COUNTRY_MAP["IT"] = "co.37";
COUNTRY_CITY_MAP["IT"] = [
  "IT.1",
  "IT.2",
  "IT.3",
  "IT.4",
  "IT.5",
  "IT.6",
  "IT.7",
  "IT.8",
  "IT.9",
  "IT.10",
  "IT.11",
  "IT.12",
  "IT.13",
  "IT.14",
  "IT.15",
  "IT.16",
  "IT.17",
  "IT.18",
  "IT.19",
  "IT.20",
  "IT.21",
  "IT.22",
  "IT.23",
  "IT.24",
  "IT.25",
  "IT.26",
  "IT.27",
  "IT.28",
  "IT.29",
  "IT.30",
  "IT.31",
  "IT.32",
  "IT.33",
  "IT.34",
  "IT.35",
  "IT.36",
  "IT.37",
  "IT.38",
  "IT.39",
  "IT.40",
  "IT.41",
  "IT.42",
  "IT.43",
  "IT.44",
  "IT.45",
  "IT.46",
  "IT.47",
  "IT.48",
  "IT.49",
  "IT.50",
  "IT.51",
  "IT.52",
  "IT.53",
  "IT.54",
  "IT.55",
  "IT.56",
  "IT.57",
  "IT.58",
  "IT.59",
  "IT.60",
  "IT.61",
  "IT.62",
  "IT.63",
  "IT.64",
  "IT.65",
  "IT.66",
  "IT.67",
  "IT.68",
  "IT.69",
  "IT.70",
  "IT.71",
  "IT.72",
  "IT.73",
  "IT.74",
  "IT.75",
  "IT.76",
  "IT.77",
  "IT.78",
  "IT.79",
  "IT.80",
  "IT.81",
  "IT.82",
  "IT.83",
  "IT.84",
  "IT.85",
  "IT.86",
  "IT.87",
  "IT.88",
  "IT.89",
  "IT.90",
  "IT.91",
  "IT.92",
  "IT.93",
  "IT.94",
  "IT.95",
  "IT.96",
  "IT.97",
  "IT.98",
  "IT.99",
  "IT.100",
  "IT.101",
  "IT.102",
  "IT.103",
  "IT.104",
  "IT.105",
  "IT.106",
  "IT.107",
  "IT.108",
  "IT.109",
  "IT.110",
  "IT.111",
  "IT.112",
  "IT.113",
  "IT.114",
  "IT.115",
  "IT.116",
  "IT.117",
  "IT.118",
  "IT.119",
  "IT.120",
  "IT.121",
  "IT.122",
  "IT.123",
  "IT.124",
  "IT.125",
  "IT.126",
  "IT.127",
  "IT.128"
];

COUNTRY_MAP["JO"] = "co.38";
COUNTRY_CITY_MAP["JO"] = [
  "JO.1",
  "JO.2",
  "JO.3",
  "JO.4",
  "JO.5",
  "JO.6",
  "JO.7",
  "JO.8",
  "JO.9",
  "JO.10",
  "JO.11",
  "JO.12",
  "JO.13",
  "JO.14",
  "JO.15",
  "JO.16",
  "JO.17",
  "JO.18",
  "JO.19",
  "JO.20",
  "JO.21",
  "JO.22",
  "JO.23",
  "JO.24",
  "JO.25",
  "JO.26",
  "JO.27",
  "JO.28",
  "JO.29",
  "JO.30",
  "JO.31",
  "JO.32",
  "JO.33",
  "JO.34",
  "JO.35",
  "JO.36",
  "JO.37",
  "JO.38",
  "JO.39",
  "JO.40",
  "JO.41",
  "JO.42",
  "JO.43",
  "JO.44",
  "JO.45",
  "JO.46",
  "JO.47",
  "JO.48",
  "JO.49",
  "JO.50",
  "JO.51",
  "JO.52",
  "JO.53",
  "JO.54",
  "JO.55",
  "JO.56",
  "JO.57",
  "JO.58",
  "JO.59",
  "JO.60",
  "JO.61",
  "JO.62",
  "JO.63",
  "JO.64",
  "JO.65",
  "JO.66",
  "JO.67",
  "JO.68",
  "JO.69",
  "JO.70",
  "JO.71",
  "JO.72",
  "JO.73",
  "JO.74",
  "JO.75",
  "JO.76",
  "JO.77",
  "JO.78",
  "JO.79",
  "JO.80",
  "JO.81",
  "JO.82"
];

COUNTRY_MAP["KE"] = "co.39";
COUNTRY_CITY_MAP["KE"] = [
  "KE.1",
  "KE.2",
  "KE.3",
  "KE.4",
  "KE.5",
  "KE.6",
  "KE.7",
  "KE.8",
  "KE.9",
  "KE.10",
  "KE.11",
  "KE.12",
  "KE.13",
  "KE.14",
  "KE.15",
  "KE.16",
  "KE.17",
  "KE.18",
  "KE.19",
  "KE.20",
  "KE.21",
  "KE.22",
  "KE.23",
  "KE.24",
  "KE.25",
  "KE.26",
  "KE.27",
  "KE.28",
  "KE.29",
  "KE.30",
  "KE.31",
  "KE.32",
  "KE.33",
  "KE.34",
  "KE.35",
  "KE.36",
  "KE.37",
  "KE.38",
  "KE.39",
  "KE.40",
  "KE.41",
  "KE.42",
  "KE.43",
  "KE.44",
  "KE.45",
  "KE.46",
  "KE.47",
  "KE.48",
  "KE.49",
  "KE.50",
  "KE.51",
  "KE.52",
  "KE.53",
  "KE.54"
];

COUNTRY_MAP["KG"] = "co.40";
COUNTRY_CITY_MAP["KG"] = [
  "KG.1",
  "KG.2",
  "KG.3",
  "KG.4",
  "KG.5",
  "KG.6",
  "KG.7",
  "KG.8",
  "KG.9",
  "KG.10",
  "KG.11",
  "KG.12",
  "KG.13",
  "KG.14",
  "KG.15",
  "KG.16",
  "KG.17",
  "KG.18",
  "KG.19",
  "KG.20",
  "KG.21",
  "KG.22",
  "KG.23",
  "KG.24",
  "KG.25",
  "KG.26",
  "KG.27",
  "KG.28",
  "KG.29",
  "KG.30",
  "KG.31",
  "KG.32",
  "KG.33",
  "KG.34",
  "KG.35",
  "KG.36",
  "KG.37",
  "KG.38",
  "KG.39",
  "KG.40",
  "KG.41",
  "KG.42",
  "KG.43",
  "KG.44",
  "KG.45",
  "KG.46",
  "KG.47",
  "KG.48",
  "KG.49",
  "KG.50",
  "KG.51",
  "KG.52",
  "KG.53"
];

COUNTRY_MAP["KM"] = "co.41";
COUNTRY_CITY_MAP["KM"] = [
  "KM.1",
  "KM.2",
  "KM.3",
  "KM.4",
  "KM.5",
  "KM.6",
  "KM.7",
  "KM.8",
  "KM.9",
  "KM.10",
  "KM.11",
  "KM.12",
  "KM.13",
  "KM.14",
  "KM.15",
  "KM.16",
  "KM.17",
  "KM.18",
  "KM.19",
  "KM.20",
  "KM.21",
  "KM.22",
  "KM.23",
  "KM.24",
  "KM.25",
  "KM.26",
  "KM.27",
  "KM.28",
  "KM.29",
  "KM.30",
  "KM.31",
  "KM.32",
  "KM.33",
  "KM.34",
  "KM.35",
  "KM.36",
  "KM.37",
  "KM.38",
  "KM.39",
  "KM.40",
  "KM.41",
  "KM.42",
  "KM.43",
  "KM.44",
  "KM.45",
  "KM.46",
  "KM.47",
  "KM.48",
  "KM.49",
  "KM.50",
  "KM.51",
  "KM.52",
  "KM.53",
  "KM.54",
  "KM.55",
  "KM.56",
  "KM.57",
  "KM.58",
  "KM.59",
  "KM.60",
  "KM.61",
  "KM.62",
  "KM.63",
  "KM.64",
  "KM.65",
  "KM.66",
  "KM.67",
  "KM.68",
  "KM.69",
  "KM.70",
  "KM.71",
  "KM.72",
  "KM.73",
  "KM.74",
  "KM.75",
  "KM.76",
  "KM.77",
  "KM.78",
  "KM.79",
  "KM.80",
  "KM.81",
  "KM.82"
];

COUNTRY_MAP["LB"] = "co.42";
COUNTRY_CITY_MAP["LB"] = [
  "LB.1",
  "LB.2",
  "LB.3",
  "LB.4",
  "LB.5",
  "LB.6",
  "LB.7",
  "LB.8",
  "LB.9",
  "LB.10",
  "LB.11",
  "LB.12",
  "LB.13",
  "LB.14",
  "LB.15",
  "LB.16",
  "LB.17",
  "LB.18",
  "LB.19",
  "LB.20",
  "LB.21",
  "LB.22",
  "LB.23",
  "LB.24",
  "LB.25",
  "LB.26"
];

COUNTRY_MAP["LR"] = "co.43";
COUNTRY_CITY_MAP["LR"] = [
  "LR.1",
  "LR.2",
  "LR.3",
  "LR.4",
  "LR.5",
  "LR.6",
  "LR.7",
  "LR.8",
  "LR.9",
  "LR.10",
  "LR.11",
  "LR.12",
  "LR.13",
  "LR.14",
  "LR.15",
  "LR.16",
  "LR.17",
  "LR.18"
];

COUNTRY_MAP["LY"] = "co.44";
COUNTRY_CITY_MAP["LY"] = [
  "LY.1",
  "LY.2",
  "LY.3",
  "LY.4",
  "LY.5",
  "LY.6",
  "LY.7",
  "LY.8",
  "LY.9",
  "LY.10",
  "LY.11",
  "LY.12",
  "LY.13",
  "LY.14",
  "LY.15",
  "LY.16",
  "LY.17",
  "LY.18",
  "LY.19",
  "LY.20"
];

COUNTRY_MAP["ME"] = "co.45";
COUNTRY_CITY_MAP["ME"] = [
  "ME.1",
  "ME.2",
  "ME.3",
  "ME.4",
  "ME.5",
  "ME.6",
  "ME.7",
  "ME.8",
  "ME.9",
  "ME.10",
  "ME.11",
  "ME.12",
  "ME.13",
  "ME.14",
  "ME.15",
  "ME.16",
  "ME.17",
  "ME.18",
  "ME.19",
  "ME.20",
  "ME.21",
  "ME.22",
  "ME.23",
  "ME.24",
  "ME.25",
  "ME.26",
  "ME.27",
  "ME.28",
  "ME.29",
  "ME.30",
  "ME.31",
  "ME.32",
  "ME.33",
  "ME.34"
];

COUNTRY_MAP["MG"] = "co.46";
COUNTRY_CITY_MAP["MG"] = [
  "MG.1",
  "MG.2",
  "MG.3",
  "MG.4",
  "MG.5",
  "MG.6",
  "MG.7",
  "MG.8",
  "MG.9"
];

COUNTRY_MAP["MK"] = "co.47";
COUNTRY_CITY_MAP["MK"] = [
  "MK.1",
  "MK.2",
  "MK.3",
  "MK.4",
  "MK.5",
  "MK.6",
  "MK.7",
  "MK.8",
  "MK.9",
  "MK.10",
  "MK.11",
  "MK.12",
  "MK.13",
  "MK.14",
  "MK.15",
  "MK.16",
  "MK.17",
  "MK.18",
  "MK.19",
  "MK.20",
  "MK.21",
  "MK.22",
  "MK.23",
  "MK.24",
  "MK.25",
  "MK.26",
  "MK.27",
  "MK.28",
  "MK.29",
  "MK.30",
  "MK.31",
  "MK.32",
  "MK.33",
  "MK.34",
  "MK.35",
  "MK.36",
  "MK.37",
  "MK.38",
  "MK.39",
  "MK.40",
  "MK.41",
  "MK.42",
  "MK.43",
  "MK.44",
  "MK.45",
  "MK.46",
  "MK.47",
  "MK.48",
  "MK.49",
  "MK.50",
  "MK.51",
  "MK.52",
  "MK.53",
  "MK.54",
  "MK.55",
  "MK.56",
  "MK.57",
  "MK.58",
  "MK.59",
  "MK.60",
  "MK.61",
  "MK.62",
  "MK.63",
  "MK.64",
  "MK.65",
  "MK.66",
  "MK.67",
  "MK.68",
  "MK.69",
  "MK.70",
  "MK.71",
  "MK.72",
  "MK.73",
  "MK.74",
  "MK.75",
  "MK.76",
  "MK.77",
  "MK.78",
  "MK.79",
  "MK.80",
  "MK.81",
  "MK.82",
  "MK.83",
  "MK.84"
];

COUNTRY_MAP["ML"] = "co.48";
COUNTRY_CITY_MAP["ML"] = [
  "ML.1",
  "ML.2",
  "ML.3",
  "ML.4",
  "ML.5",
  "ML.6",
  "ML.7",
  "ML.8",
  "ML.9",
  "ML.10",
  "ML.11"
];

COUNTRY_MAP["MM"] = "co.49";
COUNTRY_CITY_MAP["MM"] = [
  "MM.1",
  "MM.2",
  "MM.3",
  "MM.4",
  "MM.5",
  "MM.6",
  "MM.7",
  "MM.8",
  "MM.9",
  "MM.10",
  "MM.11",
  "MM.12",
  "MM.13",
  "MM.14",
  "MM.15"
];

COUNTRY_MAP["MR"] = "co.50";
COUNTRY_CITY_MAP["MR"] = [
  "MR.1",
  "MR.2",
  "MR.3",
  "MR.4",
  "MR.5",
  "MR.6",
  "MR.7",
  "MR.8",
  "MR.9",
  "MR.10",
  "MR.11",
  "MR.12",
  "MR.13",
  "MR.14",
  "MR.15",
  "MR.16",
  "MR.17",
  "MR.18",
  "MR.19",
  "MR.20",
  "MR.21",
  "MR.22",
  "MR.23"
];
COUNTRY_MAP["MV"] = "co.51";
COUNTRY_CITY_MAP["MV"] = [
  "MV.1",
  "MV.2",
  "MV.3",
  "MV.4",
  "MV.5",
  "MV.6",
  "MV.7",
  "MV.8",
  "MV.9",
  "MV.10",
  "MV.11",
  "MV.12",
  "MV.13",
  "MV.14",
  "MV.15",
  "MV.16",
  "MV.17",
  "MV.18",
  "MV.19",
  "MV.20",
  "MV.21",
  "MV.22",
  "MV.23",
  "MV.24",
  "MV.25",
  "MV.26"
];
COUNTRY_MAP["MW"] = "co.52";
COUNTRY_CITY_MAP["MW"] = [
  "MW.1",
  "MW.2",
  "MW.3",
  "MW.4",
  "MW.5",
  "MW.6",
  "MW.7",
  "MW.8",
  "MW.9",
  "MW.10",
  "MW.11",
  "MW.12",
  "MW.13",
  "MW.14",
  "MW.15",
  "MW.16",
  "MW.17",
  "MW.18",
  "MW.19",
  "MW.20",
  "MW.21",
  "MW.22",
  "MW.23",
  "MW.24",
  "MW.25",
  "MW.26",
  "MW.27",
  "MW.28",
  "MW.29",
  "MW.30"
];
COUNTRY_MAP["MY"] = "co.53";
COUNTRY_CITY_MAP["MY"] = [
  "MY.1",
  "MY.2",
  "MY.3",
  "MY.4",
  "MY.5",
  "MY.6",
  "MY.7",
  "MY.8",
  "MY.9",
  "MY.10",
  "MY.11",
  "MY.12",
  "MY.13",
  "MY.14",
  "MY.15",
  "MY.16"
];
COUNTRY_MAP["NE"] = "co.54";
COUNTRY_CITY_MAP["NE"] = [
  "NE.1",
  "NE.2",
  "NE.3",
  "NE.4",
  "NE.5",
  "NE.6",
  "NE.7",
  "NE.8",
  "NE.9",
  "NE.10",
  "NE.11",
  "NE.12",
  "NE.13",
  "NE.14",
  "NE.15",
  "NE.16",
  "NE.17",
  "NE.18",
  "NE.19",
  "NE.20",
  "NE.21",
  "NE.22",
  "NE.23",
  "NE.24",
  "NE.25",
  "NE.26",
  "NE.27",
  "NE.28",
  "NE.29",
  "NE.30",
  "NE.31",
  "NE.32",
  "NE.33",
  "NE.34",
  "NE.35",
  "NE.36",
  "NE.37",
  "NE.38",
  "NE.39",
  "NE.40",
  "NE.41",
  "NE.42",
  "NE.43",
  "NE.44",
  "NE.45",
  "NE.46",
  "NE.47",
  "NE.48",
  "NE.49",
  "NE.50",
  "NE.51",
  "NE.52",
  "NE.53",
  "NE.54",
  "NE.55",
  "NE.56",
  "NE.57",
  "NE.58",
  "NE.59",
  "NE.60",
  "NE.61",
  "NE.62",
  "NE.63",
  "NE.64",
  "NE.65",
  "NE.66",
  "NE.67",
  "NE.68",
  "NE.69",
  "NE.70"
];
COUNTRY_MAP["NG"] = "co.55";
COUNTRY_CITY_MAP["NG"] = [
  "NG.1",
  "NG.2",
  "NG.3",
  "NG.4",
  "NG.5",
  "NG.6",
  "NG.7",
  "NG.8",
  "NG.9",
  "NG.10",
  "NG.11",
  "NG.12",
  "NG.13",
  "NG.14",
  "NG.15",
  "NG.16",
  "NG.17",
  "NG.18",
  "NG.19",
  "NG.20",
  "NG.21",
  "NG.22",
  "NG.23",
  "NG.24",
  "NG.25",
  "NG.26",
  "NG.27",
  "NG.28",
  "NG.29",
  "NG.30",
  "NG.31",
  "NG.32",
  "NG.33",
  "NG.34",
  "NG.35",
  "NG.36"
];
COUNTRY_MAP["OM"] = "co.56";
COUNTRY_CITY_MAP["OM"] = [
  "OM.1",
  "OM.2",
  "OM.3",
  "OM.4",
  "OM.5",
  "OM.6",
  "OM.7",
  "OM.8",
  "OM.9",
  "OM.10",
  "OM.11",
  "OM.12",
  "OM.13",
  "OM.14",
  "OM.15",
  "OM.16",
  "OM.17",
  "OM.18",
  "OM.19",
  "OM.20",
  "OM.21",
  "OM.22",
  "OM.23",
  "OM.24",
  "OM.25",
  "OM.26",
  "OM.27",
  "OM.28"
];
COUNTRY_MAP["PH"] = "co.57";
COUNTRY_CITY_MAP["PH"] = [
  "PH.1",
  "PH.2",
  "PH.3",
  "PH.4",
  "PH.5",
  "PH.6",
  "PH.7",
  "PH.8",
  "PH.9",
  "PH.10",
  "PH.11",
  "PH.12",
  "PH.13",
  "PH.14",
  "PH.15",
  "PH.16",
  "PH.17",
  "PH.18",
  "PH.19",
  "PH.20",
  "PH.21",
  "PH.22",
  "PH.23",
  "PH.24",
  "PH.25",
  "PH.26",
  "PH.27",
  "PH.28",
  "PH.29",
  "PH.30",
  "PH.31",
  "PH.32",
  "PH.33",
  "PH.34",
  "PH.35",
  "PH.36",
  "PH.37",
  "PH.38",
  "PH.39",
  "PH.40",
  "PH.41",
  "PH.42",
  "PH.43",
  "PH.44",
  "PH.45",
  "PH.46",
  "PH.47",
  "PH.48",
  "PH.49",
  "PH.50",
  "PH.51",
  "PH.52",
  "PH.53",
  "PH.54",
  "PH.55",
  "PH.56",
  "PH.57",
  "PH.58",
  "PH.59",
  "PH.60",
  "PH.61",
  "PH.62",
  "PH.63",
  "PH.64",
  "PH.65",
  "PH.66",
  "PH.67",
  "PH.68",
  "PH.69",
  "PH.70",
  "PH.71",
  "PH.72",
  "PH.73",
  "PH.74",
  "PH.75",
  "PH.76",
  "PH.77",
  "PH.78",
  "PH.79",
  "PH.80",
  "PH.81",
  "PH.82",
  "PH.83",
  "PH.84",
  "PH.85",
  "PH.86",
  "PH.87",
  "PH.88",
  "PH.89",
  "PH.90",
  "PH.91",
  "PH.92",
  "PH.93",
  "PH.94",
  "PH.95",
  "PH.96",
  "PH.97"
];
COUNTRY_MAP["PK"] = "co.58";
COUNTRY_CITY_MAP["PK"] = [
  "PK.1",
  "PK.2",
  "PK.3",
  "PK.4",
  "PK.5",
  "PK.6",
  "PK.7",
  "PK.8"
];
COUNTRY_MAP["PS"] = "co.59";
COUNTRY_CITY_MAP["PS"] = [
  "PS.1",
  "PS.2",
  "PS.3",
  "PS.4",
  "PS.5",
  "PS.6",
  "PS.7",
  "PS.8",
  "PS.9",
  "PS.10",
  "PS.11",
  "PS.12",
  "PS.13",
  "PS.14",
  "PS.15",
  "PS.16",
  "PS.17",
  "PS.18",
  "PS.19",
  "PS.20",
  "PS.21",
  "PS.22",
  "PS.23",
  "PS.24",
  "PS.25",
  "PS.26",
  "PS.27",
  "PS.28",
  "PS.29",
  "PS.30",
  "PS.31",
  "PS.32",
  "PS.33",
  "PS.34",
  "PS.35",
  "PS.36",
  "PS.37"
];
COUNTRY_MAP["QA"] = "co.60";
COUNTRY_CITY_MAP["QA"] = [
  "QA.1",
  "QA.2",
  "QA.3",
  "QA.4",
  "QA.5",
  "QA.6",
  "QA.7",
  "QA.8",
  "QA.9",
  "QA.10",
  "QA.11",
  "QA.12",
  "QA.13",
  "QA.14",
  "QA.15"
];

COUNTRY_MAP["RU"] = "co.61";
COUNTRY_CITY_MAP["RU"] = [
  "RU.1",
  "RU.2",
  "RU.3",
  "RU.4",
  "RU.5",
  "RU.6",
  "RU.7",
  "RU.8",
  "RU.9",
  "RU.10",
  "RU.11",
  "RU.12",
  "RU.13",
  "RU.14",
  "RU.15",
  "RU.16",
  "RU.17",
  "RU.18",
  "RU.19",
  "RU.20",
  "RU.21",
  "RU.22",
  "RU.23",
  "RU.24",
  "RU.25",
  "RU.26",
  "RU.27",
  "RU.28",
  "RU.29",
  "RU.30",
  "RU.31",
  "RU.32",
  "RU.33",
  "RU.34",
  "RU.35",
  "RU.36",
  "RU.37",
  "RU.38",
  "RU.39",
  "RU.40",
  "RU.41",
  "RU.42",
  "RU.43",
  "RU.44",
  "RU.45",
  "RU.46",
  "RU.47",
  "RU.48",
  "RU.49",
  "RU.50",
  "RU.51",
  "RU.52",
  "RU.53",
  "RU.54",
  "RU.55",
  "RU.56",
  "RU.57",
  "RU.58",
  "RU.59",
  "RU.60",
  "RU.61",
  "RU.62",
  "RU.63",
  "RU.64",
  "RU.65",
  "RU.66",
  "RU.67",
  "RU.68",
  "RU.69",
  "RU.70",
  "RU.71",
  "RU.72",
  "RU.73",
  "RU.74",
  "RU.75",
  "RU.76",
  "RU.77",
  "RU.78",
  "RU.79",
  "RU.80",
  "RU.81",
  "RU.82",
  "RU.83",
  "RU.84"
];
COUNTRY_MAP["SD"] = "co.62";
COUNTRY_CITY_MAP["SD"] = [
  "SD.1",
  "SD.2",
  "SD.3",
  "SD.4",
  "SD.5",
  "SD.6",
  "SD.7",
  "SD.8",
  "SD.9",
  "SD.10"
];
COUNTRY_MAP["SL"] = "co.63";
COUNTRY_CITY_MAP["SL"] = [
  "SL.1",
  "SL.2",
  "SL.3",
  "SL.4",
  "SL.5",
  "SL.6",
  "SL.7",
  "SL.8",
  "SL.9",
  "SL.10",
  "SL.11",
  "SL.12",
  "SL.13",
  "SL.14",
  "SL.15",
  "SL.16",
  "SL.17",
  "SL.18",
  "SL.19",
  "SL.20",
  "SL.21",
  "SL.22",
  "SL.23",
  "SL.24",
  "SL.25",
  "SL.26",
  "SL.27",
  "SL.28",
  "SL.29",
  "SL.30",
  "SL.31",
  "SL.32",
  "SL.33",
  "SL.34",
  "SL.35",
  "SL.36",
  "SL.37",
  "SL.38",
  "SL.39",
  "SL.40",
  "SL.41",
  "SL.42",
  "SL.43",
  "SL.44",
  "SL.45",
  "SL.46",
  "SL.47",
  "SL.48",
  "SL.49",
  "SL.50",
  "SL.51",
  "SL.52",
  "SL.53",
  "SL.54",
  "SL.55",
  "SL.56",
  "SL.57",
  "SL.58",
  "SL.59",
  "SL.60",
  "SL.61",
  "SL.62",
  "SL.63",
  "SL.64",
  "SL.65",
  "SL.66",
  "SL.67",
  "SL.68",
  "SL.69",
  "SL.70",
  "SL.71",
  "SL.72",
  "SL.73",
  "SL.74",
  "SL.75",
  "SL.76",
  "SL.77",
  "SL.78",
  "SL.79",
  "SL.80",
  "SL.81",
  "SL.82",
  "SL.83",
  "SL.84",
  "SL.85",
  "SL.86",
  "SL.87",
  "SL.88",
  "SL.89",
  "SL.90"
];
COUNTRY_MAP["SN"] = "co.64";
COUNTRY_CITY_MAP["SN"] = [
  "SN.1",
  "SN.2",
  "SN.3",
  "SN.4",
  "SN.5",
  "SN.6",
  "SN.7",
  "SN.8",
  "SN.9",
  "SN.10",
  "SN.11",
  "SN.12",
  "SN.13",
  "SN.14"
];
COUNTRY_MAP["SO"] = "co.65";
COUNTRY_CITY_MAP["SO"] = [
  "SO.1",
  "SO.2",
  "SO.3",
  "SO.4",
  "SO.5",
  "SO.6",
  "SO.7",
  "SO.8",
  "SO.9",
  "SO.10",
  "SO.11",
  "SO.12",
  "SO.13",
  "SO.14",
  "SO.15",
  "SO.16"
];
COUNTRY_MAP["SY"] = "co.66";
COUNTRY_CITY_MAP["SY"] = [
  "SY.1",
  "SY.2",
  "SY.3",
  "SY.4",
  "SY.5",
  "SY.6",
  "SY.7",
  "SY.8",
  "SY.9",
  "SY.10",
  "SY.11",
  "SY.12",
  "SY.13",
  "SY.14"
];
COUNTRY_MAP["TD"] = "co.67";
COUNTRY_CITY_MAP["TD"] = [
  "TD.1",
  "TD.2",
  "TD.3",
  "TD.4",
  "TD.5",
  "TD.6",
  "TD.7",
  "TD.8",
  "TD.9",
  "TD.10",
  "TD.11",
  "TD.12",
  "TD.13",
  "TD.14",
  "TD.15",
  "TD.16",
  "TD.17",
  "TD.18",
  "TD.19",
  "TD.20",
  "TD.21",
  "TD.22",
  "TD.23"
];

COUNTRY_MAP["TG"] = "co.68";
COUNTRY_CITY_MAP["tg"] = [
  "TG.1",
  "TG.2",
  "TG.3",
  "TG.4",
  "TG.5",
  "TG.6",
  "TG.7",
  "TG.8",
  "TG.9",
  "TG.10",
  "TG.11",
  "TG.12",
  "TG.13",
  "TG.14",
  "TG.15",
  "TG.16",
  "TG.17",
  "TG.18",
  "TG.19",
  "TG.20",
  "TG.21",
  "TG.22"
];

COUNTRY_MAP["TH"] = "co.69";
COUNTRY_CITY_MAP["TH"] = [
  "TH.1",
  "TH.2",
  "TH.3",
  "TH.4",
  "TH.5",
  "TH.6",
  "TH.7",
  "TH.8",
  "TH.9",
  "TH.10",
  "TH.11",
  "TH.12",
  "TH.13",
  "TH.14",
  "TH.15",
  "TH.16",
  "TH.17",
  "TH.18",
  "TH.19",
  "TH.20",
  "TH.21",
  "TH.22",
  "TH.23",
  "TH.24",
  "TH.25",
  "TH.26",
  "TH.27",
  "TH.28",
  "TH.29",
  "TH.30",
  "TH.31",
  "TH.32",
  "TH.33",
  "TH.34",
  "TH.35",
  "TH.36",
  "TH.37",
  "TH.38",
  "TH.39",
  "TH.40",
  "TH.41",
  "TH.42",
  "TH.43",
  "TH.44",
  "TH.45",
  "TH.46",
  "TH.47",
  "TH.48",
  "TH.49",
  "TH.50",
  "TH.51",
  "TH.52",
  "TH.53",
  "TH.54",
  "TH.55",
  "TH.56",
  "TH.57",
  "TH.58",
  "TH.59",
  "TH.60",
  "TH.61",
  "TH.62",
  "TH.63",
  "TH.64",
  "TH.65",
  "TH.66",
  "TH.67",
  "TH.68",
  "TH.69",
  "TH.70",
  "TH.71",
  "TH.72",
  "TH.73",
  "TH.74",
  "TH.75",
  "TH.76",
  "TH.77"
];
COUNTRY_MAP["TJ"] = "co.70";
COUNTRY_CITY_MAP["TJ"] = [
  "TJ.1",
  "TJ.2",
  "TJ.3",
  "TJ.4",
  "TJ.5",
  "TJ.6",
  "TJ.7",
  "TJ.8",
  "TJ.9",
  "TJ.10",
  "TJ.11",
  "TJ.12",
  "TJ.13",
  "TJ.14",
  "TJ.15",
  "TJ.16",
  "TJ.17",
  "TJ.18",
  "TJ.19",
  "TJ.20",
  "TJ.21",
  "TJ.22",
  "TJ.23",
  "TJ.24",
  "TJ.25",
  "TJ.26",
  "TJ.27",
  "TJ.28",
  "TJ.29",
  "TJ.30",
  "TJ.31",
  "TJ.32",
  "TJ.33",
  "TJ.34",
  "TJ.35",
  "TJ.36",
  "TJ.37",
  "TJ.38",
  "TJ.39",
  "TJ.40",
  "TJ.41",
  "TJ.42",
  "TJ.43",
  "TJ.44",
  "TJ.45",
  "TJ.46",
  "TJ.47",
  "TJ.48",
  "TJ.49",
  "TJ.50",
  "TJ.51",
  "TJ.52",
  "TJ.53",
  "TJ.54",
  "TJ.55",
  "TJ.56",
  "TJ.57",
  "TJ.58",
  "TJ.59",
  "TJ.60",
  "TJ.61",
  "TJ.62",
  "TJ.63",
  "TJ.64",
  "TJ.65",
  "TJ.66",
  "TJ.67",
  "TJ.68",
  "TJ.69",
  "TJ.70"
];
COUNTRY_MAP["TM"] = "co.71";
COUNTRY_CITY_MAP["TM"] = [
  "TM.1",
  "TM.2",
  "TM.3",
  "TM.4",
  "TM.5",
  "TM.6",
  "TM.7",
  "TM.8",
  "TM.9",
  "TM.10",
  "TM.11",
  "TM.12",
  "TM.13",
  "TM.14",
  "TM.15",
  "TM.16",
  "TM.17",
  "TM.18",
  "TM.19",
  "TM.20",
  "TM.21",
  "TM.22",
  "TM.23",
  "TM.24",
  "TM.25",
  "TM.26",
  "TM.27",
  "TM.28",
  "TM.29",
  "TM.30"
];

COUNTRY_MAP["TN"] = "co.72";
COUNTRY_CITY_MAP["TN"] = [
  "TN.1",
  "TN.2",
  "TN.3",
  "TN.4",
  "TN.5",
  "TN.6",
  "TN.7",
  "TN.8",
  "TN.9",
  "TN.10",
  "TN.11",
  "TN.12",
  "TN.13",
  "TN.14",
  "TN.15",
  "TN.16",
  "TN.17",
  "TN.18",
  "TN.19",
  "TN.20",
  "TN.21",
  "TN.22",
  "TN.23"
];
COUNTRY_MAP["TR"] = "co.73";
COUNTRY_CITY_MAP["TR"] = [
  "TR.1",
  "TR.2",
  "TR.3",
  "TR.4",
  "TR.5",
  "TR.6",
  "TR.7",
  "TR.8",
  "TR.9",
  "TR.10",
  "TR.11",
  "TR.12",
  "TR.13",
  "TR.14",
  "TR.15",
  "TR.16",
  "TR.17",
  "TR.18",
  "TR.19",
  "TR.20",
  "TR.21",
  "TR.22",
  "TR.23",
  "TR.24",
  "TR.25",
  "TR.26",
  "TR.27",
  "TR.28",
  "TR.29",
  "TR.30",
  "TR.31",
  "TR.32",
  "TR.33",
  "TR.34",
  "TR.35",
  "TR.36",
  "TR.37",
  "TR.38",
  "TR.39",
  "TR.40",
  "TR.41",
  "TR.42",
  "TR.43",
  "TR.44",
  "TR.45",
  "TR.46",
  "TR.47",
  "TR.48",
  "TR.49",
  "TR.50",
  "TR.51",
  "TR.52",
  "TR.53",
  "TR.54",
  "TR.55",
  "TR.56",
  "TR.57",
  "TR.58",
  "TR.59",
  "TR.60",
  "TR.61",
  "TR.62",
  "TR.63",
  "TR.64",
  "TR.65",
  "TR.66",
  "TR.67",
  "TR.68",
  "TR.69",
  "TR.70",
  "TR.71",
  "TR.72",
  "TR.73",
  "TR.74",
  "TR.75",
  "TR.76",
  "TR.77",
  "TR.78",
  "TR.79",
  "TR.80",
  "TR.81"
];
COUNTRY_MAP["TZ"] = "co.74";
COUNTRY_CITY_MAP["TZ"] = [
  "TZ.1",
  "TZ.2",
  "TZ.3",
  "TZ.4",
  "TZ.5",
  "TZ.6",
  "TZ.7",
  "TZ.8",
  "TZ.9",
  "TZ.10",
  "TZ.11",
  "TZ.12",
  "TZ.13",
  "TZ.14",
  "TZ.15",
  "TZ.16",
  "TZ.17",
  "TZ.18",
  "TZ.19",
  "TZ.20",
  "TZ.21",
  "TZ.22",
  "TZ.23",
  "TZ.24",
  "TZ.25",
  "TZ.26",
  "TZ.27",
  "TZ.28",
  "TZ.29"
];
COUNTRY_MAP["UG"] = "co.75";
COUNTRY_CITY_MAP["UG"] = [
  "UG.1",
  "UG.2",
  "UG.3",
  "UG.4",
  "UG.5",
  "UG.6",
  "UG.7",
  "UG.8",
  "UG.9",
  "UG.10",
  "UG.11",
  "UG.12",
  "UG.13",
  "UG.14",
  "UG.15",
  "UG.16",
  "UG.17",
  "UG.18",
  "UG.19",
  "UG.20",
  "UG.21",
  "UG.22",
  "UG.23",
  "UG.24",
  "UG.25",
  "UG.26",
  "UG.27",
  "UG.28",
  "UG.29",
  "UG.30",
  "UG.31",
  "UG.32",
  "UG.33",
  "UG.34",
  "UG.35",
  "UG.36",
  "UG.37",
  "UG.38",
  "UG.39",
  "UG.40",
  "UG.41",
  "UG.42",
  "UG.43",
  "UG.44",
  "UG.45",
  "UG.46",
  "UG.47",
  "UG.48",
  "UG.49",
  "UG.50",
  "UG.51",
  "UG.52",
  "UG.53",
  "UG.54",
  "UG.55",
  "UG.56",
  "UG.57",
  "UG.58",
  "UG.59",
  "UG.60",
  "UG.61",
  "UG.62",
  "UG.63",
  "UG.64",
  "UG.65",
  "UG.66",
  "UG.67",
  "UG.68",
  "UG.69",
  "UG.70",
  "UG.71",
  "UG.72",
  "UG.73",
  "UG.74",
  "UG.75",
  "UG.76",
  "UG.77",
  "UG.78",
  "UG.79",
  "UG.80",
  "UG.81",
  "UG.82",
  "UG.83",
  "UG.84",
  "UG.85",
  "UG.86",
  "UG.87",
  "UG.88",
  "UG.89",
  "UG.90",
  "UG.91"
];
COUNTRY_MAP["UZ"] = "co.76";
COUNTRY_CITY_MAP["UZ"] = [
  "UZ.1",
  "UZ.2",
  "UZ.3",
  "UZ.4",
  "UZ.5",
  "UZ.6",
  "UZ.7",
  "UZ.8",
  "UZ.9",
  "UZ.10",
  "UZ.11",
  "UZ.12",
  "UZ.13",
  "UZ.14"
];

COUNTRY_MAP["YE"] = "co.77";
COUNTRY_CITY_MAP["YE"] = [
  "YE.1",
  "YE.2",
  "YE.3",
  "YE.4",
  "YE.5",
  "YE.6",
  "YE.7",
  "YE.8",
  "YE.9",
  "YE.10",
  "YE.11",
  "YE.12",
  "YE.13",
  "YE.14",
  "YE.15",
  "YE.16",
  "YE.17",
  "YE.18",
  "YE.19",
  "YE.20",
  "YE.21"
];

// COUNTRY_CITY_MAP["gb"] = [
//   "gb.1" ,
//   "gb.2"
// ];

export const COUNTRY_CITY_MAP_VALUE = new Map();
COUNTRY_CITY_MAP_VALUE["DZ"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58
];
COUNTRY_CITY_MAP_VALUE["EG"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27
];
COUNTRY_CITY_MAP_VALUE["MA"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

COUNTRY_CITY_MAP_VALUE["SA"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
COUNTRY_CITY_MAP_VALUE["KW"] = [1, 2, 3, 4, 5, 6];
COUNTRY_CITY_MAP_VALUE["AR"] = [1, 2, 3, 4, 5, 6];
COUNTRY_CITY_MAP_VALUE["US"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51
];

COUNTRY_CITY_MAP_VALUE["CA"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

COUNTRY_CITY_MAP_VALUE["SG"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76
];

COUNTRY_CITY_MAP_VALUE["UA"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24
];

COUNTRY_CITY_MAP_VALUE["PT"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55
];

COUNTRY_CITY_MAP_VALUE["AF"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18
];

COUNTRY_CITY_MAP_VALUE["AL"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75
];

COUNTRY_CITY_MAP_VALUE["AZ"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65
];

COUNTRY_CITY_MAP_VALUE["BA"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25
];

COUNTRY_CITY_MAP_VALUE["BD"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

COUNTRY_CITY_MAP_VALUE["BF"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65
];

COUNTRY_CITY_MAP_VALUE["BG"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28
];

COUNTRY_CITY_MAP_VALUE["BH"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

COUNTRY_CITY_MAP_VALUE["BJ"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39
];

COUNTRY_CITY_MAP_VALUE["CI"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40
];

COUNTRY_CITY_MAP_VALUE["CM"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

COUNTRY_CITY_MAP_VALUE["CN"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33
];

COUNTRY_CITY_MAP_VALUE["DE"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17
];

COUNTRY_CITY_MAP_VALUE["DJ"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

COUNTRY_CITY_MAP_VALUE["ES"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49
];
COUNTRY_CITY_MAP_VALUE["FR"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42
];

COUNTRY_CITY_MAP_VALUE["GB"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104,
  105,
  106,
  107,
  108,
  109,
  110,
  111,
  112,
  113,
  114,
  115,
  116,
  117,
  118,
  119,
  120,
  121,
  122,
  123,
  124,
  125,
  126,
  127,
  128,
  129,
  130,
  131,
  132,
  133,
  134,
  135,
  136,
  137,
  138,
  139,
  140,
  141,
  142,
  143,
  144,
  145,
  146,
  147,
  148,
  149,
  150,
  151,
  152,
  153,
  154,
  155,
  156,
  157,
  158,
  159,
  160,
  161,
  162,
  163,
  164,
  165,
  166,
  167,
  168,
  169,
  170,
  171,
  172,
  173,
  174,
  175,
  176,
  177,
  178,
  179,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  187,
  188,
  189,
  190,
  191,
  192,
  193,
  194,
  195,
  196,
  197,
  198,
  199,
  200,
  201,
  202,
  203,
  204,
  205,
  206,
  207,
  208,
  209,
  210,
  211,
  212,
  213,
  214,
  215,
  216,
  217,
  218,
  219,
  220,
  221,
  222,
  223,
  224,
  225,
  226,
  227,
  228,
  229,
  230,
  231,
  232,
  233,
  234,
  235,
  236,
  237,
  238,
  239,
  240,
  241,
  242,
  243,
  244,
  245,
  246,
  247
];
COUNTRY_CITY_MAP_VALUE["GH"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

COUNTRY_CITY_MAP_VALUE["GM"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104
];
COUNTRY_CITY_MAP_VALUE["GN"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40
];
COUNTRY_CITY_MAP_VALUE["GW"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

COUNTRY_CITY_MAP_VALUE["ID"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38
];
COUNTRY_CITY_MAP_VALUE["IN"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36
];
COUNTRY_CITY_MAP_VALUE["IQ"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82
];
COUNTRY_CITY_MAP_VALUE["IR"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31
];
COUNTRY_CITY_MAP_VALUE["IT"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104,
  105,
  106,
  107,
  108,
  109,
  110,
  111,
  112,
  113,
  114,
  115,
  116,
  117,
  118,
  119,
  120,
  121,
  122,
  123,
  124,
  125,
  126,
  127,
  128
];
COUNTRY_CITY_MAP_VALUE["JO"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82
];

COUNTRY_CITY_MAP_VALUE["KE"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54
];
COUNTRY_CITY_MAP_VALUE["KG"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53
];

COUNTRY_CITY_MAP_VALUE["KM"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88
];
COUNTRY_CITY_MAP_VALUE["LB"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26
];
COUNTRY_CITY_MAP_VALUE["LY"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18
];
COUNTRY_CITY_MAP_VALUE["LY"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

COUNTRY_CITY_MAP_VALUE["ME"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34
];
COUNTRY_CITY_MAP_VALUE["MG"] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
COUNTRY_CITY_MAP_VALUE["MK"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84
];
COUNTRY_CITY_MAP_VALUE["ML"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
COUNTRY_CITY_MAP_VALUE["MM"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15
];

COUNTRY_CITY_MAP_VALUE["MR"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23
];
COUNTRY_CITY_MAP_VALUE["MV"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26
];

COUNTRY_CITY_MAP_VALUE["MW"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30
];
COUNTRY_CITY_MAP_VALUE["MY"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16
];

COUNTRY_CITY_MAP_VALUE["NE"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70
];

COUNTRY_CITY_MAP_VALUE["NG"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36
];

COUNTRY_CITY_MAP_VALUE["OM"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28
];

COUNTRY_CITY_MAP_VALUE["PH"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97
];

COUNTRY_CITY_MAP_VALUE["PK"] = [1, 2, 3, 4, 5, 6, 7, 8];

COUNTRY_CITY_MAP_VALUE["PS"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37
];

COUNTRY_CITY_MAP_VALUE["qa"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15
];

COUNTRY_CITY_MAP_VALUE["RU"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84
];

COUNTRY_CITY_MAP_VALUE["SD"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

COUNTRY_CITY_MAP_VALUE["SL"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90
];

COUNTRY_CITY_MAP_VALUE["SN"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

COUNTRY_CITY_MAP_VALUE["SO"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16
];
COUNTRY_CITY_MAP_VALUE["SY"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
COUNTRY_CITY_MAP_VALUE["TD"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23
];
COUNTRY_CITY_MAP_VALUE["TG"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22
];
COUNTRY_CITY_MAP_VALUE["TH"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77
];
COUNTRY_CITY_MAP_VALUE["TJ"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70
];
COUNTRY_CITY_MAP_VALUE["TM"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30
];

COUNTRY_CITY_MAP_VALUE["tn"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23
];

COUNTRY_CITY_MAP_VALUE["TR"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81
];

COUNTRY_CITY_MAP_VALUE["TZ"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29
];

COUNTRY_CITY_MAP_VALUE["UG"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91
];

COUNTRY_CITY_MAP_VALUE["UZ"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

COUNTRY_CITY_MAP_VALUE["YE"] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21
];

export const COUNTRY_CODE_TO_NAME_MAP = new Map();
COUNTRY_CODE_TO_NAME_MAP["+20"] = "eg";
COUNTRY_CODE_TO_NAME_MAP["+44"] = "gb";

export const ARRAYS_OF_GENDER = ["gender.0", "gender.1"];

export const ARRAYS_OF_REASONS = [
  "reason.1",
  "reason.9",
  "reason.2",
  "reason.3",
  "reason.4",
  "reason.5",
  "reason.6",
  "reason.7",
  "reason.8"
];

export const ARRAYS_OF_REASONS_CONV = [
  "reasonConv.1",
  "reasonConv.2",
  "reasonConv.3",
  "reason.8"
];

//Iran
//Pakistan
//Saudi Arabia
//Afghanistan
//Mauritania
//Yemen
//Egypt
//Jordan
//Iraq
//Kuwait
//Algeria
//Malaysia
//Maldives
//Morocco
//Libya
//Tunisia
//United Arab Emirates
//Somalia
//Brunei
//
//Albania
//Azerbaijan
//Bangladesh
//Burkina Faso
//Chad
//Gambia
//Guinea
//Kazakhstan
//Kosovo
//Kyrgyzstan
//Mali
//Northern Cyprus
//Nigeria
//Senegal
//Syria
//Lebanon
//Tajikistan
//Turkmenistan
//Turkey
//Uzbekistan

//Iraq, Malawi, Libya, Namibia, and Uganda

//Algeria
//Cameroon
//Chad Chad
//Central African Republic Central African Republic
//Republic of the Congo Republic of the Congo
//Djibouti Djibouti
//Egypt
//Gabon
//The Gambia
//Guinea
//Libya
//Kenya
//Mali
//Mauritania
//Morocco
//Nigeria
//São Tomé and Príncipe São Tomé and Príncipe
//Senegal
//Somalia
//South Sudan
//Sudan
//Eswatini
//Togo
//Tanzania
//Uganda
//Zambia
//
//
//Afghanistan
//Bahrain
//Bangladesh
//
//Brunei
//Indonesia
//Iran
//Iraq
//Jordan
//Kuwait
//Lebanon
//Maldives
//Oman
//Qatar
//Pakistan
//Saudi Arabia
//Sri Lanka
//Syria
//United Arab Emirates
//Yemen
//
//Solomon Islands[53]
//India
//Malaysia
//Philippines
//Singapore
