import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getCookie } from "../util/session";
import base64url from "base64url";
////////

// import PropTypes from "prop-types";
// import { getGridNumericColumnOperators } from "@material-ui/data-grid";
// import { useDemoData } from "@material-ui/x-grid-data-generator";

///////

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Button from "@material-ui/core/Button";
import {
  hideMessage,
  getCreatedUsers,
  viewUserFullProfile,
  getRecentUsers,
  suspendUser,
  suspendUserSuccess,
  unsuspendUser,
  unsuspendUserSuccess,
  getReportedProfileList,
  getReportedConversationList,
  viewReportedConversation,
  changeLimitReturnedData,
  setPageDate,
  setClickedRow,
  updateCreatedDataList,
  addAdminCheck,
  addAdminCheckSuccess,
  adminCheckList,
  adminCheckListSuccess,
  resetOffsetAdminCheckList,
  resetReportedProfileList,
  resetReportedConversationList,
  openPopupChat,
  resetConversationViewData,
  openPopupAddSubscription,
  getUserSubscriptionList
} from "../redux/actions/Home";

import AG_GRID_LOCALE_AR from "./locale/local.ar";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

///////
import { GridOverlay, DataGrid } from "@material-ui/data-grid";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { nlNL } from "@mui/x-data-grid";

import {
  COUNTRY_MAP,
  COUNTRY_CITY_MAP,
  ARRAYS_OF_REASONS,
  ARRAYS_OF_REASONS_CONV
} from "../util/data";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PageviewIcon from "@material-ui/icons/Pageview";
import AvTimerIcon from '@material-ui/icons/AvTimer';

import ModalChat from "./modals/chat";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Flag from "react-world-flags";

// import Carousel from "react-material-ui-carousel";

import Carousel from "react-elastic-carousel";

import { mapUserPhotoUrl } from "../helpers/mapUserPhotoUrl";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import ModalAddSubscription from "./modals/addSubscription"

//////

// const columns = [
//   { id: "userId", label: "User Id", minWidth: 200 },
//   { id: "fullName", label: "Name", minWidth: 100 },
//   { id: "userCountry", label: "Country", minWidth: 100 },
//   { id: "userCity", label: "City", minWidth: 100 },
//   { id: "userVa", label: "virtual area", minWidth: 100 },
//   { id: "birthDay", label: "BirthDay", minWidth: 100 },
//   { id: "jnt", label: "Join Time", minWidth: 100 },
//   { id: "phoneNumber", label: "phone Number", minWidth: 100 },
//   { id: "reportedProfile", label: "Reported Profile", minWidth: 100 },
//   { id: "reportedConversation", label: "Reported Conversation", minWidth: 100 },
//   { id: "suspendedUser", label: "Suspended", minWidth: 100 },
//   { id: "createdDate", label: "created Date", minWidth: 100 },
//   { id: "location", label: "Location", minWidth: 100 },
//   { id: "ip", label: "Ip", minWidth: 100 },
//   { id: "macAddress", label: "MacAddress", minWidth: 100 },
//   { id: "hardwareName", label: "HardwareName", minWidth: 100 },
//   { id: "hardwareVendor", label: "HardwareVendor", minWidth: 100 }
// ];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "3rem 1.5rem 1rem",
    textAlign: "center",
    width: "40%",
    "@media screen and (max-width: 575px) ": {
      width: "75% !important"
    }
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  avatar: {
    margin: "auto"
  },
  icons: {
    width: "35%",
    margin: "auto"
  },
  smallIcons: {
    width: "100%",
    margin: "auto"
  },
  root2: {
    flexDirection: "column",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode !== "light" ? "#aeb8c2" : "#262626"
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode !== "light" ? "#f5f5f7" : "#595959"
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode !== "light" ? "#dce0e6" : "#434343"
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode !== "light" ? "#fff" : "#1c1c1c"
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode !== "light" ? "0.8" : "0.08",
      fill: theme.palette.mode !== "light" ? "#f5f5f5" : "#fff"
    },
    label2: {
      marginTop: theme.spacing(1)
    }
  }
}));

function CustomNoRowsOverlay() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <GridOverlay className={classes.root2}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label2}>{t("data.nodate")}</div>
    </GridOverlay>
  );
}

const UsersList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const createdUsersData = useSelector(state => state.home.createdUsersData);
  const [finalCreatedUsersData, setFinalCreatedUsersData] = useState([]);
  const [currentClickedUser, setCurrentClickedUser] = useState(null);
  const [finalUserPhotoUrl, setFinalUserPhotoUrl] = useState(null);
  const [finalUserPhotoPPUrl, setFinalUserPhotoPPUrl] = useState(null);

  const [openListPopup, setOpenListPopup] = useState(false);

  const authUser = useSelector(state => state.auth.authUser);
  const local = useSelector(state => state.home.local);
  // filter date

  const countrySelected = useSelector(state => state.home.countrySelected);
  const ageFromSelected = useSelector(state => state.home.ageFromSelected);
  const ageToSelected = useSelector(state => state.home.ageToSelected);
  const andOrOptionSelected = useSelector(
    state => state.home.andOrOptionSelected
  );
  const checkedDateFromSelected = useSelector(
    state => state.home.checkedDateFromSelected
  );

  const checkedDateToSelected = useSelector(
    state => state.home.checkedDateToSelected
  );
  const checkedOptionSelected = useSelector(
    state => state.home.checkedOptionSelected
  );
  const subscribeOptionSelected=useSelector(
    state => state.home.subscribeOptionSelected
  );
  const checkedRecentUsersSelected = useSelector(
    state => state.home.checkedRecentUsersSelected
  );
  const checkOrderBySelected = useSelector(
    state => state.home.checkOrderBySelected
  );
  const subscribeOrderBySelected=useSelector(
    state => state.home.subscribeOrderBySelected
  );
  const citySelected = useSelector(state => state.home.citySelected);
  const createdDateFromSelected = useSelector(
    state => state.home.createdDateFromSelected
  );
  const createdDateToSelected = useSelector(
    state => state.home.createdDateToSelected
  );
  const createdOrderBySelected = useSelector(
    state => state.home.createdOrderBySelected
  );
  const dateFromSelected = useSelector(state => state.home.dateFromSelected);
  const userIdSelected = useSelector(state => state.home.userIdSelected);
  const dateToSelected = useSelector(state => state.home.dateToSelected);
  const genderSelected = useSelector(state => state.home.genderSelected);
  const maritalStatusSelected = useSelector(
    state => state.home.maritalStatusSelected
  );
  const phoneSelected = useSelector(state => state.home.phoneSelected);
  const reportedConversationReasonSelected = useSelector(
    state => state.home.reportedConversationReasonSelected
  );
  const reportedConversationSelected = useSelector(
    state => state.home.reportedConversationSelected
  );
  const suspendedSelected = useSelector(state => state.home.suspendedSelected);
  const reportedProfileSelected = useSelector(
    state => state.home.reportedProfileSelected
  );
  const reportedProfileReasonSelected = useSelector(
    state => state.home.reportedProfileReasonSelected
  );

  const breakPoints = [
    // { width: 1, itemsToShow: 2 },
    { width: 100, itemsToShow: 1, itemsToScroll: 1 },
    { width: 300, itemsToShow: 2 },
    { width: 1200, itemsToShow: 4 }
  ];

  const recentUsersData = useSelector(state => state.home.recentUsersData);
  const userFullProfileData = useSelector(
    state => state.home.userFullProfileData
  );
  const reportedConversationView = useSelector(
    state => state.home.reportedConversationView
  );

  const userSuspended = useSelector(state => state.home.userSuspended);
  const userUnsuspended = useSelector(state => state.home.userUnsuspended);
  const showMessage = useSelector(state => state.home.showMessage);
  const alertMessage = useSelector(state => state.home.alertMessage);

  const limitReturnedData = useSelector(state => state.home.limitReturnedData);
  const offsetReturnedData = useSelector(
    state => state.home.offsetReturnedData
  );

  const totalCountReturnedData = useSelector(
    state => state.home.totalCountReturnedData
  );

  const adminCheckListData = useSelector(
    state => state.home.adminCheckListData
  );

  const offsetAdminCheckListData = useSelector(
    state => state.home.offsetAdminCheckListData
  );

  const limitAdminCheckListData = useSelector(
    state => state.home.limitAdminCheckListData
  );

  const totalAdminCheckListData = useSelector(
    state => state.home.totalAdminCheckListData
  );

  const adminAddCheck = useSelector(state => state.home.adminAddCheck);

  const clickedRowData = useSelector(state => state.home.clickedRowData);

  const dataPageIndex = useSelector(state => state.home.dataPageIndex);

  const dataAdminListPageIndex = useSelector(
    state => state.home.dataAdminListPageIndex
  );

  const [openPopupConfirm, setOpenPopupConfirm] = useState(false);

  const [comment, setComment] = useState("");

  const [openPopupAdminList, setOpenPopupAdminList] = useState(false);
  const [userIdCheckList, setUserIdCheckList] = useState(null);

  const tokenValue = getCookie("access_token", false);
  const parsedTokenData = JSON.parse(
    base64url.decode(`${tokenValue}`.split(".")[1])
  );

  /////// reported list

  const [listPathName, setListPathName] = useState("");

  const reportedProfileData = useSelector(
    state => state.home.reportedProfileData
  );
  const reportedConversationData = useSelector(
    state => state.home.reportedConversationData
  );

  const limitReportedProfileData = useSelector(
    state => state.home.limitReportedProfileData
  );

  const limitReportedConversationData = useSelector(
    state => state.home.limitReportedConversationData
  );
  const offsetReportedProfileData = useSelector(
    state => state.home.offsetReportedProfileData
  );
  const offsetReportedConversationData = useSelector(
    state => state.home.offsetReportedConversationData
  );

  const totalCountReportedConversationData = useSelector(
    state => state.home.totalCountReportedConversationData
  );

  const totalCountReportedProfileData = useSelector(
    state => state.home.totalCountReportedProfileData
  );

  const openSubscriptionPopup = useSelector(state => state.home.openSubscriptionPopup);

  const openChat = useSelector(state => state.home.openChat);
  const [clickedConversationId, setClickedConversationId] = useState(null);
  const [clickedReportedId, setClickedReportedId] = useState(null);
  const [clickedReporterId, setClickedReporterId] = useState(null);

  ////

  /////

  const columns2 = [
    {
      field: "id",
      headerName: t("filter.userId"),
      width: 300,
      // disableClickEventBubbling: true,
      // renderCell: params => {
      //   params.row.id = params.row.userId;
      //   delete params.row.userId;
      //   return <></>;
      // },
      hide: true
    },
    { field: "fullName", headerName: t("data.name"), width: 180 },
    {
      field: "userCountry",
      headerName: t("filter.country"),
      width: 150,
      disableClickEventBubbling: true,
      renderCell: params => {
        return <>{t(COUNTRY_MAP[params.row.userCountry])}</>;
      }
    },
    {
      field: "userCity",
      headerName: t("filter.city"),
      width: 150,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>
            {t(
              COUNTRY_CITY_MAP[params.row.userCountry][params.row.userCity - 1]
            )}
          </>
        );
      }
    },
    {
      field: "userVa",
      headerName: t("data.virtualArea"),
      width: 150,
      hide: true
    },
    {
      field: "birthDay",
      headerName: t("data.birth"),
      width: 150,

      renderCell: params => {
        return (
          <>
            {`${params.row.birthDay
              .toString()
              .slice(0, 4)}- ${params.row.birthDay
              .toString()
              .slice(4, 6)}- ${params.row.birthDay.toString().slice(6, 8)}`}
          </>
        );
      }
    },
    {
      field: "jnt",
      headerName: t("data.joinTime"),
      width: 150,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          
          <>{params.row.jnt}
          {/* {moment(new Date(params.row.jnt * 100000)).format("YYYY-MM-DD")} */}
          </>
        );
      },
      hide: true
    },
    {
      field: "phoneNumber",
      headerName: t("data.phone"),
      width: 150,
      hide: true
    },
    {
      field: "reportedProfileCount",
      // headerName: "Reported Profile",
      headerName: (
        <div className="icons-conrainer-1">
          <img
            src="/static/images/User.svg"
            style={{ margin: "2rem .5rem 0 0", width: "35%" }}
            className="icon-report"
          />
          <img
            src="/static/images/report.svg"
            style={{ width: "30%" }}
            className="icon-report"
          />
        </div>
      ),
      // style={{ width: "1.5rem", margin: "0 .5rem" }}
      width: 140,
      minWidth: 140,
      renderCell: params => {
        return (
          <>
            {params.row.reportedProfileCount}
            <PageviewIcon
              color="secondary"
              fontSize="large"
              style={{ cursor: "pointer", margin: "0 .5rem" }}
              onClick={() => {
                setOpenListPopup(true);
                setListPathName("/ReportedProfileList");
                dispatch(resetReportedProfileList());
                dispatch(
                  getReportedProfileList(
                    limitReportedProfileData,
                    offsetReportedProfileData,
                    "",
                    params.row.id,
                    "",
                    1
                  )
                );
                dispatch(
                  getReportedProfileList(
                    limitReportedProfileData,
                    offsetReportedProfileData,
                    "",
                    params.row.id,
                    "",
                    0
                  )
                );
              }}
            />
          </>
        );
      }
    },
    {
      field: "reportedConversationCount",
      // headerName: "Reported Conversation",
      headerName: (
        <div className="icons-conrainer-2">
          <img
            src="/static/images/Messages_Icon_Standard.svg"
            style={{ margin: "2rem .5rem 0 0", width: "35%" }}
            className="icon-report"
          />
          <img
            src="/static/images/report.svg"
            style={{ width: "30%" }}
            className="icon-report"
          />
        </div>
      ),
      width: 140,
      minWidth: 140,
      renderCell: params => {
        return (
          <>
            {params.row.reportedConversationCount}
            <PageviewIcon
              color="secondary"
              fontSize="large"
              style={{ cursor: "pointer", margin: "0 .5rem" }}
              onClick={() => {
                setOpenListPopup(true);
                setListPathName("/ReportedConvList");
                dispatch(resetReportedConversationList());
                dispatch(
                  getReportedConversationList(
                    limitReportedConversationData,
                    offsetReportedConversationData,
                    "",
                    params.row.id,
                    "",
                    1
                  )
                );
                dispatch(
                  getReportedConversationList(
                    limitReportedConversationData,
                    offsetReportedConversationData,
                    "",
                    params.row.id,
                    "",
                    0
                  )
                );
              }}
            />
          </>
        );
      }
    },
    {
      field: "suspended",
      headerName: t("data.suspended"),
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>
            {parsedTokenData.id === 1 || authUser.adminRoles.includes(7) ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  dispatch(setClickedRow(params.row));

                  if (params.row.suspended === 1) {
                    dispatch(
                      unsuspendUser(
                        params.row.id,
                        params.row.gender,
                        params.row.userCountry,
                        params.row.userCity,
                        params.row.userVa,
                        params.row.jnt
                      )
                    );
                  } else {
                    dispatch(
                      suspendUser(
                        params.row.id,
                        params.row.gender,
                        params.row.userCountry,
                        params.row.userCity,
                        params.row.userVa,
                        params.row.jnt
                      )
                    );
                  }
                }}
              >
                {params.row.suspended === 1
                  ? t("user.unsuspend")
                  : t("user.suspend")}
              </Button>
            ) : params.row.suspended !== 1 ? (
              <Typography variant="body1" gutterBottom style={{ color: "red" }}>
                {" "}
                {t("user.suspended")}
              </Typography>
            ) : (
              ""
            )}
          </>
        );
      }
    },
    {
      field: "createdDate",
      headerName: t("data.createdDate"),
      width: 180,
      renderCell: params => {
        return <>{moment(params.row.createdDate).format("YYYY-MM-DD HH:mm A")}</>;
      }
    },
    {
      field: "updatedAtTime",
      headerName: t("data.updatedAtTime"),
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>
            {params.row.modified_date !== null &&
            moment(params.row.modified_date).format(
                "YYYY-MM-DD HH:mm A"
              )
              // moment(new Date(Number(params.row.modified_date))).format(
              //   "YYYY-MM-DD HH:mm A"
              // )
              
            }
          </>
        );
      }
    },
    {
      field: "checkState",
      headerName: t("data.checkState"),
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>
            {params.row.checkState !== 1 && (
              <Button
                variant="contained"
                // color={params.row.checkState === 0 ? "success" : "error"}
                className={params.row.checkState === 0 ? "colorG" : "colorR"}
                onClick={() => {
                  dispatch(setClickedRow(params.row));
                  //  open popup
                  handleOpen(true);
                  setOpenPopupConfirm(true);
                }}
              >
                {params.row.checkState === 0 ? "Check" : "Confirm Update"}
              </Button>
            )}
            {params.row.checkState === 1 && (
              <CheckCircleIcon className="icon-done" />
            )}
            {(params.row.checkState === 1 || params.row.checkState === 2) && (
              <>
                <PageviewIcon
                  color="secondary"
                  fontSize="large"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(
                      adminCheckList(
                        limitAdminCheckListData,
                        offsetAdminCheckListData,
                        params.row.id,
                        1
                      )
                    );
                    dispatch(
                      adminCheckList(
                        limitAdminCheckListData,
                        offsetAdminCheckListData,
                        params.row.id,
                        0
                      )
                    );
                    setUserIdCheckList(params.row.id);
                    handleOpen(true);
                    setOpenPopupAdminList(true);
                    //  setFinalUserPhotoUrl(null);
                    //  setCurrentClickedUser(params.row);
                    // dispatch(
                    //   viewUserFullProfile(
                    //     params.row.id,
                    //     params.row.gender,
                    //     params.row.userCountry,
                    //     params.row.userCity,
                    //     params.row.userVa,
                    //     params.row.jnt
                    //   )
                    // );
                  }}
                />
              </>
            )}
          </>
        );
      }
    },

    {
      field: "checkStateTime",
      headerName: t("data.checkStateTime"),
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>
            {params.row.checkStateTime !== null &&
              moment(new Date(Number(params.row.checkStateTime))).format(
                "YYYY-MM-DD HH:mm A"
              )}
          </>
        );
      }
    },
    {
      field: "userChannel",
      headerName: t("data.userChannel"),
      width: 250,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>
            {
              `${params.row.userCountry}_${params.row.userCity}_${params.row.userVa}_${params.row.id}_${params.row.gender}_${params.row.jnt}`
            }
          </>
        );
      }
    },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   width: 180
    // },
    {
      field: "viewFullProfile",
      headerName: t("data.viewProfile"),
      width: 180,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          // <Button
          //   variant="contained"
          //   color="secondary"
          // >
          <PageviewIcon
            color="secondary"
            fontSize="large"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setFinalUserPhotoUrl(null);
              setCurrentClickedUser(params.row);
              // dispatch(
              //   viewUserFullProfile(
              //     params.row.id,
              //     params.row.gender,
              //     params.row.userCountry,
              //     params.row.userCity,
              //     params.row.userVa,
              //     params.row.jnt
              //   )
              // );
            }}
          />
          // </Button>
        );
      }
    },
    {
      field: "ip",
      headerName: t("data.ip"),
      minWidth: 180,
      hide: true
    },
    {
      field: "macAddress",
      headerName: t("data.platform"),
      minWidth: 180,
      hide: true,
      // resizable: true
    },
    {
      field: "hardwareName",
      headerName: t("data.hardwareName"),
      minWidth: 180,
      hide: true,
      // resizable: true
    },
    {
      field: "hardwareVendor",
      headerName: t("data.viewProfileVender"),
      minWidth: 180,
      hide: true,
      // resizable: true
    },
    {
      field: "subscribe",
      headerName: t("data.subscription"),
      width: 400,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <>
              {params.row.subscriptionFlag==0&&params.row.subscriptionEndDate!=null&&
                    <Typography variant="body1" gutterBottom>
                         { t("user.subExpiredFrom")} 
                         {moment(params.row.subscriptionEndDate).format("DD")}
                         {t("user.days")}
                   </Typography>
              }
              {params.row.subscriptionFlag==0&&params.row.subscriptionEndDate==null&&
                  <>
                   <AvTimerIcon className="icon-color"/>  {t("menu.subAwait")}
                   </>
              }
              {params.row.subscriptionFlag==0&&params.row.subscriptionEndDate!=null&&
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                 /// open modal to add subscription
                 dispatch(openPopupAddSubscription(true));
                 // call get subscription API
                //  dispatch(getUserSubscriptionList(params.row.userId,10,0,0))
                }}
              >
                  {t("user.renewSub")}
              </Button>
               }
            
          </>
        );
      }
    }
  ];

  /////

  const columnsProfile = [
    { id: "reporterId", label: t("data.reporterId"), minWidth: 170 },
    { id: "reportedId", label: t("data.reportedId"), minWidth: 170 },
    { id: "reasonId", label: t("data.reasonId"), minWidth: 100 },
    { id: "comment", label: t("data.comment"), minWidth: 100 }
  ];

  const columnsConversation = [
    { id: "reporterId", label: t("data.reporterId"), minWidth: 170 },
    { id: "reportedId", label: t("data.reportedId"), minWidth: 170 },
    { id: "reasonId", label: t("data.reasonId"), minWidth: 100 },
    { id: "comment", label: t("data.comment"), minWidth: 100 },
    { id: "conversationId", label: t("data.conversationId"), minWidth: 70 }
  ];

  const [pageList, setPageList] = useState(0);
  const [rowsPerPageList, setRowsPerPageList] = useState(10);

  const handleChangePageList = (event, newPage) => {
    setPageList(newPage);
  };

  const handleChangeRowsPerPageList = event => {
    setRowsPerPageList(+event.target.value);
    setPageList(0);
  };

  const [open, setOpen] = useState(false);

  const [page, setPage] = React.useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setComment("");
    setCurrentClickedUser(null);
    setOpenPopupConfirm(false);
    setOpenPopupAdminList(false);
    setUserIdCheckList(null);
    dispatch(resetOffsetAdminCheckList());
    setOpenListPopup(false);
  };

  /////

  useEffect(() => {
    dispatch(
      getCreatedUsers(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        1,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        0,
        "",
        false,
        "",
        ""
      )
    );
    dispatch(
      getCreatedUsers(
        limitReturnedData,
        offsetReturnedData,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        0,
        "",
        false,
        "",
        ""
      )
    );
    // dispatch(getRecentUsers(0, 0, "", 1, 0));
    // dispatch(getReportedProfileList(0,0,"","","",0))
    //dispatch(getReportedConversationList(0, 0, "", "", "", 0));
    // dispatch(
    //   viewReportedConversation(
    //     0,
    //     0,
    //     1,
    //     "7731f1f7-8747-40d6-b3fe-eacaf6f50809",
    //     "954693f9-ac6c-4bd1-9bf9-a9ec22f1081d",
    //     "7731f1f7-8747-40d6-b3fe-eacaf6f50345"
    //   )
    // );
  }, []);

  useEffect(() => {
    if (createdUsersData.length !== 0) {
      setFinalCreatedUsersData(
        createdUsersData.slice(
          page * limitReturnedData,
          page * limitReturnedData + limitReturnedData
        )
      );
      // console.log("createdUsersData from component ", createdUsersData);
    }
  }, [createdUsersData]);

  useEffect(() => {
    if (currentClickedUser) {
      dispatch(
        viewUserFullProfile(
          currentClickedUser.id,
          currentClickedUser.gender,
          currentClickedUser.userCountry,
          currentClickedUser.userCity,
          currentClickedUser.userVa,
          currentClickedUser.jnt
        )
      );
      //  setFinalUserPhotoUrl(mapUserPhotoUrl());
      // handleOpen();
    }
  }, [currentClickedUser]);

  useEffect(() => {
    if (userFullProfileData && currentClickedUser) {
      setFinalUserPhotoUrl(
        mapUserPhotoUrl(
          currentClickedUser.id,
          userFullProfileData.mainpicture,
          userFullProfileData.mainpictureSR
        )
      );
      if (userFullProfileData.privatepictures !== null) {
        setFinalUserPhotoPPUrl(
          mapUserPhotoUrl(
            currentClickedUser.id,
            userFullProfileData.privatepictures,
            userFullProfileData.privatepicturesSR
          )
        );
      }
      // handleOpen();
    }
  }, [userFullProfileData]);

  useEffect(() => {
    if (finalUserPhotoUrl) {
      handleOpen();
    }
  }, [finalUserPhotoUrl]);

  useEffect(() => {
    if (showMessage) {
      NotificationManager.error(alertMessage);
      dispatch(hideMessage());
    }
  }, [showMessage]);

  useEffect(() => {
    if (userSuspended) {
      NotificationManager.success(t("notif.successSuspend"));
      dispatch(suspendUserSuccess(false));
      dispatch(updateCreatedDataList("s"));
    }
  }, [userSuspended]);

  useEffect(() => {
    if (userUnsuspended) {
      NotificationManager.success(t("notif.successSuspend"));
      dispatch(unsuspendUserSuccess(false));
      dispatch(updateCreatedDataList("s"));
    }
  }, [userUnsuspended]);

  useEffect(() => {
    if (adminAddCheck) {
      setOpenPopupConfirm(false);
      handleClose();
      NotificationManager.success(t("notif.successAddCheck"));
      dispatch(updateCreatedDataList("ch"));
      dispatch(addAdminCheckSuccess(false, null));
    }
  }, [adminAddCheck]);

  ////// Admin check list
  const columnsDetails = [
    // { id: "adminId", label: "Admin Id", minWidth: 100 },
    // { id: "adminName", label: "Admin Name", minWidth: 100 },
    // { id: "id", label: "Admin Check Id", minWidth: 100 },
    // { id: "userId", label: "User Id", minWidth: 100 },
    // { id: "checkComment", label: "Comment", minWidth: 100 },
    // { id: "createdDate", label: "Created", minWidth: 100 },

    { field: "adminId", headerName: t("data.adminId"), width: 150,resizable: false },
    { field: "adminName", headerName: t("data.adminName"), width: 150 ,resizable: false},
    { field: "id", headerName: t("data.adminCheckId"), width: 150,resizable: false },
    { field: "userId", headerName: t("filter.userId"), width: 300,resizable: false },

    {
      field: "createdDate",
      headerName: t("data.createdDate"),
      width: 150,
      resizable: false,
      renderCell: params => {
        return <>{moment(params.row.createdDate).format("YYYY-MM-DD HH:mm A")}</>;
      }
    },
    { field: "checkComment", resizable: false,headerName: t("data.comment"), width: 150 }
  ];

  return (
    <>
      <div style={{ height: "88rem", width: "100%" }}>
        {
          <DataGrid
            pagination
            style={{ direction: "ltr" }}
            rows={createdUsersData.length !== 0 ? finalCreatedUsersData : []}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            // localeText={nlNL.props.MuiDataGrid.localeText}
            localeText={local === "ar" ? AG_GRID_LOCALE_AR : ""}
            columns={columns2}
            pageSize={limitReturnedData}
            rowsPerPageOptions={[25]}
            rowCount={totalCountReturnedData}
            paginationMode="server"
            page={dataPageIndex}
            onPageChange={newPage => {
              setPage(newPage);
              dispatch(setPageDate("d", newPage));

              /////
              let finalReportedProfileReason = "";
              if (reportedProfileReasonSelected != "") {
                reportedProfileReasonSelected.forEach(e => {
                  finalReportedProfileReason = `${finalReportedProfileReason}${e},`;
                });
                finalReportedProfileReason = finalReportedProfileReason.substring(
                  0,
                  finalReportedProfileReason.length - 1
                );
              }
              ////
              let finalReportedConversationReason = "";
              if (reportedConversationReasonSelected != "") {
                reportedConversationReasonSelected.forEach(e => {
                  finalReportedConversationReason = `${finalReportedConversationReason}${e},`;
                });
                finalReportedConversationReason = finalReportedConversationReason.substring(
                  0,
                  finalReportedConversationReason.length - 1
                );
              }
              ////
              let finalMaritalStatus = "";
              if (maritalStatusSelected != "") {
                maritalStatusSelected.forEach(e => {
                  finalMaritalStatus = `${finalMaritalStatus}${e},`;
                });
                finalMaritalStatus = finalMaritalStatus.substring(
                  0,
                  finalMaritalStatus.length - 1
                );
              }

              let finalSuspended = !suspendedSelected ? "" : 1;
              let finalCheckedRecentUsers = !checkedRecentUsersSelected
                ? ""
                : 1;

              ////
              let finalAgeFrom = "";
              let finalAgeTo = "";
              if (ageFromSelected != "") {
                let newDate = new Date();
                newDate.setFullYear(newDate.getFullYear() - ageFromSelected);
                finalAgeFrom = moment(newDate)
                  .format("YYYY-MM-DD")
                  .replaceAll(/-/g, "");
              }

              if (ageToSelected != "") {
                let newDateTo = new Date();
                newDateTo.setFullYear(newDateTo.getFullYear() - ageToSelected);
                finalAgeTo = moment(newDateTo)
                  .format("YYYY-MM-DD")
                  .replaceAll(/-/g, "");
              }

              if (finalAgeFrom > finalAgeTo) {
                let temp = finalAgeFrom;
                finalAgeFrom = finalAgeTo;
                finalAgeTo = temp;
              }

              
              dispatch(
                getCreatedUsers(
                  limitReturnedData,
                  offsetReturnedData,
                  countrySelected,
                  citySelected,
                  genderSelected,
                  phoneSelected,
                  reportedProfileSelected,
                  finalReportedProfileReason,
                  reportedConversationSelected,
                  finalReportedConversationReason,
                  finalSuspended,
                  0,
                  // dateFromSelected,
                  // dateToSelected,

                  dateFromSelected !== ""
                    ? moment(dateFromSelected).format("YYYY-MM-DD HH:mm:ss")
                    : "",
                  dateToSelected !== ""
                    ? moment(dateToSelected).format("YYYY-MM-DD HH:mm:ss")
                    : "",

                  finalCheckedRecentUsers,
                  userIdSelected,
                  finalMaritalStatus,

                  // createdDateFromSelected,
                  // createdDateToSelected,
                  createdDateFromSelected !== ""
                    ? moment(createdDateFromSelected).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : "",
                  createdDateToSelected !== ""
                    ? moment(createdDateToSelected).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : "",

                  checkedOptionSelected,

                  // checkedDateFromSelected,
                  checkedDateFromSelected !== "" && checkedDateToSelected !== ""
                    ? `${moment(checkedDateFromSelected).format("X") *
                        1000}-${moment(checkedDateToSelected).format("X") *
                        1000}
                  
                  `
                    : "",

                  checkOrderBySelected,
                  createdOrderBySelected,
                  // ageFromSelected,
                  finalAgeFrom !== "" && finalAgeTo !== ""
                    ? `${finalAgeFrom}-${finalAgeTo}`
                    : "",
                  andOrOptionSelected,
                  subscribeOptionSelected,
                  subscribeOrderBySelected
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // 0,
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // "",
                  // 0,
                  // 0,
                  // "",
                  // false
                )
              );
            }}
            // filterModel={filterModel}
            // onFilterModelChange={(model) => setFilterModel(model)}
          />
        }
      </div>
      {/* <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(
            suspendUser(
              "d0876fc8-a155-42af-9ea5-d400436a31cf",
              1,
              "EG",
              10,
              1,
              16116719
            )
          );
        }}
      >
        Suspend User
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(
            unsuspendUser(
              "d0876fc8-a155-42af-9ea5-d400436a31cf",
              1,
              "EG",
              10,
              1,
              16116719
            )
          );
        }}
      >
        UnSuspend User
      </Button> */}
      {/* {showMessage && NotificationManager.error(alertMessage)} */}
      {/* <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {createdUsersData.length !== 0 &&
                createdUsersData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(user => {
                    //console.log("user ", user);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={user.userId}
                      >
                        {columns.map(column => {
                          const value = user[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value ==== "number"
                                ? column.format(value)
                                : column.id === "userCountry"
                                ? t(COUNTRY_MAP[value])
                                : column.id === "userCity"
                                ? t(
                                    COUNTRY_CITY_MAP[user.userCountry][
                                      value - 1
                                    ]
                                  )
                                : column.id === "jnt"
                                ? //|| column.id === "birthDay"
                                  moment(new Date(value * 100000)).format(
                                    "YYYY-MM-DD"
                                  )
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={createdUsersData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper> */}

      {openSubscriptionPopup && <ModalAddSubscription subscribedUser=""/> }

      {currentClickedUser && userFullProfileData && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Grid container>
                <Grid item xs={6} className="full-mb">
                  <Avatar
                    alt={currentClickedUser ? currentClickedUser.fullName : ""}
                    src={finalUserPhotoUrl ? finalUserPhotoUrl : ""}
                    className={`${classes.large} ${classes.avatar}`}
                  />
                  <h2 id="transition-modal-title">
                    {currentClickedUser.fullName}
                  </h2>
                </Grid>

                <Grid item xs={6} className="full-mb">
                  {/* className="grid-mb" */}
                  <Grid container>
                    <Grid item xs={1} className="g3-1">
                      <div className={`${classes.smallIcons} icon`}>
                        <Flag code={userFullProfileData.nationality} />
                      </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className="g3-2">
                      <Typography variant="body1" gutterBottom>
                        {t(`nationality.${userFullProfileData.nationality}`)}
                      </Typography>
                    </Grid>

                    <Grid item xs={1} className="g3-1">
                      <div className={`${classes.smallIcons} icon`}>
                        <img src="static/images/icons/new-profile/education.svg" />
                      </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className="g3-2">
                      <Typography variant="body1" gutterBottom>
                        {t(`education.${userFullProfileData.education}`)}
                      </Typography>
                    </Grid>

                    {userFullProfileData.tpercent && (
                      <>
                        <Grid item xs={1} className="g3-1">
                          <div className={`${classes.smallIcons} icon`}>
                            <img src="static/images/icons/new-profile/tprecent.svg" />
                          </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} className="g3-2">
                          <Typography variant="body1" gutterBottom>
                            {t(`tprecent.${userFullProfileData.tpercent}`)}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className="grid-mb">
                <Grid item xs={2} className="g3-1">
                  <div className={`${classes.icons} icon`}>
                    <img src="static/images/icons/new-profile/title.svg" />
                  </div>
                </Grid>
                <Grid item xs={10} className="g3-2">
                  <Typography variant="body1" gutterBottom>
                    {userFullProfileData.title}
                  </Typography>
                </Grid>

                <Grid item xs={2} className="g3-1">
                  <div className={`${classes.icons} icon`}>
                    <img src="static/images/icons/new-profile/workd.svg" />
                  </div>
                </Grid>
                <Grid item xs={10} className="g3-2">
                  <Typography variant="body1" gutterBottom>
                    {t(`workd.${userFullProfileData.workd - 1}`)}
                  </Typography>
                </Grid>
                <Grid item xs={2} className="g3-1">
                  <div className={`${classes.icons} icon`}>
                    <img src="static/images/icons/new-profile/bio.svg" />
                  </div>
                </Grid>
                <Grid item xs={10} className="g3-2">
                  <Typography variant="body1" gutterBottom>
                    {userFullProfileData.bio}
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>
                {t("profile.privateImg")}
              </Typography>
              {userFullProfileData.privatepictures === null ? (
                <p id="transition-modal-description">{t("user.noPP")}</p>
              ) : finalUserPhotoPPUrl ? (
                <>
                  <div
                    style={{
                      width: finalUserPhotoPPUrl.length == 1 ? "50%" : "70%",
                      margin: "auto"
                    }}
                    className="carousel-container"
                  >
                    <Carousel breakPoints={breakPoints}>
                      {finalUserPhotoPPUrl.map((e, i) => (
                        <img key={i} src={e} />
                      ))}
                    </Carousel>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </Fade>
        </Modal>
      )}

      {openPopupAdminList && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h3 id="transition-modal-title">{t("data.adminList")}</h3>
              <div style={{ height: 300, width: "100%" }}>
                {adminCheckListData.length !== 0 && (
                  <DataGrid
                    rows={adminCheckListData}
                    columns={columnsDetails}
                    pageSize={limitAdminCheckListData}
                    rowsPerPageOptions={[25]}
                    rowCount={totalAdminCheckListData}
                    paginationMode="server"
                    page={dataAdminListPageIndex}
                    onPageChange={newPage => {
                      // setPage(newPage);
                      dispatch(setPageDate("da", newPage));
                      dispatch(
                        adminCheckList(
                          limitAdminCheckListData,
                          offsetAdminCheckListData,
                          userIdCheckList,
                          0
                        )
                      );
                    }}
                    // filterModel={filterModel}
                    // onFilterModelChange={(model) => setFilterModel(model)}
                  />
                )}
              </div>
            </div>
          </Fade>
        </Modal>
      )}

      {openPopupConfirm && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h3 id="transition-modal-title">{t("data.seenContent")}</h3>
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel
                    id="comment-label"
                    style={{ marginBottom: ".5rem" }}
                  >
                    {t("data.addComment")}
                  </InputLabel>
                  <TextareaAutosize
                    onChange={event => {
                      setComment(event.target.value);
                    }}
                    aria-label="minimum height"
                    defaultValue={comment}
                    minRows={3}
                    name="comment"
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      // setOpenEdit(false);
                      handleClose();
                    }}
                    color="primary"
                    className="linear-g-r"
                  >
                    {t("data.cancel")}
                  </Button>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(addAdminCheck(clickedRowData.id, comment));
                    }}
                    color="primary"
                    className="linear-g-r"
                  >
                    {t("data.confirm")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      )}

      {openListPopup &&
        (reportedProfileData.length != 0 ||
          reportedConversationData.length != 0) && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openListPopup}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={openListPopup}>
              <div className={classes.paper} style={{ width: "75%" }}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {listPathName === "/ReportedProfileList"
                          ? columnsProfile.map(column => (
                              <TableCell
                                key={column.id}
                                // align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))
                          : listPathName === "/ReportedConvList"
                          ? columnsConversation.map(column => (
                              <TableCell
                                key={column.id}
                                // align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))
                          : ""}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listPathName === "/ReportedProfileList" &&
                        reportedProfileData.length != 0 &&
                        reportedProfileData
                          .slice(
                            pageList * rowsPerPageList,
                            pageList * rowsPerPageList + rowsPerPageList
                          )
                          .map((row, i) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.reporterId}
                              >
                                {columnsProfile.map(column => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                      typeof value === "number" ? (
                                        column.format(value)
                                      ) : column.id === "reporterId" ||
                                        column.id === "reportedId" ? (
                                        <>
                                          {value}
                                          {/* <PageviewIcon
                                          color="secondary"
                                          fontSize="large"
                                          onClick={() => {
                                            if (column.id === "reporterId") {
                                              setReportedDetails(null);
                                              setReporterDetails(
                                                reportedProfileData[i]
                                                  .reporterDetails
                                              );
                                            } else if (
                                              column.id === "reportedId"
                                            ) {
                                              setReporterDetails(null);

                                              setReportedDetails(
                                                reportedProfileData[i]
                                                  .reportedDetails
                                              );
                                            }
                                            //   setFinalUserPhotoUrl(null);
                                            //   setCurrentClickedUser(params.row);
                                            // dispatch(
                                            //   viewUserFullProfile(
                                            //     params.row.id,
                                            //     params.row.gender,
                                            //     params.row.userCountry,
                                            //     params.row.userCity,
                                            //     params.row.userVa,
                                            //     params.row.jnt
                                            //   )
                                            // );
                                          }}
                                        /> */}
                                        </>
                                      ) : column.id === "reasonId" ? (
                                        t(ARRAYS_OF_REASONS[value - 1])
                                      ) : (
                                        value
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      {listPathName === "/ReportedConvList" &&
                        reportedConversationData.length != 0 &&
                        reportedConversationData
                          .slice(
                            pageList * rowsPerPageList,
                            pageList * rowsPerPageList + rowsPerPageList
                          )
                          .map((row, i) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.reporterId}
                              >
                                {columnsConversation.map(column => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                      typeof value === "number" ? (
                                        column.format(value)
                                      ) : column.id === "reporterId" ||
                                        column.id === "reportedId" ||
                                        column.id === "conversationId" ? (
                                        <>
                                          <span style={{ marginRight: "1rem" }}>
                                            {value}
                                          </span>
                                          {column.id === "conversationId" && (
                                            <PageviewIcon
                                              color="secondary"
                                              fontSize="large"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                dispatch(
                                                  resetConversationViewData()
                                                );
                                                dispatch(openPopupChat(true));
                                                setClickedReportedId(
                                                  reportedConversationData[i]
                                                    .reportedId
                                                );
                                                setClickedReporterId(
                                                  reportedConversationData[i]
                                                    .reporterId
                                                );
                                                setClickedConversationId(
                                                  reportedConversationData[i]
                                                    .conversationId
                                                );
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : column.id === "reasonId" ? (
                                        t(ARRAYS_OF_REASONS_CONV[value - 1])
                                      ) : (
                                        value
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25]}
                  component="div"
                  count={
                    listPathName === "/ReportedProfileList" &&
                    reportedProfileData
                      ? totalCountReportedProfileData
                      : //reportedProfileData.length
                      listPathName === "/ReportedConvList" &&
                        reportedConversationData
                      ? totalCountReportedConversationData
                      : // reportedConversationData.length
                        ""
                  }
                  rowsPerPage={rowsPerPageList}
                  page={pageList}
                  onPageChange={handleChangePageList}
                  onRowsPerPageChange={handleChangeRowsPerPageList}
                />
              </div>
            </Fade>
          </Modal>
        )}

      {clickedConversationId && openChat && (
        <ModalChat
          clickedConversationId={clickedConversationId}
          clickedReportedId={clickedReportedId}
          clickedReporterId={clickedReporterId}
        ></ModalChat>
      )}
      <NotificationContainer />
    </>
  );
};

export default UsersList;
