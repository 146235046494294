import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import { getCookie } from "../util/session";
import base64url from "base64url";
import MuiPhoneNumber from "material-ui-phone-number";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import Button from "@material-ui/core/Button";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import {
  hideMessage,
  getCreatedUsers,
  resetCreatedUsers,
  setPageDate,
  setCheckedDateFromSelected,
  setCheckedOptionSelected,
  setCheckedRecentUsersSelected,
  setCheckOrderBySelected,
  setSubscribeOrderBySelected,
  setCreatedDateFromSelected,
  setCreatedDateToSelected,
  setCreateOrderBySelected,
  setDateFromSelected,
  setDateToSelected,
  setMarialStatusSelected,
  setPhoneSelected,
  setReportedConversationReasonSelected,
  setReportedConversationSelected,
  setReportedProfileReasonSelected,
  setReportedProfileSelected,
  setSuspendedSelected,
  setUserIdSelected,
  setGenderSelected,
  setCitySelected,
  setCountrySelected,
  setAgeFromSelected,
  setAgeToSelected,
  setAndOrOptionSelected,
  setCheckedDateToSelected,
  setSubscribeOptionSelected
} from "../redux/actions/Home";
import {
  COUNTRY_MAP,
  COUNTRY_MAP_VALUE,
  COUNTRY_CITY_MAP,
  COUNTRY_CITY_MAP_VALUE,
  ARRAYS_OF_GENDER,
  ARRAYS_OF_REASONS,
  ARRAYS_OF_REASONS_CONV,
  ARRAYS_OF_MARTIAL_STATUS
} from "../util/data";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: "2rem auto",
      padding: "1rem"
    }
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  field: {
    margin: "10px 0"
  },
  countryList: {
    ...theme.typography.body1
  },
  phone: {
    marginTop: "1rem"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  formG: {
    margin: "1rem 0rem"
  },
  textInput: {
    margin: "0 1rem",
    "@media screen and (max-width: 575px) ": {
      margin: "1rem 0"
    }
  },
  checkbox: {
    marginTop: "1rem"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

const Filtration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [createdDateTo, setCreatedDateTo] = useState("");
  const [createdDateFrom, setCreatedDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateCheckTo, setDateCheckTo] = useState("");
  const [dateCheckFrom, setDateCheckFrom] = useState("");
  const [checkedOption, setCheckedOption] = useState("");
  const [subscribeOption, setSubscribeOption] = useState("");
  const [phone, setPhone] = useState("");
  const [ageFrom, setAgeFrom] = useState("");
  const [ageTo, setAgeTo] = useState("");
  const [tokenUserData, setTokenUserData] = useState(null);

  const [checkedRecentUsers, setCheckedRecentUsers] = useState("");
  const [userId, setUserId] = useState("");
  const [checkedReportProfile, setCheckedReportProfile] = useState("");
  const [checkedReportConv, setCheckedReportConv] = useState("");
  const [checkedSuspend, setCheckedSuspend] = useState("");

  
  const [subscribeOrderBy, setSubscribeOrderBy] = useState("0");
  const [checkedOrderBy, setCheckedOrderBy] = useState("0");
  const [createdOrderBy, setcreatedOrderBy] = useState("0");

  const [orand, setOrand] = useState("0");

  const limitReturnedData = useSelector(state => state.home.limitReturnedData);
  const offsetReturnedData = useSelector(
    state => state.home.offsetReturnedData
  );
  const [reportedProfileReason, setReportedProfileReason] = useState([]);
  const [reportedConversationReason, setReportedConversationReason] = useState(
    []
  );
  const theme = useTheme();

  const authUser = useSelector(state => state.auth.authUser);

  const showMessage = useSelector(state => state.home.showMessage);
  const alertMessage = useSelector(state => state.home.alertMessage);

  useEffect(() => {
    const tokenValue = getCookie("access_token", false);
    const parsedTokenData = JSON.parse(
      base64url.decode(`${tokenValue}`.split(".")[1])
    );
    if (tokenValue != undefined) {
      setTokenUserData(parsedTokenData);
    }
    if (parsedTokenData.id != 1) {
      if (authUser.adminRoles.includes(2)) {
        setCheckedReportConv("1");
        setCheckedReportProfile("1");
        dispatch(setReportedConversationSelected("1"));
        dispatch(setReportedProfileSelected("1"));
      }
    }
  }, []);

  const handleChangeCountry = event => {
    setCountry(event.target.value);
    dispatch(setCountrySelected(event.target.value));
  };

  const handleChangeCity = event => {
    setCity(event.target.value);
    dispatch(setCitySelected(event.target.value));
  };

  const handleChange = event => {
    setReportedProfileReason(event.target.value);
    dispatch(setReportedProfileReasonSelected(event.target.value));
  };

  const handleChangeConvReason = event => {
    setReportedConversationReason(event.target.value);
    dispatch(setReportedConversationReasonSelected(event.target.value));
  };

  return (
    <>
      <div className={classes.root}>
        <Paper elevation={3}>
          {/* Country */}
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              {t("filter.country")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={country}
              onChange={handleChangeCountry}
            >
              <MenuItem value="">
                <em>{t("menu.none")}</em>
              </MenuItem>
              {((authUser.countryList&&authUser.countryList.length == 0 &&
                tokenUserData &&
                tokenUserData.id == 1) ||
                              (authUser.adminRoles.includes(5)))
                ? COUNTRY_MAP_VALUE.map((value, i) => (
                    <MenuItem
                      key={COUNTRY_MAP_VALUE[i]}
                      value={COUNTRY_MAP_VALUE[i]}
                      //   control={<Radio />}
                      //   label={value}>
                    >
                      {t(COUNTRY_MAP[COUNTRY_MAP_VALUE[i]])}
                    </MenuItem>
                  ))
                : authUser.adminRoles.includes(6) &&
                  authUser.countryList.map((value, i) => (
                    <MenuItem
                      key={authUser.countryList[i]}
                      value={authUser.countryList[i]}
                      //   control={<Radio />}
                      //   label={value}>
                    >
                      {t(COUNTRY_MAP[authUser.countryList[i]])}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          {/* City */}
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              {t("filter.city")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={city}
              onChange={handleChangeCity}
            >
              <MenuItem value="">
                <em>{t("menu.none")}</em>
              </MenuItem>

              {country != "" &&
                COUNTRY_CITY_MAP[country].map((value, i) => (
                  <MenuItem
                    key={COUNTRY_CITY_MAP_VALUE[country][i]}
                    value={COUNTRY_CITY_MAP_VALUE[country][i]}
                    // control={<Radio />}
                    // label={value}
                  >
                    {t(value)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {/* Gender */}

          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              {t("filter.gender")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={gender}
              onChange={event => {
                setGender(event.target.value);
                dispatch(setGenderSelected(event.target.value));
              }}
            >
              <MenuItem value="">
                <em>{t("menu.none")}</em>
              </MenuItem>
              {(authUser.genderList.length == 0 &&
                tokenUserData &&
                tokenUserData.id == 1) ||
              (authUser.genderList.length == 0 &&
                authUser.adminRoles.includes(3))
                ? ARRAYS_OF_GENDER.map((value, i) => (
                    <MenuItem
                      key={ARRAYS_OF_GENDER[i]}
                      value={i}
                      //   control={<Radio />}
                      //   label={value}>
                    >
                      {t(ARRAYS_OF_GENDER[i])}
                    </MenuItem>
                  ))
                : authUser.adminRoles.includes(4) &&
                  authUser.genderList.map((value, i) => (
                    <MenuItem
                      key={authUser.genderList[i]}
                      value={i}
                      //   control={<Radio />}
                      //   label={value}>
                    >
                      {t(ARRAYS_OF_GENDER[authUser.genderList[i]])}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>

          {/* Phone */}
          {tokenUserData && tokenUserData.id == 1 && (
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="demo-simple-select-helper-label">
              Phone Number
            </InputLabel> */}
              <MuiPhoneNumber
                defaultCountry={"eg"}
                onChange={(event,countrydata) => {
                  let onlyPhoneNum=event
                  onlyPhoneNum=onlyPhoneNum.slice(countrydata.dialCode.length+1, onlyPhoneNum.length)
                  setPhone(onlyPhoneNum);
                  dispatch(setPhoneSelected(onlyPhoneNum));
                }}
                className={classes.phone}
              />
            </FormControl>
          )}

          {/* Create Date */}
          <>
            <TextField
              id="datetime-local"
              label={t("filter.createDateFrom")}
              type="datetime-local"
              // defaultValue={Date.now()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={createdDateFrom}
              onChange={event => {
                setCreatedDateFrom(
                  // moment(event.target.value).format("YYYY-MM-DD HH:mm:ss")
                  event.target.value
                );
                dispatch(setCreatedDateFromSelected(event.target.value));
              }}
            />

            <TextField
              id="datetime-local"
              label={t("filter.createDateTo")}
              type="datetime-local"
              // defaultValue="2017-05-24T10:30"
              className={classes.textField}
              value={createdDateTo}
              onChange={event => {
                setCreatedDateTo(
                  // moment(event.target.value).format("YYYY-MM-DD HH:mm:ss")
                  event.target.value
                );
                dispatch(setCreatedDateToSelected(event.target.value));
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </>

          {/* Marital Status */}
          {(gender === 0 || gender === 1) && (
            <FormControl className={classes.formControl2}>
              <InputLabel id="demo-mutiple-chip-label">
                {t("filter.marital")}
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={maritalStatus}
                onChange={event => {
                  setMaritalStatus(event.target.value);
                  dispatch(setMarialStatusSelected(event.target.value));
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {/* {ARRAYS_OF_REASONS.map((reason, i) => (
                  <MenuItem
                    key={ARRAYS_OF_REASONS[i]}
                    value={ARRAYS_OF_REASONS[i]}
                    style={getStyles(reason, reportedProfileReason, theme)}
                  >
                    {name}
                  </MenuItem>
                ))} */}

                {ARRAYS_OF_MARTIAL_STATUS[gender == 0 ? 0 : 1].map(
                  (value, i) => (
                    // !(i == ARRAYS_OF_REASONS_CONV.length - 1) ? (
                    <MenuItem
                      key={ARRAYS_OF_MARTIAL_STATUS[gender == 0 ? 0 : 1][i]}
                      value={i}
                      style={getStyles(
                        ARRAYS_OF_MARTIAL_STATUS[gender == 0 ? 0 : 1][i],
                        maritalStatus,
                        theme
                      )}
                    >
                      {/* <FormControlLabel
                        //   control={
                        //     <Checkbox
                        //       checked={reasonProfile.i}
                        //       onChange={handleChangeReason}
                        //       name={i + 1}
                        //     />
                        //   }
                        label={value}
                      /> */}
                      {t(value)}
                    </MenuItem>
                  )
                  // ) : (
                  //   <MenuItem
                  //     key={ARRAYS_OF_REASONS_CONV[i]}
                  //     value={99}
                  //     style={getStyles(
                  //       ARRAYS_OF_REASONS_CONV[i],
                  //       reportedConversationReason,
                  //       theme
                  //     )}
                  //   >
                  //     {/* <FormControlLabel
                  //       //   control={
                  //       //     <Checkbox
                  //       //       checked={reasonProfile.i}
                  //       //       onChange={handleChangeReason}
                  //       //       name={99}
                  //       //     />
                  //       //   }
                  //       label={value}
                  //     /> */}
                  //     {t(value)}
                  //   </MenuItem>
                  // )
                )}
              </Select>
            </FormControl>
          )}

          {/* reported profile & conversation & suspended */}
          <FormGroup row className={classes.formG}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={checkedReportProfile}
                  onChange={event => {
                    console.log("clecked 1", event.target.checked);
                    setCheckedReportProfile(event.target.checked);
                  }}
                />
              }
              label="Reported Profile"
            /> */}

            <TextField
              id="standard-number"
              label={t("filter.profile")}
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              value={checkedReportProfile}
              onChange={event => {
                setCheckedReportProfile(event.target.value);

                dispatch(setReportedProfileSelected(event.target.value));
              }}
              inputProps={{
                min: 1
              }}
            />

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Check"
                defaultValue="check"
                // row
                name="row-radio-buttons-group"
                value={orand}
                onChange={event => {
                  setOrand(event.target.value);
                  dispatch(setAndOrOptionSelected(event.target.value));
                }}
              >
                {/* <FormControlLabel value="" control={<Radio />} label="None" /> */}
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={t("filter.or")}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={t("filter.and")}
                />
              </RadioGroup>
            </FormControl>

            <TextField
              id="standard-number-2"
              label={t("filter.conversation")}
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              value={checkedReportConv}
              onChange={event => {
                setCheckedReportConv(event.target.value);
                dispatch(setReportedConversationSelected(event.target.value));
              }}
              className={classes.textInput}
              inputProps={{
                min: 1
              }}
            />

            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={checkedSuspend}
                  onChange={event => {
                    setCheckedSuspend(event.target.checked);
                  }}
                />
              }
              label={t("filter.suspendedUsers")}
            />

            {(checkedReportProfile || checkedReportConv || checkedSuspend) && (
              <>
                <TextField
                  id="datetime-local"
                  label={t("filter.dateFrom")}
                  type="datetime-local"
                  // defaultValue={Date.now()}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={dateFrom}
                  onChange={event => {
                    setDateFrom(
                      // moment(event.target.value).format("YYYY-MM-DD HH:mm:ss")
                      event.target.value
                    );
                  }}
                />

                <TextField
                  id="datetime-local"
                  label={t("filter.dateTo")}
                  type="datetime-local"
                  // defaultValue="2017-05-24T10:30"
                  className={classes.textField}
                  value={dateTo}
                  onChange={event => {
                    setDateTo(
                      // moment(event.target.value).format("YYYY-MM-DD HH:mm:ss")
                      event.target.value
                    );
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </>
            )}
          </FormGroup>

          {/* <FormControl component="fieldset">
            <FormLabel component="legend">Checked Users</FormLabel>
            <RadioGroup
              aria-label="checked"
              defaultValue="female"
              name="radio-buttons-group"
              value={checkedOption}
              onChange={event => {
                console.log("checkedOption ", checkedOption);
                setCheckedOption(event.target.value);
              }}
            >
              <FormControlLabel value="" control={<Radio />} label="None" />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Unchecked"
              />
              <FormControlLabel value="1" control={<Radio />} label="Checked" />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Confirm Updated"
              />
            </RadioGroup>
          </FormControl> */}

          {/* Age Range */}
          <div style={{ margin: "1rem" }}>
            <TextField
              id="standard-number"
              label={t("filter.ageFrom")}
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              value={ageFrom}
              onChange={event => {
                // let newDate = new Date();

                // newDate.setFullYear(newDate.getFullYear() - event.target.value);

                // console.log(
                //   "ageFrom",
                //   event.target.value,
                //   // ageFrom,

                //   // newDate
                // );

                setAgeFrom(
                  // moment(newDate)
                  //   .format("YYYY-MM-DD")
                  //   .replaceAll(/-/g, "")
                  event.target.value
                );

                dispatch(setAgeFromSelected(event.target.value));
              }}
              inputProps={{
                min: 18
              }}
            />

            <TextField
              id="standard-number-2"
              label={t("filter.ageTo")}
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              value={ageTo}
              onChange={event => {
                // let newDate = new Date();

                // newDate.setFullYear(newDate.getFullYear() - event.target.value);

                setAgeTo(
                  // moment(newDate)
                  //   .format("YYYY-MM-DD")
                  //   .replaceAll(/-/g, "")
                  event.target.value
                );
                dispatch(setAgeToSelected(event.target.value));

                // console.log("ageTo", event.target.value);
              }}
              className={classes.textInput}
              inputProps={{
                min: 18
              }}
            />
          </div>

          {/* Checked */}
          <FormControl className={classes.formControl2}>
            <InputLabel id="demo-simple-select-helper-label">
              {t("menu.checkUser")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={checkedOption}
              onChange={event => {
                setCheckedOption(event.target.value);
                dispatch(setCheckedOptionSelected(event.target.value));
              }}
            >
              <MenuItem value="">
                <em>{t("menu.none")}</em>
              </MenuItem>
              <MenuItem value="0">
                <em>{t("menu.uncheck")}</em>
              </MenuItem>
              <MenuItem value="1">
                <em>{t("menu.check")}</em>
              </MenuItem>
              <MenuItem value="2">
                <em>{t("menu.confirmUpdate")}</em>
              </MenuItem>
            </Select>
          </FormControl>

          {checkedOption != "" && checkedOption != "0" && (
            <>
              <TextField
                id="datetime-local"
                label={t("filter.dateCheckFrom")}
                type="datetime-local"
                // defaultValue={Date.now()}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                value={dateCheckFrom}
                onChange={event => {
                  setDateCheckFrom(
                    // moment(event.target.value).format("YYYY-MM-DD HH:mm:ss")
                    event.target.value
                    // moment(event.target.value).format("X") * 1000
                  );
                  dispatch(setCheckedDateFromSelected(event.target.value));
                }}
              />

              <TextField
                id="datetime-local"
                label={t("filter.dateCheckTo")}
                type="datetime-local"
                // defaultValue="2017-05-24T10:30"
                className={classes.textField}
                value={dateCheckTo}
                onChange={event => {
                  setDateCheckTo(
                    // moment(event.target.value).format("YYYY-MM-DD HH:mm:ss")
                    event.target.value
                    // moment(event.target.value).format("X") * 1000
                  );
                  dispatch(setCheckedDateToSelected(event.target.value));
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </>
          )}

          {/* reported profile & conversation reasons */}

          <div>
            <FormControl className={classes.formControl2}>
              <InputLabel id="demo-mutiple-chip-label">
                {t("filter.profileReason")}
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={reportedProfileReason}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {/* {ARRAYS_OF_REASONS.map((reason, i) => (
                <MenuItem
                  key={ARRAYS_OF_REASONS[i]}
                  value={ARRAYS_OF_REASONS[i]}
                  style={getStyles(reason, reportedProfileReason, theme)}
                >
                  {name}
                </MenuItem>
              ))} */}

                {ARRAYS_OF_REASONS.map((value, i) =>
                  !(i == ARRAYS_OF_REASONS.length - 1) ? (
                    i == 1 ? (
                      <MenuItem
                        key={ARRAYS_OF_REASONS[i]}
                        value={9}
                        style={getStyles(
                          ARRAYS_OF_REASONS[i],
                          reportedProfileReason,
                          theme
                        )}
                      >
                        {/* <FormControlLabel
                    //   control={
                    //     <Checkbox
                    //       checked={reasonProfile.i}
                    //       onChange={handleChangeReason}
                    //       name={i + 1}
                    //     />
                    //   }
                    label={value}
                  /> */}
                        {t(value)}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={ARRAYS_OF_REASONS[i]}
                        value={i + 1}
                        style={getStyles(
                          ARRAYS_OF_REASONS[i],
                          reportedProfileReason,
                          theme
                        )}
                      >
                        {/* <FormControlLabel
                      //   control={
                      //     <Checkbox
                      //       checked={reasonProfile.i}
                      //       onChange={handleChangeReason}
                      //       name={i + 1}
                      //     />
                      //   }
                      label={value}
                    /> */}
                        {t(value)}
                      </MenuItem>
                    )
                  ) : (
                    <MenuItem
                      key={ARRAYS_OF_REASONS[i]}
                      value={99}
                      style={getStyles(
                        ARRAYS_OF_REASONS[i],
                        reportedProfileReason,
                        theme
                      )}
                    >
                      {/* <FormControlLabel
                      //   control={
                      //     <Checkbox
                      //       checked={reasonProfile.i}
                      //       onChange={handleChangeReason}
                      //       name={99}
                      //     />
                      //   }
                      label={value}
                    /> */}
                      {t(value)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl2}>
              <InputLabel id="demo-mutiple-chip-label">
                {t("filter.conversationReason")}
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={reportedConversationReason}
                onChange={handleChangeConvReason}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {/* {ARRAYS_OF_REASONS.map((reason, i) => (
                <MenuItem
                  key={ARRAYS_OF_REASONS[i]}
                  value={ARRAYS_OF_REASONS[i]}
                  style={getStyles(reason, reportedProfileReason, theme)}
                >
                  {name}
                </MenuItem>
              ))} */}

                {ARRAYS_OF_REASONS_CONV.map((value, i) =>
                  !(i == ARRAYS_OF_REASONS_CONV.length - 1) ? (
                    <MenuItem
                      key={ARRAYS_OF_REASONS_CONV[i]}
                      value={i}
                      style={getStyles(
                        ARRAYS_OF_REASONS_CONV[i],
                        reportedConversationReason,
                        theme
                      )}
                    >
                      {/* <FormControlLabel
                      //   control={
                      //     <Checkbox
                      //       checked={reasonProfile.i}
                      //       onChange={handleChangeReason}
                      //       name={i + 1}
                      //     />
                      //   }
                      label={value}
                    /> */}
                      {t(value)}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={ARRAYS_OF_REASONS_CONV[i]}
                      value={99}
                      style={getStyles(
                        ARRAYS_OF_REASONS_CONV[i],
                        reportedConversationReason,
                        theme
                      )}
                    >
                      {/* <FormControlLabel
                      //   control={
                      //     <Checkbox
                      //       checked={reasonProfile.i}
                      //       onChange={handleChangeReason}
                      //       name={99}
                      //     />
                      //   }
                      label={value}
                    /> */}
                      {t(value)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </div>

          {/* Recent users */}
          <FormGroup row className={classes.formG}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={checkedRecentUsers}
                  onChange={event => {
                    setCheckedRecentUsers(event.target.checked);
                    dispatch(setCheckedRecentUsersSelected(event.target.value));
                  }}
                />
              }
              label={t("filter.recentUsers")}
            />
            {checkedRecentUsers && (
              <TextField
                id="standard-basic"
                label={t("filter.userId")}
                value={userId}
                onChange={event => {
                  setUserId(event.target.value);
                }}
              />
            )}
          </FormGroup>

          {/* subscription menu */}
             <FormControl className={`${classes.formControl2}`}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {t("menu.subscribeUser")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={subscribeOption}
                        onChange={event => {
                          setSubscribeOption(event.target.value);
                          dispatch(setSubscribeOptionSelected(event.target.value));
                        }}
                      >
                        <MenuItem value="">
                          <em>{t("menu.none")}</em>
                        </MenuItem>
                        <MenuItem value="2">
                          <em>{t("menu.subAll")}</em>
                        </MenuItem>
                        <MenuItem value="0">
                          <em>{t("menu.subInactive")}</em>
                        </MenuItem>
                        <MenuItem value="1">
                          <em>{t("menu.subActive")}</em>
                        </MenuItem>
                        <MenuItem value="3">
                          <em>{t("menu.subAwait")}</em>
                        </MenuItem>
                      </Select>
                    </FormControl>

            {/*subscription order by  */}
           {(subscribeOption==="0"||subscribeOption==="1"||subscribeOption=="3")&& <div className={classes.formG}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t("filter.subscribeOrder")}</FormLabel>
              <RadioGroup
                aria-label="Check"
                defaultValue="check"
                row
                name="row-radio-buttons-group"
                value={subscribeOrderBy}
                onChange={event => {
                  setSubscribeOrderBy(event.target.value);
                  dispatch(setSubscribeOrderBySelected(event.target.value));
                }}
              >
                {/* <FormControlLabel value="" control={<Radio />} label="None" /> */}
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={t("filter.subDes")}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={t("filter.subAsc")}
                />
              </RadioGroup>
            </FormControl>
          </div>
           }
          {/*check order by  */}
          <div className={classes.formG}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t("filter.checkOrder")}</FormLabel>
              <RadioGroup
                aria-label="Check"
                defaultValue="check"
                row
                name="row-radio-buttons-group"
                value={checkedOrderBy}
                onChange={event => {
                  setCheckedOrderBy(event.target.value);
                  dispatch(setCheckOrderBySelected(event.target.value));
                }}
              >
                {/* <FormControlLabel value="" control={<Radio />} label="None" /> */}
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={t("filter.checkDes")}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={t("filter.checkAsc")}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {/*created order by  */}
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {t("filter.createOrder")}
              </FormLabel>
              <RadioGroup
                aria-label="Create"
                row
                defaultValue="Create"
                name="row-radio-buttons-group"
                value={createdOrderBy}
                onChange={event => {
                  setcreatedOrderBy(event.target.value);
                  dispatch(setCreateOrderBySelected(event.target.value));
                }}
              >
                {/* <FormControlLabel value="" control={<Radio />} label="None" /> */}
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={t("filter.createDes")}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={t("filter.createAsc")}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {/* Filter */}
          <div style={{ textAlign: "end" }}>
            <Button
              variant="contained"
              className="btn-1"
              onClick={() => {

                console.log(" subscribeOption,", subscribeOption,subscribeOrderBy)
                /////
                let finalReportedProfileReason = "";
                reportedProfileReason.forEach(e => {
                  finalReportedProfileReason = `${finalReportedProfileReason}${e},`;
                });
                finalReportedProfileReason = finalReportedProfileReason.substring(
                  0,
                  finalReportedProfileReason.length - 1
                );
                ////
                let finalReportedConversationReason = "";
                reportedConversationReason.forEach(e => {
                  finalReportedConversationReason = `${finalReportedConversationReason}${e},`;
                });
                finalReportedConversationReason = finalReportedConversationReason.substring(
                  0,
                  finalReportedConversationReason.length - 1
                );

                ////
                let finalMaritalStatus = "";
                maritalStatus.forEach(e => {
                  finalMaritalStatus = `${finalMaritalStatus}${e},`;
                });
                finalMaritalStatus = finalMaritalStatus.substring(
                  0,
                  finalMaritalStatus.length - 1
                );

                let finalSuspended = !checkedSuspend ? "" : 1;
                let finalCheckedRecentUsers = !checkedRecentUsers ? "" : 1;

                ////
                let finalAgeFrom = "";
                let finalAgeTo = "";
                if (ageFrom != "") {
                  let newDate = new Date();
                  newDate.setFullYear(newDate.getFullYear() - ageFrom);
                  finalAgeFrom = moment(newDate)
                    .format("YYYY-MM-DD")
                    .replaceAll(/-/g, "");
                }

                if (ageTo != "") {
                  let newDateTo = new Date();
                  newDateTo.setFullYear(newDateTo.getFullYear() - ageTo);
                  finalAgeTo = moment(newDateTo)
                    .format("YYYY-MM-DD")
                    .replaceAll(/-/g, "");
                }

                if (finalAgeFrom > finalAgeTo) {
                  let temp = finalAgeFrom;
                  finalAgeFrom = finalAgeTo;
                  finalAgeTo = temp;
                }

                ////
                dispatch(resetCreatedUsers());
                dispatch(setPageDate(0));
                dispatch(
                  getCreatedUsers(
                    limitReturnedData,
                    0,
                    country,
                    city,
                    gender,
                    phone,
                    checkedReportProfile,
                    finalReportedProfileReason,
                    checkedReportConv,
                    finalReportedConversationReason,
                    finalSuspended,
                    1,
                    dateFrom !== ""
                      ? moment(dateFrom).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                    dateTo !== ""
                      ? moment(dateTo).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                    finalCheckedRecentUsers,
                    userId,
                    finalMaritalStatus,
                    createdDateFrom !== ""
                      ? moment(createdDateFrom).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                    createdDateTo !== ""
                      ? moment(createdDateTo).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                    checkedOption,
                    dateCheckFrom !== "" && dateCheckTo !== ""
                      ? `${moment(dateCheckFrom).format("X") * 1000}-${moment(
                          dateCheckTo
                        ).format("X") * 1000}
                      
                      `
                      : "",

                    checkedOrderBy,
                    createdOrderBy,
                    finalAgeFrom !== "" && finalAgeTo !== ""
                      ? `${finalAgeFrom}-${finalAgeTo}`
                      : "",

                    orand,
                    subscribeOption,
                    subscribeOrderBy
                  )
                );

                dispatch(
                  getCreatedUsers(
                    limitReturnedData,
                    0,
                    country,
                    city,
                    gender,
                    phone,
                    checkedReportProfile,
                    finalReportedProfileReason,
                    checkedReportConv,
                    finalReportedConversationReason,
                    finalSuspended,
                    "",
                    dateFrom !== ""
                      ? moment(dateFrom).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                    dateTo !== ""
                      ? moment(dateTo).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                    finalCheckedRecentUsers,
                    userId,
                    finalMaritalStatus,
                    createdDateFrom !== ""
                      ? moment(createdDateFrom).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                    createdDateTo !== ""
                      ? moment(createdDateTo).format("YYYY-MM-DD HH:mm:ss")
                      : "",
                    checkedOption,
                    dateCheckFrom !== "" && dateCheckTo !== ""
                      ? `${moment(dateCheckFrom).format("X") * 1000}-${moment(
                          dateCheckTo
                        ).format("X") * 1000}
                      
                      `
                      : "",

                    checkedOrderBy,
                    createdOrderBy,
                    finalAgeFrom !== "" && finalAgeTo !== ""
                      ? `${finalAgeFrom}-${finalAgeTo}`
                      : "",

                    orand,
                    subscribeOption,
                    subscribeOrderBy
                  )
                );
              }}
            >
              {t("filter.apply")}
            </Button>
          </div>
        </Paper>
      </div>
      <NotificationContainer />
    </>
  );
};

export default Filtration;
