import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

///Modal
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
/////
import { styled } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import {
  viewReportedConversation,
  openPopupChat
} from "../../redux/actions/Home";

import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReceivedMessageCell from "../ReceivedMessageCell";
import SentMessageCell from "../SentMessageCell";

import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    width: "55%",
    "@media screen and (max-width: 575px) ": {
      width: "75% !important"
    }
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  avatar: {
    margin: "auto"
  },
  icons: {
    width: "35%",
    margin: "auto"
  }
}));

export default function ModalChat({
  // clickedReporterId,
  clickedReportedRow,
  clickedConversationId
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reportedConversationView = useSelector(
    state => state.home.reportedConversationView
  );

  const OffsetConversationMessages = useSelector(
    state => state.home.OffsetConversationMessages
  );
  const scoreLConversationMessages = useSelector(
    state => state.home.scoreLConversationMessages
  );

  const endOfConversationMessages = useSelector(
    state => state.home.endOfConversationMessages
  );

  const openChat = useSelector(state => state.home.openChat);
  ////// modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(openPopupChat(false));
  };

  const StyledFormControl = styled(FormControl)({
    formControl: {
      margin: 2,
      "& select": {
        paddingRight: "22px"
      }
    },
    selectEmpty: {
      marginTop: 0
    }
  });

  useEffect(() => {
    if (clickedConversationId) {
      dispatch(
        viewReportedConversation(
          scoreLConversationMessages,
          OffsetConversationMessages,
          1,
          clickedReportedRow.reporterId,
          clickedReportedRow.reportedId,
          clickedConversationId
        )
      );
      //  handleOpen();
    }
  }, [clickedConversationId]);

  // useEffect(()=>{
  //   // console.log("reportedConversationView ",reportedConversationView)
  // },[reportedConversationView])

  // handle scroll for list of Conversation messages
  const handleScrollConversationMessages = () => {
    if (!endOfConversationMessages) {
      // sent get Conversation messages (next options)
      dispatch(
        viewReportedConversation(
          scoreLConversationMessages,
          OffsetConversationMessages,
          1,
          clickedReportedRow.reporterId,
          clickedReportedRow.reportedId,
          clickedConversationId
        )
      );
    }
  };

  return (
    <>
      {/*  */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openChat}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openChat}>
          <div className={classes.paper}>
            {clickedConversationId && reportedConversationView && (
              <>
                <Typography variant="h6" gutterBottom>
                  {t(`chat.msg`)}
                </Typography>
                <div className="users-main-content users-main-content-2 customize-scroll">
                  <div
                    id="scrollableDiv"
                    style={{
                      height: 300,
                      overflow: "auto",
                      display: "flex",
                      flexDirection: "column-reverse "
                    }}
                    className="customize-scroll"
                  >
                    <InfiniteScroll
                      dataLength={reportedConversationView.length}
                      next={handleScrollConversationMessages}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse"
                      }}
                      inverse={true} //
                      hasMore={!endOfConversationMessages}
                      loader={<CircularProgress />}
                      scrollableTarget="scrollableDiv"
                      className="scroll-m customize-scroll"
                    >
                      {reportedConversationView.map((conversation, index) =>
                        conversation.o === 1 ? (
                          <>
                            {/* {indexOfLastMsg === index && (
                            <div style={{ textAlign: "center", color: "#b72051" }}>
                              <Typography
                                variant="overline"
                                display="block"
                                gutterBottom
                                style={{ fontWeight: "bold" }}
                              >
                                <IntlMessages id="mgs.seen" />
                              </Typography>
                            </div>
                          )} */}
                            <SentMessageCell
                              key={conversation.d}
                              conversation={conversation}
                              // clickedReporter={clickedReporterId}
                              clickedReported={clickedReportedRow}
                              clickedConversationId={clickedConversationId}
                              // myPhoto={myPhoto}
                              // seen={seenFlag === 0 ? false : true}
                            />
                          </>
                        ) : (
                          <ReceivedMessageCell
                            key={conversation.d}
                            conversation={conversation}
                            // clickedReporter={clickedReporterId}
                            clickedReported={clickedReportedRow}
                            clickedConversationId={clickedConversationId}
                            //   user={clickedUserChat}
                          />
                        )
                      )}
                    </InfiniteScroll>
                  </div>
                </div>
              </>
            )}
          </div>
        </Fade>
      </Modal>
      {/*  */}
    </>
  );
}
