import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";

import {
  login,
  changePassword,
  changePasswordSuccess,
  forgotPassword,
  forgotPasswordSuccess
} from "../redux/actions/Auth";
import { hideMessage } from "../redux/actions/Home";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: "10rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const authUser = useSelector(state => state.auth.authUser);
  const passwordChanged = useSelector(state => state.auth.passwordChanged);
  const passwordSent = useSelector(state => state.auth.passwordSent);
  const showMessage = useSelector(state => state.home.showMessage);
  const alertMessage = useSelector(state => state.home.alertMessage);

  useEffect(() => {
    // dispatch(login("admin@ha.com", "ha@_123"));
  }, []);

  // useEffect(() => {
  //   if (passwordChanged) {
  //     console.log("passwordChanged from component ", passwordChanged);
  //     NotificationManager.success("Your Password changed successfully");
  //     dispatch(changePasswordSuccess(false));
  //   }
  // }, [passwordChanged]);

  // useEffect(() => {
  //   if (passwordSent) {
  //     console.log("passwordSent from component ", passwordSent);
  //     NotificationManager.success(
  //       "Your password has been sent to your email inbox."
  //     );
  //     dispatch(forgotPasswordSuccess(false));
  //   }
  // }, [passwordSent]);

  useEffect(() => {
    if (showMessage) {
      NotificationManager.error(alertMessage);
      dispatch(hideMessage());
    }
  }, [showMessage]);

  return (
    <>
      {/* <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(changePassword("ha@_123", "amal@_123"));
        }}
      >
        change Password
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(forgotPassword("admin@ha.com"));
        }}
      >
        Forgot Password
      </Button> */}

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("user.login")}
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("user.userName")}
              name="email"
              autoComplete="email"
              value={userName}
              onChange={event => setUserName(event.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("user.password")}
              type="password"
              id="password"
              value={password}
              autoComplete="current-password"
              onChange={event => setPassword(event.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={userName === "" || password === "" ? true : false}
              onClick={() => {
                // dispatch(login("admin@ha.com", "ha@_123"));
                dispatch(login(userName, password));
              }}
            >
              {t("user.login")}
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => {
                    dispatch(forgotPassword("admin@ha.com"));
                  }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */}
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
      <NotificationContainer />
    </>
  );
};

export default LoginForm;
