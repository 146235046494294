import {
  ADMIN_LOGIN,
  ADMIN_LOGIN_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  ADMIN_LOGOUT
} from "../ActionTypes";

export const login = (username, password) => {
  return {
    type: ADMIN_LOGIN,
    payload: {
      username,
      password
    }
  };
};

export const loginSuccess = (returnedData, password) => {
  return {
    type: ADMIN_LOGIN_SUCCESS,
    payload: { returnedData, password }
  };
};

export const logout = () => {
  return {
    type: ADMIN_LOGOUT
  };
};

export const changePassword = (oldPassword, newPassword) => {
  return {
    type: CHANGE_PASSWORD,
    payload: {
      oldPassword,
      newPassword
    }
  };
};

export const changePasswordSuccess = data => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data
  };
};

export const forgotPassword = email => {
  return {
    type: FORGOT_PASSWORD,
    payload: {
      email
    }
  };
};

export const forgotPasswordSuccess = data => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: data
  };
};
