import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useMediaQuery,
  Button,
  useScrollTrigger,
  Slide,
  Menu,
  MenuItem,
  ListItemIcon
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";

import { getCookie } from "../util/session";
import base64url from "base64url";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

import Fade from "@material-ui/core/Fade";

import Home from "../pages/home";
import ReportedProfileList from "../pages/reportedProfileList";
import ReportedConvList from "../pages/reportedConvList";
import UncompletedProfiles from "../pages/uncompletedProfiles";

import LangDropdown from "../components/langDropdown";

import { LIST_OF_ROLES } from "../util/data";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import {
  logout,
  changePassword,
  changePasswordSuccess
} from "../redux/actions/Auth";

import Login from "../pages/login";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  bar: {
    backgroundColor: "white"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    width: "40%"
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction={"down"} in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser = useSelector(state => state.auth.authUser);
  const userPassword = useSelector(state => state.auth.userPassword);
  const passwordChanged = useSelector(state => state.auth.passwordChanged);
  const isLogin = useSelector(state => state.auth.isLogin);
  const [anchor, setAnchor] = React.useState(null);
  const open = Boolean(anchor);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleMenu = event => {
    setAnchor(event.currentTarget);
  };

  const [tokenUserData, setTokenUserData] = useState(null);

  useEffect(() => {
    const tokenValue = getCookie("access_token", false);
    if (tokenValue != undefined) {
      setTokenUserData(
        JSON.parse(base64url.decode(`${tokenValue}`.split(".")[1]))
      );
    }
  }, []);
  useEffect(() => {
    if (passwordChanged) {
      handleClose3();
      NotificationManager.success(t("notif.successPass"));
      dispatch(changePasswordSuccess(false));
    }
  }, [passwordChanged]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /// modal
  const [oldpassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState("");

  const [open3, setOpen3] = useState(false);

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
    setPassword("");
    setConfirmPassword("");
    setOldPassword("");
  };

  const [open4, setOpen4] = useState(false);

  const handleOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  return (
    <>
      <div className={classes.root}>
        <HideOnScroll {...props}>
          <BrowserRouter>
            <AppBar>
              <Toolbar className={classes.bar}>
                {/* <Typography
                  variant="h5"
                  component="p"
                  color="textSecondary"
                  className={classes.title}
                >
                  Murali
                </Typography> */}
                <Grid container>
                  <Grid item xs={8} className={"g-30"}>
                    {isLogin && authUser && isMobile ? (
                      <>
                        <IconButton
                          color="textPrimary"
                          className={classes.menuButton}
                          edge="start"
                          aria-label="menu"
                          onClick={handleMenu}
                        >
                          <MenuIcon />
                        </IconButton>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchor}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                          KeepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                          open={open}
                        >
                          <MenuItem
                            onClick={() => setAnchor(null)}
                            component={Link}
                            to="/"
                          >
                            <Typography variant="h6">
                              {" "}
                              {t("header.home")}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => setAnchor(null)}
                            component={Link}
                            to="/ReportedProfileList"
                          >
                            <Typography variant="h6">
                              {t("header.profileList")}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => setAnchor(null)}
                            component={Link}
                            to="/ReportedConvList"
                          >
                            <Typography variant="h6">
                              {t("header.conversationList")}
                            </Typography>
                          </MenuItem>
                         
                          {tokenUserData && (tokenUserData.id == 1 || tokenUserData.id == 10) &&
                            <MenuItem
                            variant="text"
                            component={Link}
                            to="/uncompletedProfiles"
                            color="default"
                          >
                           <Typography variant="h6">
                            {t("header.uncompletedProfiles")}
                            </Typography>
                          </MenuItem>
                            }
                           

                          <MenuItem>
                            <Button
                              aria-controls="fade-menu"
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              {t("header.settings")}
                            </Button>
                            <Menu
                              id="fade-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={open2}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  handleOpen4();
                                  setAnchor(null);
                                }}
                              >
                                {t("header.myInfo")}
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  handleOpen3();
                                  setAnchor(null);
                                }}
                              >
                                {t("header.changePass")}
                              </MenuItem>
                            </Menu>
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      isLogin &&
                      authUser && (
                        <div style={{ marginRight: "2rem" }}>
                          <Button
                            variant="text"
                            component={Link}
                            to="/"
                            color="default"
                          >
                            {t("header.home")}
                          </Button>
                          <Button
                            variant="text"
                            component={Link}
                            to="/ReportedProfileList"
                            color="default"
                          >
                            {t("header.profileList")}
                          </Button>
                          <Button
                            variant="text"
                            component={Link}
                            to="/ReportedConvList"
                            color="default"
                          >
                            {t("header.conversationList")}
                          </Button>

                           {tokenUserData && (tokenUserData.id == 1||tokenUserData.id == 10)&&
                           <Button
                            variant="text"
                            component={Link}
                            to="/uncompletedProfiles"
                            color="default"
                          >
                            {t("header.uncompletedProfiles")}
                          </Button>
                           }
                          <Button
                            aria-controls="fade-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            {t("header.settings")}
                          </Button>
                          <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open2}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                          >
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                handleOpen4();
                                setAnchor(null);
                              }}
                            >
                              {t("header.myInfo")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                handleOpen3();
                                setAnchor(null);
                              }}
                            >
                              {t("header.changePass")}
                            </MenuItem>
                          </Menu>
                        </div>
                      )
                    )}
                  </Grid>
                  <Grid item xs={4} className={"g-70"}>
                    <div className="btn-lang">
                      <LangDropdown />
                      {isLogin && authUser && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            dispatch(logout());
                          }}
                        >
                          {t("header.logout")}
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Switch>
              <Route
                exact
                path="/"
                component={isLogin && authUser ? Home : Login}
              />
              <Route
                exact
                path="/ReportedProfileList"
                component={isLogin && authUser ? ReportedProfileList : Login}
              />
              <Route
                exact
                path="/ReportedConvList"
                component={isLogin && authUser ? ReportedConvList : Login}
              />
               <Route
                exact
                path="/uncompletedProfiles"
                component={isLogin && authUser ? UncompletedProfiles : Login}
              />
            </Switch>
          </BrowserRouter>
        </HideOnScroll>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open3}
        onClose={handleClose3}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open3}>
          <div className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {`${t("user.email")} ${authUser ? authUser.email : ""}`}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  //   className={clsx(classes.margin, classes.textField)}
                  className={classes.margin}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t("user.oldPass")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showOldPassword ? "text" : "password"}
                    value={oldpassword}
                    onChange={event => {
                      setOldPassword(event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={event => {
                            setShowOldPassword(!showOldPassword);
                          }}
                          onMouseDown={event => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  //   className={clsx(classes.margin, classes.textField)}
                  className={classes.margin}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t("user.newPass")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={event => {
                      setPassword(event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={event => {
                            setShowPassword(!showPassword);
                          }}
                          onMouseDown={event => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>

                {/* <TextField
                  id="standard-basic"
                  label="New Password"
                  type="password"
                  value={password}
                  onChange={event => {
                    setPassword(event.target.value);
                  }}
                /> */}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  //   className={clsx(classes.margin, classes.textField)}
                  className={classes.margin}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-confirm-password">
                    {t("user.confPass")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={event => {
                      setConfirmPassword(event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={event => {
                            setShowPassword(!showPassword);
                          }}
                          onMouseDown={event => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                {/* <TextField
                  id="standard-basic"
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={event => {
                    setConfirmPassword(event.target.value);
                  }}
                /> */}
              </Grid>
              <Grid item xs={12}>
                {password === confirmPassword &&
                  password != "" &&
                  oldpassword != "" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        dispatch(changePassword(oldpassword, password));
                      }}
                    >
                      {t("user.change")}
                    </Button>
                  )}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/* modal my info */}

      {open4 && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open4}
          onClose={handleClose4}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open4}>
            <div className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {`${t("user.email")} ${authUser ? authUser.email : ""}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {`${t("user.username")} ${
                      authUser ? authUser.username : ""
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {`${t("user.roles")}`}

                    {tokenUserData && tokenUserData.id == 1 ? (
                      // <List>
                      //   {LIST_OF_ROLES.map((value, i) => (
                      //     <ListItem
                      //       style={{ padding: "0", textAlign: "center" }}
                      //     >
                      //       <ListItemText
                      //         primary={`${t(value)}`}
                      //         style={{ color: "gray" }}
                      //         // secondary={secondary ? 'Secondary text' : null}
                      //       />
                      //     </ListItem>
                      //   ))}
                      // </List>
                      <span>{t("admin.role1")}</span>
                    ) : (
                      // LIST_OF_ROLES.map((value, i) => (
                      //     <Typography
                      //       variant="body1"
                      //       gutterBottom
                      //       style={{ color: "gray" }}
                      //     >
                      //       {`${t(value)}`}
                      //     </Typography>
                      //   ))
                      // authUser ? (
                      //   authUser.username
                      <List>
                        {authUser.adminRoles.map((value, i) => (
                          <ListItem
                            style={{ padding: "0", textAlign: "center" }}
                          >
                            <ListItemText
                              primary={`${t(LIST_OF_ROLES[value - 1])}`}
                              style={{ color: "gray" }}
                              // secondary={secondary ? 'Secondary text' : null}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      )}

      <NotificationContainer />
    </>
  );
};

export default Header;
