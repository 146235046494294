import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import LockIcon from "@material-ui/icons/Lock";
import ReactAudioPlayer from 'react-audio-player';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  readUserVoiceMessage,
  updateAudioBlobList
} from "../../redux/actions/Home";
const ReceivedMessageCell = ({ conversation, user,clickedReported,clickedConversationId }) => {
  const dispatch = useDispatch();
  const userVoiceMessageUrl=useSelector(state => state.home.userVoiceMessageUrl)
  const audioBlobList=useSelector(state => state.home.audioBlobList);
  const [newBlob,setNewBlob]=useState(null);
  useEffect(()=>{
    if (userVoiceMessageUrl &&userVoiceMessageUrl.includes(conversation.m)) {
      // console.log("userVoiceMessageUrl from component ",userVoiceMessageUrl)
      handleGetBlob()
      }

  },[userVoiceMessageUrl])
  useEffect(() => {
    if (audioBlobList.length!=0) {
    let blobObject = audioBlobList.find((a, i)=>{
      if(a.audioName == conversation.m)
        return true;
      }
    )
    setNewBlob(blobObject)
    }
  }, [audioBlobList]);

  const handleGetBlob = async() => {
    let blob = await fetch(userVoiceMessageUrl).then(r => r.blob());
    let blobUrl = URL.createObjectURL(blob)
    dispatch(updateAudioBlobList({audioName:conversation.m,audioUrl:blobUrl}))
};

  return (
    <>
    {conversation.m.includes(".amr")&&
     <div className="send-date date-audia">
     <Typography variant="overline" gutterBottom>
      {moment(Number(conversation.d)).format("MM/DD/YYYY hh:mm A")}
      </Typography>
      </div>  
    }
    <div className="d-flex flex-nowrap chat-item">
      <img
        className="rounded-circle avatar size-40 align-self-end"
        src={user ? user._ : "https://via.placeholder.com/150x150"}
        alt={user ? user.n : ""}
      />
       {conversation.m.includes(".amr")?
      <Grid container>
      <Grid item xs={4} className="audio-container">
        <ReactAudioPlayer
       className="audioPlayer2"
        // autoPlay
        controls
        // src={audioMessageUrl!=null&&audioMessageUrl.includes(conversation.m)?audioMessageUrl:conversation.m}
        onPlay={()=>{}}
        onCanPlay={()=>{}}
        src={newBlob!=undefined?newBlob.audioUrl:conversation.m}
      />
      </Grid>
      {(!userVoiceMessageUrl || !userVoiceMessageUrl.includes(conversation.m))&&newBlob==undefined&& <Grid item xs={1} className="cust-w">
      <ArrowDownwardIcon  className="load-icon load-icon-2" onClick={()=>{
           dispatch(readUserVoiceMessage(
            clickedConversationId,
            clickedReported.reporterDetails.gender,
            clickedReported.reporterDetails.userCountry,
            clickedReported.reporterDetails.userCity,
            clickedReported.reporterDetails.userVa,
            clickedReported.reporterId,
            clickedReported.reporterDetails.jnt,
            clickedReported.reportedDetails.gender,
            clickedReported.reportedDetails.userCountry,
            clickedReported.reportedDetails.userCity,
            clickedReported.reportedDetails.userVa,
            clickedReported.reportedId,
            clickedReported.reportedDetails.jnt,
            conversation.m,
            0
          ))
      }}/> 
      </Grid>}
      </Grid>:
      <div className="bubble">
        <div
          className={"message"}
          style={{ wordWrap: "break-word" }}
        >
          {conversation.m}
        </div>
        <div
          className={
              // conversation.h == 1
              //   ? "time text-muted text-right mt-2  bubble-blur"
              // : 
              "time text-muted text-right mt-2"
          }
        >
          {moment(Number(conversation.d)).format("hh:mm A")}
        </div>
        {/* {conversation.h == 1 ? (
          <div className="lock-mgs">
            <LockIcon />
          </div>
        ) : (
          ""
        )} */}
      </div>
       }
    </div>
     
    </>
  );
};

export default ReceivedMessageCell;
