import { persist } from "./services/reduxPersist";
import { combineReducers } from "redux";
import { authMigration } from "./migrations/Auth";
import { homeMigration } from "./migrations/Home";
import { createMigrate } from "redux-persist";
import Home from "./Home";
import Auth from "./Auth";

const MIGRATION_DEBUG = false;

// const settingsPersistConfig = {
//   key: "settings",
//   version: 1,
//   whitelist: [
//     "locale",
//     "navigationStyle",
//     "horizontalNavPosition",
//     "isDirectionRTL",
//     "darkTheme",
//     "themeColor",
//     "drawerType"
//   ],
//   migrate: createMigrate(settingsMigration, { debug: MIGRATION_DEBUG })
// };

const authPersistConfig = {
  key: "auth",
  version: 1,
  whitelist: ["authUser", "isLogin"],
  migrate: createMigrate(authMigration, { debug: MIGRATION_DEBUG })
};

const homePersistConfig = {
  key: "home",
  version: 1,
  whitelist: ["local"], //
  migrate: createMigrate(homeMigration, { debug: MIGRATION_DEBUG })
};

///////////////////////////////////////
/////////////////////////////////////
export default history =>
  combineReducers({
    //auth: Auth,
    auth: persist(authPersistConfig, Auth),
    // home: Home
    home: persist(homePersistConfig, Home)
  });
