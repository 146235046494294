import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

///Modal
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
/////
import { styled } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import {
    openPopupAction
} from "../../redux/actions/Home";

import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReceivedMessageCell from "../ReceivedMessageCell";
import SentMessageCell from "../SentMessageCell";

import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    width: "55%",
    "@media screen and (max-width: 575px) ": {
      width: "75% !important"
    }
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  avatar: {
    margin: "auto"
  },
  icons: {
    width: "35%",
    margin: "auto"
  }
}));

export default function ModalActionList({
  actionSelected,
  selectedUsers
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openActionList = useSelector(state => state.home.openActionList);
  ////// modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(openPopupAction(false));
  };

  const StyledFormControl = styled(FormControl)({
    formControl: {
      margin: 2,
      "& select": {
        paddingRight: "22px"
      }
    },
    selectEmpty: {
      marginTop: 0
    }
  });

  useEffect(() => {
  }, []);

  return (
    <>
      {/*  */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openActionList}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openActionList}>
          <div className={classes.paper}>
          "helllo"
          </div>
        </Fade>
      </Modal>
      {/*  */}
    </>
  );
}
