import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import "./index.css";
import "./i18nextConf";
import "react-notifications/lib/notifications.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { createTheme,ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#b72051'
    }
    ,
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
  <Provider store={configureStore()}>
    <App />
  </Provider>
  </ThemeProvider>
  ,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
