import React, { useState } from "react";

import Filtration3 from "../components/filtration-3";
import UncompletedList from "../components/uncompletedList";

const UncompletedProfiles = () => {
  return (
    <>
      <div className="div-container">
      <Filtration3/>
        <UncompletedList />
      </div>
    </>
  );
};

export default UncompletedProfiles;
