export const mapUserPhotoUrl = (userId, userPhoto, signedRequest) => {
  if (userId && signedRequest.includes("MP/")) {
    let editSignedRequest = "";
    editSignedRequest = signedRequest.replace("/*?", `/${userId}${userPhoto}?`);
    return editSignedRequest;
  } else if (signedRequest.includes("PP/")) {
    let finalArr = [];
    for (const p in userPhoto) {
      let editSignedRequest2 = signedRequest;
      userPhoto[p] = editSignedRequest2.replace("/*?", `/${userPhoto[p]}?`);
      finalArr.push(userPhoto[p]);
    }
    return finalArr;
  }

  // if (Array.isArray(usersArr)) {
  //   if (signedRequest != null && usersArr.length != 0) {
  //     const newUsersArr = usersArr.map((e, i) => {
  //       if (e != null && !e._.includes("MP/")) {
  //         const editSignedRequest = signedRequest.replace(
  //           "MP/*?",
  //           `MP/${e._}?`
  //         );
  //         e._ = editSignedRequest;
  //       }
  //       return e;
  //     });
  //     return newUsersArr;
  //   }
  // } else {
  //   if (signedRequest != null) {
  //     let editSignedRequest = "";
  //     if (!usersArr.includes("MP/")) {
  //       editSignedRequest = signedRequest.replace("MP/*?", `MP/${usersArr}?`);
  //     }
  //     return editSignedRequest;
  //   }
  // }
};
